import styled from 'styled-components';

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  > * {
    :not(:first-child) {
      width: 300px;
      margin-left: 1.5rem;
    }
  }
`;
