import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { RootStore, useStores } from '../../stores';
import { useHistory, useParams } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import { Button } from '../../components/FormControls';
import styled from 'styled-components';
import { InnerContentContainer } from '../../components/Layout';
import Input, { handleInputChange } from '../../components/Input';
import TranslationEditor from '../../components/TranslationEditor';
import { TranslationIn } from '../../stores/models/TranslationModel';

const Container = styled.div`
  padding: 1em;
  button {
    margin-top: 1em;
  }
`;

export const ProductCategoryScreen: FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { categoryId } = useParams<{ categoryId: string | undefined }>();

  const {
    productStore: { state, updateProductCategory, allCategories, getProducts },
  } = useStores() as RootStore;

  const isSaving = state === 'SAVING';

  useEffect(() => {
    if (state === 'NOT_FETCHED') {
      getProducts({});
    }
  }, [getProducts, state]);

  const editCategory = useMemo(
    () => allCategories.find(({ id }) => id === Number(categoryId)),
    [allCategories, categoryId],
  );

  const [categoryName, setCategoryName] = useState(editCategory?.name ?? '');
  const [translations, setTranslations] = useState<TranslationIn[]>(
    editCategory?.translations ?? [],
  );

  useEffect(() => {
    if (editCategory) {
      setCategoryName(editCategory.name);
      setTranslations(editCategory.translations ?? []);
    }
  }, [editCategory]);

  const saveCategory = async () => {
    const id = Number(categoryId);
    const name = categoryName;
    if (id) {
      await updateProductCategory({ id, name, translations });
      history.push('/products');
    }
  };

  const getPageTitle = () => {
    const title = t(`screens.productCategories`);
    const details = t(`common.edit`);
    return `${title} - ${details}`;
  };

  return (
    <Layout pageTitle={getPageTitle()}>
      <InnerContentContainer>
        <Container>
          <Input
            label={t('productCategories.name')}
            value={categoryName}
            onChange={handleInputChange(setCategoryName)}
          />

          <TranslationEditor
            categoryId={Number(categoryId)}
            translations={translations}
            setTranslations={setTranslations}
          />

          <div>
            <Button
              label={t('common.save')}
              onClick={saveCategory}
              loading={isSaving}
            />
          </div>
        </Container>
      </InnerContentContainer>
    </Layout>
  );
});

export default ProductCategoryScreen;
