import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';
import { MediaModel } from './MediaModel';
import { BlockModel } from './BlockModel';
import { TranslationModel } from './TranslationModel';
import { LanguageModel } from './LanguageModel';
import { AreaModel } from './AreaModel';

export const ProductModel = types.model({
  id: types.identifierNumber,
  languageId: types.maybeNull(types.number),
  language: types.maybeNull(LanguageModel),
  areaId: types.maybeNull(types.number),
  area: types.maybeNull(AreaModel),
  isDraft: types.maybeNull(types.boolean),
  name: types.string,
  slug: types.maybeNull(types.string),
  order: types.maybeNull(types.number),
  categoryId: types.maybeNull(types.number),
  priceExpectation: types.maybeNull(types.string),
  forecast: types.maybeNull(types.number),
  index: types.maybeNull(types.number),
  backgroundImageId: types.maybeNull(types.number),
  backgroundImage: types.maybeNull(MediaModel),
  pdfViewId: types.maybeNull(types.number),
  pdfView: types.maybeNull(MediaModel),
  data: types.maybeNull(types.array(BlockModel)),
  data2: types.maybeNull(types.array(BlockModel)),
});

export interface IProductModel extends Instance<typeof ProductModel> {}
export interface Product extends SnapshotOut<typeof ProductModel> {}
export interface ProductIn extends SnapshotIn<typeof ProductModel> {}

export const CategoryModel = types.model({
  id: types.identifierNumber,
  name: types.string,
  slug: types.maybeNull(types.string),
  order: types.maybeNull(types.number),
  products: types.maybeNull(types.array(ProductModel)),
  translations: types.maybeNull(types.array(TranslationModel)),
});

export interface ICategoryModel extends Instance<typeof CategoryModel> {}
export interface Category extends SnapshotOut<typeof CategoryModel> {}
export interface ProductCategory extends SnapshotOut<typeof CategoryModel> {}
export interface CategoryIn extends SnapshotIn<typeof CategoryModel> {}
export interface ProductCategoryIn extends SnapshotIn<typeof CategoryModel> {}
