import React, { FC } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { styled } from '../../styles';
import { useStores } from '../../stores/index';
import LogoutIcon from '@material-ui/icons/ExitToApp';

interface HeaderProps {}

const LogoutButton = styled(Button)`
  background-color: ${props => props.theme.colors.white};
  border: none;
  box-shadow: none;
`;

const HeaderBox = styled(Box)`
  color: #fff;
  height: ${props => props.theme.constants.headerHeight};
  background-color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid ${props => props.theme.colors.greyDarkest};
  padding: 0;
`;

export const Header: FC<HeaderProps> = () => {
  const { userStore } = useStores();

  const onLogoutClick = () => {
    userStore.logout();
  };

  return (
    <HeaderBox pl={4} pr={4}>
      <Typography variant="body2">{userStore.user?.name}</Typography>
      <LogoutButton
        aria-label="logout"
        color="secondary"
        variant="contained"
        onClick={onLogoutClick}
      >
        <LogoutIcon />
      </LogoutButton>
    </HeaderBox>
  );
};
