import styled from 'styled-components';

export const handleSubmitBlockForm =
  (onSubmit: () => void) => (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit();
  };

export const BlockForm = styled.form`
  width: 100%;

  .Mui-disabled {
    color: #aaa;
  }

  > button {
    margin-top: 2em;
  }
`;
