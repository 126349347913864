import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStores, RootStore } from '../../stores';
import Layout from '../../components/Layout/Layout';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import PlusIcon from '@material-ui/icons/Add';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import TreeView, { Tree } from '../../components/TreeView';
import { getLanguageCodesFromTranslations } from '../../utils/language';
import TreeItemName from '../../components/TreeItemName';
import { Content } from '../../stores/models';
import { SelectOptionValue } from '../../components/Select';
import STORAGE from '../../utils/storage';
import AreaFilter from '../../components/AreaFilter';

export const AllContentScreen: FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();

  const [areaFilter, setAreaFilter] = useState<SelectOptionValue>(
    STORAGE.read({ key: 'AREA' }) ?? 'all',
  );

  const {
    contentStore: {
      state,
      categories,
      getContent,
      getCategoryPages,
      deleteContent,
      deleteContentCategory,
      changePageOrder,
      changeCategoryOrder,
    },
  } = useStores() as RootStore;

  const isBusy = state === 'FETCHING';

  useEffect(() => {
    if (state === 'NOT_FETCHED') {
      getContent({});
    }
  }, [getContent, state]);

  const editCategory = (id: number) => {
    history.push(`/contentCategory/${id}`);
  };

  const handleDeleteCategory = (id: number) => {
    if (window.confirm(t('contentCategories.deleteCategoryConfirmation'))) {
      deleteContentCategory({ id });
    }
  };

  const addPage = (categoryId: number) => {
    history.push(`/content/add/${categoryId}`);
  };

  const editPage = (id: number) => {
    history.push(`/content/${id}`);
  };

  const handleDeletePage = (id: number) => {
    if (window.confirm(t('contents.deletePageConfirmation'))) {
      deleteContent({ id });
    }
  };

  const handleChangeOrder = (id: number, direction: 'up' | 'down') => {
    changePageOrder({ id, direction });
  };

  const handleChangeCategoryOrder = (id: number, direction: 'up' | 'down') => {
    changeCategoryOrder({ id, direction });
  };

  const filterPage = (page: Content) => {
    return areaFilter === 'all' || page.area?.code === areaFilter;
  };

  const composePageName = (page: Content) => {
    const areaText =
      areaFilter === 'all' ? ` [${t(`areas.${page.area?.code}`)}]` : '';
    return `${page.title}${areaText}`;
  };

  const composeTreeSubItems = (categoryId: number) => {
    return (getCategoryPages(categoryId) ?? [])
      .filter(filterPage)
      .map(page => ({
        id: page.id,
        name: (
          <TreeItemName
            label={composePageName(page)}
            badges={page.language ? [page.language?.code] : []}
            isHidden={page.isDraft ?? false}
          />
        ),
        onClick: (pageId: number) => editPage(pageId),
        actions: [
          {
            label: t('common.moveUp'),
            icon: <ArrowUpIcon />,
            onClick: (pageId: number) => handleChangeOrder(pageId, 'up'),
          },
          {
            label: t('common.moveDown'),
            icon: <ArrowDownIcon />,
            onClick: (pageId: number) => handleChangeOrder(pageId, 'down'),
          },
          {
            label: t('common.edit'),
            icon: <EditIcon />,
            onClick: (pageId: number) => editPage(pageId),
          },
          {
            label: t('common.delete'),
            icon: <DeleteIcon color="error" />,
            onClick: (pageId: number) => handleDeletePage(pageId),
          },
        ],
      }));
  };

  const composeTreeItems = (): Tree => {
    return (categories ?? []).map(category => {
      const subItems = composeTreeSubItems(category.id);

      const deleteDisabled = !!subItems.length;
      const pages = getCategoryPages(category.id).filter(filterPage);

      return {
        id: category.id,
        name: (
          <TreeItemName
            label={`${category.name} (${pages.length})`}
            badges={getLanguageCodesFromTranslations(category.translations)}
            isHidden={category.isHidden}
          />
        ),
        onClick: () => null,
        subItems,
        actions: [
          {
            label: t('common.moveUp'),
            icon: <ArrowUpIcon />,
            onClick: (categoryId: number) =>
              handleChangeCategoryOrder(categoryId, 'up'),
          },
          {
            label: t('common.moveDown'),
            icon: <ArrowDownIcon />,
            onClick: (categoryId: number) =>
              handleChangeCategoryOrder(categoryId, 'down'),
          },
          {
            label: t('common.edit'),
            icon: <EditIcon />,
            onClick: (categoryId: number) => editCategory(categoryId),
          },
          {
            label: t('common.delete'),
            icon: <DeleteIcon color={deleteDisabled ? 'disabled' : 'error'} />,
            onClick: (categoryId: number) => handleDeleteCategory(categoryId),
            disabled: deleteDisabled,
          },
        ],
        actionButtons: [
          {
            label: t(`contents.addNew`),
            icon: <PlusIcon />,
            onClick: (categoryId: number) => addPage(categoryId),
          },
        ],
      };
    });
  };

  const mainActions = [
    {
      label: t('contentCategories.addNew'),
      icon: <PlusIcon />,
      onClick: () => history.push('/contentCategory/add'),
    },
  ];

  return (
    <Layout
      pageTitle={t(`screens.content`)}
      actions={mainActions}
      loading={isBusy}
    >
      <AreaFilter value={areaFilter} setValue={setAreaFilter} />

      {!isBusy && <TreeView tree={composeTreeItems()} />}
    </Layout>
  );
});

export default AllContentScreen;
