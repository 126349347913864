import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockEditorProps, BlockType } from '..';
import FileUpload from '../../FileUpload';
import { Button } from '../../FormControls';
import Input, { handleInputChange } from '../../Input';
import { BlockForm, handleSubmitBlockForm } from './BlockForm';
import { InputRow } from './styled';
import { BlockCategorySelect } from '../BlockCategorySelect';

const ImageBlock: React.FC<BlockEditorProps> = ({ onBlockSave, editBlock }) => {
  const { t } = useTranslation();

  const type: BlockType = 'ImageBlock';

  const [blockCategory, setBlockCategory] = useState(
    editBlock?.blockCategory ?? 'main',
  );
  // @ts-ignore
  const [altText, setAltText] = useState(editBlock?.altText ?? '');
  const [imageSrc, setImageSrc] = useState<UploadedFile | undefined>(
    // @ts-ignore
    editBlock?.imageSrc,
  );

  const disabled = editBlock && !editBlock.id;

  const handleSaveBlock = () => {
    const block = {
      type,
      blockCategory,
      altText,
      imageSrc,
    };

    if (onBlockSave) onBlockSave(block, editBlock);
  };

  return (
    <BlockForm onSubmit={handleSubmitBlockForm(handleSaveBlock)}>
      <InputRow>
        <FileUpload
          file={imageSrc}
          setFile={setImageSrc}
          label={t(`block-editor.${type}.upload`)}
          acceptedFiles="image/jpeg, image/png"
          disabled={disabled}
        />
        <BlockCategorySelect
          category={blockCategory}
          setCategory={setBlockCategory}
        />
      </InputRow>

      <Input
        label={t(`block-editor.${type}.altText`)}
        value={altText}
        onChange={handleInputChange(setAltText)}
        disabled={disabled}
      />

      <Button
        label={t(`block-editor.${type}.save`)}
        type="submit"
        disabled={disabled}
        fullWidth
      />
    </BlockForm>
  );
};

export default ImageBlock;
