import { FormControl, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PersonDto } from '../stores/models';
import { Button } from './FormControls';

const T_KEY = 'settings.persons';

const StyledForm = styled.form`
  > * {
    margin-bottom: 1rem;
  }
`;

type InputEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;
type SetStateType = React.Dispatch<React.SetStateAction<string>>;

interface Props {
  person?: PersonDto;
  onSubmit: (person: PersonDto) => void;
  closeForm: () => void;
}

const ModifyPersonForm: React.FC<Props> = ({ person, onSubmit, closeForm }) => {
  const { t } = useTranslation();

  const [name, setName] = useState(person?.name ?? '');
  const [email, setEmail] = useState(person?.email ?? '');
  const [title, setTitle] = useState(person?.title ?? '');
  const [company, setCompany] = useState(person?.company ?? '');
  const [info, setInfo] = useState(person?.info ?? '');

  const getValueOrNull = (value: string) => (value.length ? value : null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit({
      ...person,
      id: person?.id ?? undefined, // undefined if adding new person!
      name: getValueOrNull(name),
      email: getValueOrNull(email),
      title: getValueOrNull(title),
      company: getValueOrNull(company),
      info: getValueOrNull(info),
    });
    closeForm();
  };

  const handleInputChange = (setValue: SetStateType) => (event: InputEvent) => {
    setValue(event.currentTarget.value);
  };

  const inputs = [
    {
      label: t(`${T_KEY}.name`),
      value: name,
      setter: setName,
    },
    {
      label: t(`${T_KEY}.email`) + ' *',
      value: email,
      setter: setEmail,
      required: true,
      type: 'email',
    },
    {
      label: t(`${T_KEY}.title`),
      value: title,
      setter: setTitle,
    },
    {
      label: t(`${T_KEY}.company`),
      value: company,
      setter: setCompany,
    },
    {
      label: t(`${T_KEY}.info`),
      value: info,
      setter: setInfo,
    },
  ];

  return (
    <StyledForm onSubmit={handleSubmit}>
      {inputs.map(({ label, value, setter, required, type }, i) => (
        <div key={`${label}-${i}`}>
          <FormControl fullWidth>
            <Typography>{label}</Typography>
            <TextField
              type={type}
              value={value}
              required={required}
              autoFocus={i === 0}
              onChange={handleInputChange(setter)}
              variant="outlined"
              size="small"
            />
          </FormControl>
        </div>
      ))}

      <Button label={t('common.save')} type="submit" />
    </StyledForm>
  );
};

export default ModifyPersonForm;
