import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useTranslation } from 'react-i18next';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// Available tools
const toolbar = {
  options: [
    'inline',
    // 'blockType',
    // 'fontSize',
    // 'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    // 'embedded',
    'emoji',
    // 'image',
    'remove',
    'history',
  ],
  inline: {
    options: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      // 'monospace',
      'superscript',
      'subscript',
    ],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
  textAlign: {
    inDropdown: true,
    options: ['left', 'center', 'right'],
  },
  colorPicker: {
    colors: [
      'rgb(34, 48, 78)',
      'rgb(238, 174, 10)',
      'rgb(240, 237, 230)',
      'rgb(161, 215, 188)',
      'rgb(215, 161, 161)',
    ],
  },
};

// Transforms html string to editor state
function toEditorState(html: string) {
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks,
  );
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
}

// Transforms editor state to html string
function toHtml(editorState: EditorState) {
  return draftToHtml(convertToRaw(editorState.getCurrentContent()));
}

interface Props {
  text: string;
  setText: (text: string) => void;
}

const TextEditor: React.FC<Props> = ({ text, setText }) => {
  const { t } = useTranslation();

  const [editorState, setEditorState] = useState(toEditorState(text));

  // Update external text after a short delay when editor state changes
  useEffect(() => {
    const timeout = setTimeout(() => {
      setText(toHtml(editorState));
    }, 250);

    return () => clearTimeout(timeout);
  }, [editorState, setText]);

  const handleChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  return (
    <Editor
      toolbar={toolbar}
      editorState={editorState}
      onEditorStateChange={handleChange}
      placeholder={t('common.placeholder.text')}
    />
  );
};

export default TextEditor;
