import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockEditorProps, BlockType } from '..';
import { Button } from '../../FormControls';
import Input, { handleInputChange } from '../../Input';
import { BlockForm, handleSubmitBlockForm } from './BlockForm';

const SubHeader: React.FC<BlockEditorProps> = ({ onBlockSave, editBlock }) => {
  const { t } = useTranslation();

  const type: BlockType = 'SubHeader';

  // @ts-ignore
  const [value, setValue] = useState(editBlock?.value ?? '');

  const disabled = editBlock && !editBlock.id;

  const handleSaveBlock = () => {
    const block = {
      type,
      value,
    };

    if (onBlockSave) onBlockSave(block, editBlock);
  };

  return (
    <BlockForm onSubmit={handleSubmitBlockForm(handleSaveBlock)}>
      <Input
        label={t(`block-editor.${type}.value`)}
        value={value}
        onChange={handleInputChange(setValue)}
        disabled={disabled}
        autoFocus
      />

      <Button
        label={t(`block-editor.${type}.save`)}
        type="submit"
        disabled={disabled}
        fullWidth
      />
    </BlockForm>
  );
};

export default SubHeader;
