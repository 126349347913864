/* eslint-disable import/no-unresolved */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fi from './locales/fi.json';
import en from './locales/en.json';

export const languages = [{ name: 'Suomi', value: 'fi' }];

export const initLang = async (lng: string) => {
  await i18n.use(initReactI18next).init({
    fallbackLng: languages[0].value,
    lng: languages[0].value,
    resources: {
      fi: { translation: fi },
      en: { translation: en },
    },
  });
  return i18n;
};

export default i18n;
