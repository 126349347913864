import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BlockEditorProps, BlockType } from '..';
import { QUESTIONNAIRE_QUESTION_TYPES } from '../../../constants/constants';
import { getQuestionTypeSelectOptions } from '../../../utils/questionnaire';
import { Button } from '../../FormControls';
import Input, { handleInputChange } from '../../Input';
import Select, { handleSelectChange, SelectOptionValue } from '../../Select';
import { BlockForm, handleSubmitBlockForm } from './BlockForm';
import Switch from '../../Switch';

type SliderScale = [number, number];

const DEFAULT_SLIDER_SCALE: SliderScale = [1, 5];

const ScaleContainer = styled(Box)`
  padding: 0.5rem;
  border: 1px solid ${p => p.theme.colors.grey};
  border-radius: 0.3rem;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
`;

const Question: React.FC<BlockEditorProps> = ({ onBlockSave, editBlock }) => {
  const { t } = useTranslation();

  const type: BlockType = 'Question';

  // @ts-ignore
  const [value, setValue] = useState(editBlock?.value ?? '');
  const [questionType, setQuestionType] = useState<SelectOptionValue>(
    editBlock?.questionType ?? QUESTIONNAIRE_QUESTION_TYPES[0],
  );
  const [scale, setScale] = useState<SliderScale>([
    editBlock?.scaleMin ?? DEFAULT_SLIDER_SCALE[0],
    editBlock?.scaleMax ?? DEFAULT_SLIDER_SCALE[1],
  ]);
  const [labels, setLabels] = useState<string[]>([
    editBlock?.scaleMinLabel ?? '',
    editBlock?.scaleMaxLabel ?? '',
  ]);
  const [step, setStep] = useState<number>(editBlock?.sliderStep ?? 1);
  const [sliderInputDecoration, setSliderInputDecoration] = useState<string>(
    editBlock?.sliderInputDecoration ?? '',
  );
  const [isInputVisible, setInputVisible] = useState(
    editBlock?.isInputVisible ?? false,
  );

  const isSlider = `${questionType}`.includes('slider');
  const disabled = editBlock && !editBlock.id;

  const handleSaveBlock = () => {
    const block = {
      type,
      value,
      scaleMin: isSlider ? scale[0] : undefined,
      scaleMax: isSlider ? scale[1] : undefined,
      questionType: `${questionType}`,
      isInputVisible: isSlider && isInputVisible,
      sliderStep: isSlider ? step : undefined,
      sliderInputDecoration: isSlider ? sliderInputDecoration : undefined,
      scaleMinLabel: isSlider ? labels[0] : undefined,
      scaleMaxLabel: isSlider ? labels[1] : undefined,
    };

    if (onBlockSave) onBlockSave(block, editBlock);
  };

  useEffect(() => {
    if (questionType === 'slider.no-yes') {
      setScale([0, 1]);
    }
    if (questionType === 'slider.disagree-agree') {
      setScale([1, 5]);
    }
    // Reset these fields, if we're defining a custom slider
    if (questionType !== 'slider.custom') {
      setStep(1);
      setLabels(['', '']);
      setInputVisible(false);
      setSliderInputDecoration('');
    }
  }, [questionType]);

  const handleScaleChange =
    (type: 'min' | 'max') =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = Number(e.currentTarget.value);
      if (type === 'min') setScale(([_, max]) => [value, max]);
      if (type === 'max') setScale(([min, _]) => [min, value]);
    };

  const handleLabelChange =
    (type: 'start' | 'end') =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const {
        currentTarget: { value },
      } = e;
      if (type === 'start') setLabels(([_, end]) => [String(value), end]);
      if (type === 'end') setLabels(([start, _]) => [start, String(value)]);
    };

  const handleStepChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setStep(Number(e.target.value));
  };

  const handleInputDecorationChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setSliderInputDecoration(e.target.value);
  };

  return (
    <BlockForm onSubmit={handleSubmitBlockForm(handleSaveBlock)}>
      <Select
        id="question-type-select"
        label={t(`block-editor.${type}.questionType.label`)}
        value={questionType}
        options={getQuestionTypeSelectOptions()}
        onChange={handleSelectChange(setQuestionType)}
      />

      <Input
        label={t(`block-editor.${type}.value`)}
        value={value}
        onChange={handleInputChange(setValue)}
        disabled={disabled}
        autoFocus
      />

      <Box gridGap={24}>
        {questionType !== 'slider.no-yes' && (
          <ScaleContainer>
            <Typography variant="body2">
              {t(`block-editor.${type}.scaleLabel`)}
            </Typography>
            <div>
              <Input
                label={t(`block-editor.${type}.scale-min`)}
                value={scale[0]}
                onChange={handleScaleChange('min')}
                disabled={disabled || !isSlider}
                type="number"
              />
              <Input
                label={t(`block-editor.${type}.scale-max`)}
                value={scale[1]}
                onChange={handleScaleChange('max')}
                disabled={disabled || !isSlider}
                type="number"
              />
              {questionType === 'slider.custom' && (
                <Input
                  label={t(`block-editor.${type}.step`)}
                  value={step}
                  onChange={handleStepChange}
                  disabled={disabled || !isSlider}
                  type="number"
                />
              )}
            </div>
          </ScaleContainer>
        )}

        {questionType === 'slider.custom' && (
          <ScaleContainer>
            <Typography variant="body2">
              {t(`block-editor.${type}.input`)}
            </Typography>
            <div>
              <Switch
                label={t(
                  `block-editor.is-input-visible.${
                    isInputVisible ? 'yes' : 'no'
                  }`,
                )}
                checked={isInputVisible}
                setter={setInputVisible}
              />
              <Input
                label={t(`block-editor.${type}.decoration`)}
                value={sliderInputDecoration}
                onChange={handleInputDecorationChange}
                disabled={disabled || !isSlider || !isInputVisible}
              />
            </div>
          </ScaleContainer>
        )}

        {questionType === 'slider.custom' && (
          <ScaleContainer>
            <Typography variant="body2">
              {t(`block-editor.${type}.labels`)}
            </Typography>
            <div>
              <Input
                label={t(`block-editor.${type}.startLabel`)}
                value={labels[0]}
                onChange={handleLabelChange('start')}
                disabled={disabled}
              />
              <Input
                label={t(`block-editor.${type}.endLabel`)}
                value={labels[1]}
                onChange={handleLabelChange('end')}
                disabled={disabled}
              />
            </div>
          </ScaleContainer>
        )}
      </Box>

      <Button
        label={t(`block-editor.${type}.save`)}
        type="submit"
        disabled={disabled}
        fullWidth
      />
    </BlockForm>
  );
};

export default Question;
