import React, { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import PlusIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import { useStores, RootStore } from '../../stores';
import Layout from '../../components/Layout/Layout';
import Table from '../../components/MaterialTable/MaterialTable';
import { CellRenderers } from '../../components/MaterialTable/CellRenderers';

export const CompaniesScreen: FC = observer(() => {
  const history = useHistory();
  const {
    companyStore: { state, getCompanies, sortedCompanies, deleteCompany },
  } = useStores() as RootStore;
  const { t } = useTranslation();

  const isBusy = state === 'FETCHING';

  useEffect(() => {
    if (state === 'NOT_FETCHED') {
      getCompanies({});
    }
  }, [getCompanies, state]);

  const columns = [
    {
      accessor: 'name',
      Header: t('companies.name'),
      Cell: CellRenderers.link(row => `/companies/${row.id}`),
    },
    {
      accessor: '',
      Header: t('common.edit'),
      align: 'right',
      width: '1%',
      Cell: CellRenderers.editIcon(row => `/companies/${row.id}`),
    },
    {
      accessor: '',
      Header: t('common.delete'),
      align: 'right',
      width: '1%',
      Cell: CellRenderers.deleteIcon(row => {
        if (window.confirm(t('companies.deleteCompanyConfirmation'))) {
          deleteCompany({ id: row.id });
        }
      }),
    },
  ];

  const onPageChange = useCallback(
    (page: number, pageSize: number) => {
      // setPaging(page, pageSize)
    },
    [
      /* setPaging */
    ],
  );

  const addCompany = () => {
    history.push('/companies/add');
  };

  const mainActions = [
    {
      label: t(`companies.addNew`),
      icon: <PlusIcon />,
      onClick: addCompany,
    },
  ];

  return (
    <Layout
      pageTitle={t(`screens.companies`)}
      actions={mainActions}
      loading={isBusy}
    >
      <Table
        data={sortedCompanies}
        columns={columns}
        onPageChange={onPageChange}
        paginationEnabled={false}
      />
    </Layout>
  );
});

export default CompaniesScreen;
