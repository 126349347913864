import React, { FC, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { RootStore, useStores } from '../../stores';
import { useHistory, useParams } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import { Button } from '../../components/FormControls';
import styled from 'styled-components';
import { InnerContentContainer } from '../../components/Layout';
import Input, { handleInputChange } from '../../components/Input';
import TranslationEditor from '../../components/TranslationEditor';
import { TranslationIn } from '../../stores/models/TranslationModel';
import Switch from '../../components/Switch';

const Container = styled.div`
  padding: 1em;
  button {
    margin-top: 1em;
  }
`;

export const ContentCategoryScreen: FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { categoryId } = useParams<{ categoryId: string | undefined }>();

  const {
    contentStore: {
      state,
      updateContentCategory,
      addContentCategory,
      categories,
      getContent,
    },
  } = useStores() as RootStore;

  const isSaving = state === 'SAVING';

  useEffect(() => {
    if (state === 'NOT_FETCHED') {
      getContent({});
    }
  }, [getContent, state]);

  const editCategory = useMemo(
    () => categories.find(({ id }) => id === Number(categoryId)),
    [categories, categoryId],
  );

  const [categoryName, setCategoryName] = useState(editCategory?.name ?? '');
  const [translations, setTranslations] = useState<TranslationIn[]>(
    editCategory?.translations ?? [],
  );
  const [isHidden, setIsHidden] = useState(editCategory?.isHidden ?? false);

  useEffect(() => {
    if (editCategory) {
      setCategoryName(editCategory.name);
      setTranslations(editCategory.translations ?? []);
    }
  }, [editCategory]);

  const saveCategory = async () => {
    const id = Number(categoryId);
    const name = categoryName;
    if (id) {
      await updateContentCategory({ id, name, translations, isHidden });
      history.push('/content');
    } else {
      await addContentCategory({ name, translations, isHidden });
      history.push('/content');
    }
  };

  const getPageTitle = () => {
    const title = t(`screens.contentCategories`);
    const details = Number(categoryId)
      ? t(`common.edit`)
      : t(`contentCategories.addNew`);
    return `${title} - ${details}`;
  };

  return (
    <Layout pageTitle={getPageTitle()}>
      <InnerContentContainer>
        <Container>
          <Input
            label={t('contentCategories.name')}
            value={categoryName}
            onChange={handleInputChange(setCategoryName)}
          />

          <TranslationEditor
            categoryId={Number(categoryId)}
            translations={translations}
            setTranslations={setTranslations}
          />

          <Switch
            label={t(`common.isHidden.${isHidden ? 'yes' : 'no'}`)}
            checked={isHidden}
            setter={setIsHidden}
          />

          <div>
            <Button
              label={t('common.save')}
              onClick={saveCategory}
              loading={isSaving}
            />
          </div>
        </Container>
      </InnerContentContainer>
    </Layout>
  );
});

export default ContentCategoryScreen;
