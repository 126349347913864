import React from 'react';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { rootStore } from './stores/RootStore';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ROUTES } from './constants/routes';
import { initLang } from './i18n';
import { RootStoreProvider, useStores } from './stores';
import { THEME } from './styles';
import { MUI_THEME } from './styles/muiTheme';
import { IRoute } from './types';
import '@fontsource/roboto';
import Notifications from './components/Notifications';

initLang('fi');

const Routes = observer(() => {
  const {
    userStore: { isLoggedIn },
  } = useStores();

  const defaultView = !isLoggedIn ? '/login' : '/companies';

  const publicRoutes = ROUTES.filter(({ nonAuth }) => nonAuth);

  const allowedRoutes = isLoggedIn ? ROUTES : publicRoutes;

  return (
    <Switch>
      {allowedRoutes.map((route: IRoute) => {
        const Screen = route.component;
        return (
          <Route exact key={route.href} path={route.href}>
            <Screen />
          </Route>
        );
      })}
      <Redirect to={defaultView} />
    </Switch>
  );
});

const App = () => {
  return (
    <div className="App">
      <RootStoreProvider value={rootStore}>
        <Notifications />
        <Router>
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={MUI_THEME}>
              <ThemeProvider theme={THEME}>
                <Routes />
              </ThemeProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </Router>
      </RootStoreProvider>
    </div>
  );
};

export default App;
