import { Box, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BlockEditor from '../../components/BlockEditor';
import FileUpload from '../../components/FileUpload';
import Input, { handleInputChange } from '../../components/Input';
import Select, {
  SelectOptionValue,
  handleSelectChange,
} from '../../components/Select';
import SharingLink from '../../components/SharingLink';
import Switch from '../../components/Switch';
import UnsavedChanges from '../../components/UnsavedChanges';
import { RootStore, useStores } from '../../stores';
import { BlockIn, Content } from '../../stores/models';
import { getAreaSelectOptions } from '../../utils/area';
import { getLanguageSelectOptions } from '../../utils/language';

const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .flex-row {
    display: flex;
    align-items: center;

    > * {
      :not(:first-child) {
        margin-left: 1rem;
      }
    }
  }
`;

interface Props {
  editPage?: Content;
}

const useStyles = makeStyles({
  slug: {
    fontSize: '0.7rem',
    marginTop: '2px',
    display: 'block',
  },
});

export const ContentDetailsForm: FC<Props> = observer(({ editPage }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { categoryId } = useParams<{ categoryId: string | undefined }>();
  const classes = useStyles();
  const {
    areaStore: { areas, state: areasState, getAreas },
    contentStore: { state, updateContent, addContent },
    userStore: { availableLanguages, languagesState, getLanguages },
  } = useStores() as RootStore;

  const isSaving = state === 'SAVING';

  const [pageTitle, setPageTitle] = useState('');
  const [pageSlug, setPageSlug] = useState('');
  const [isDraft, setIsDraft] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [enableAnalytics, setEnableAnalytics] = useState(false);
  const [languageId, setLanguageId] = useState<SelectOptionValue>('');
  const [areaId, setAreaId] = useState<SelectOptionValue>('');
  const [pageBgImage, setPageBgImage] = useState<UploadedFile>();
  const [pagePdfView, setPagePdfView] = useState<UploadedFile>();
  const [currentData, setCurrentData] = useState<BlockIn[]>([]);
  const [currentData2, setCurrentData2] = useState<BlockIn[]>([]);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>();

  useEffect(() => {
    if (languagesState === 'NOT_FETCHED') getLanguages();
  }, [getLanguages, languagesState]);

  useEffect(() => {
    if (areasState === 'NOT_FETCHED') getAreas();
  }, [areasState, getAreas]);

  /**
   * Track unsaved changes
   */
  useEffect(() => {
    // Set timeout to wait for possible product to load (if editing existing product)
    const timeout = setTimeout(() => {
      if (editPage) {
        // Editing existing product
        const nameChanged = pageTitle !== editPage.title;
        const slugChanged = pageSlug !== editPage.slug;

        const languageChanged = languageId !== editPage.languageId;
        const areaChanged = areaId !== editPage.area?.id;
        const isDraftChanged = isDraft !== editPage.isDraft;
        const isPublicChanged = isPublic !== editPage.isPublic;
        const enableAnalyticsChanged =
          enableAnalytics !== editPage.enableAnalytics;

        const imageChanged = editPage.backgroundImage
          ? !_.isEqual(pageBgImage, editPage.backgroundImage)
          : pageBgImage !== undefined;

        const pdfViewChanged = editPage.pdfView
          ? !_.isEqual(pagePdfView, editPage.pdfView)
          : pagePdfView !== undefined;

        const dataChanged = !_.isEqual(currentData, editPage.data);
        const data2Changed = !_.isEqual(currentData2, editPage.data2);

        const changesMade =
          nameChanged ||
          languageChanged ||
          areaChanged ||
          isPublicChanged ||
          isDraftChanged ||
          enableAnalyticsChanged ||
          imageChanged ||
          pdfViewChanged ||
          slugChanged ||
          dataChanged ||
          data2Changed;

        if (changesMade) {
          setUnsavedChanges(true);
        }
      } else {
        // Creating new product
        if (
          pageTitle.length > 0 ||
          pageBgImage !== undefined ||
          currentData.length > 0 ||
          currentData2.length > 0
        ) {
          setUnsavedChanges(true);
        }
      }
    }, 250);

    return () => clearTimeout(timeout);
  }, [
    areaId,
    currentData,
    currentData2,
    editPage,
    isDraft,
    isPublic,
    enableAnalytics,
    languageId,
    pageBgImage,
    pagePdfView,
    pageTitle,
    pageSlug,
  ]);

  useEffect(() => {
    if (editPage) {
      setPageTitle(editPage.title ?? '');
      setLanguageId(editPage.languageId ?? '');
      setAreaId(editPage.area?.id ?? '');
      setIsDraft(editPage.isDraft ?? false);
      setIsPublic(editPage.isPublic ?? false);
      setEnableAnalytics(editPage.enableAnalytics ?? false);
      setPageBgImage(editPage.backgroundImage ?? undefined);
      setPagePdfView(editPage.pdfView ?? undefined);
      setCurrentData(editPage.data ?? []);
      setCurrentData2(editPage.data2 ?? []);
      setPageSlug(editPage.slug ?? '');
    }
  }, [editPage]);

  const storeData = async (newData: BlockIn[], newData2?: BlockIn[]) => {
    const page = {
      ...editPage,
      languageId: Number(languageId) > 0 ? Number(languageId) : undefined,
      areaId: Number(areaId) || undefined,
      title: pageTitle,
      slug: pageSlug,
      isDraft,
      isPublic,
      enableAnalytics,
      backgroundImageId: pageBgImage?.id ?? null,
      pdfViewId: pagePdfView?.id ?? null,
      data: newData,
      data2: newData2,
    };

    if (page?.id !== undefined) {
      await updateContent({ content: { ...page, id: page.id } });
    } else {
      await addContent({
        content: { ...page, categoryId: Number(categoryId) },
      });
      history.push(`/content`);
    }
    setUnsavedChanges(false);
  };

  // Renders a page link under slug input
  const renderLink = (withCategory?: boolean) => {
    if (!editPage) return '';

    const frontEndDomain =
      process.env.REACT_APP_FRONTEND_URL ?? 'https://prix.forecon.fi';

    let url = `${frontEndDomain}/${editPage.category?.slug}/${editPage.slug}`;
    if (!withCategory) url = `${frontEndDomain}/${editPage.slug}`;

    return (
      <a href={url} target="_blank" rel="noreferrer" className={classes.slug}>
        {url}
      </a>
    );
  };

  return (
    <ContentContainer>
      <UnsavedChanges unsavedChanges={unsavedChanges} showOnlyWhenUnsaved />

      <BlockEditor
        blockData={currentData}
        blockData2={currentData2}
        setBlockData={setCurrentData}
        setBlockData2={setCurrentData2}
        availableBlocks={[
          'NestedTextBlock',
          'ImageBlock',
          'VideoBlock',
          'QuestionnaireBlock',
          'LinkListBlock',
          'NestedDataTableBlock',
          'NestedChartBlock',
        ]}
        onSave={storeData}
        isSaving={isSaving}
      >
        <div className="flex-row">
          {!!availableLanguages.length && (
            <Select
              id="product-language-select"
              label={t('languages.title')}
              value={languageId ?? ''}
              options={getLanguageSelectOptions(availableLanguages)}
              onChange={handleSelectChange(setLanguageId)}
              width={150}
            />
          )}

          {!!areas.length && (
            <Select
              id="content-area-select"
              label={t('areas.title')}
              value={areaId ?? ''}
              options={getAreaSelectOptions(areas)}
              onChange={handleSelectChange(setAreaId)}
              width={150}
            />
          )}
        </div>
        <Box display="flex">
          <Switch
            label={t(`common.isDraft.${isDraft ? 'yes' : 'no'}`)}
            checked={isDraft}
            setter={setIsDraft}
          />
          <Switch
            label={t(`common.isPublic.${isPublic ? 'yes' : 'no'}`)}
            checked={isPublic}
            setter={setIsPublic}
          />
          <Switch
            label={t(
              `common.analyticsEnabled.${enableAnalytics ? 'yes' : 'no'}`,
            )}
            checked={enableAnalytics}
            setter={setEnableAnalytics}
          />
        </Box>
        <Input
          label={t('contents.name')}
          value={pageTitle}
          onChange={handleInputChange(setPageTitle)}
          autoFocus
        />

        <Box paddingBottom={3}>
          <Input
            label={t('contents.slug')}
            value={pageSlug}
            onChange={handleInputChange(setPageSlug)}
          />
          <Box marginTop={-1}>
            {renderLink(true)}
            {renderLink()}
          </Box>
        </Box>

        <FileUpload
          file={pageBgImage}
          setFile={setPageBgImage}
          label={t('common.uploadBackgroundImage')}
          acceptedFiles="image/jpeg, image/png"
        />

        <FileUpload
          file={pagePdfView}
          setFile={setPagePdfView}
          label={t('common.uploadPdfView')}
          acceptedFiles="application/pdf"
        />
      </BlockEditor>

      {editPage && (
        <SharingLink
          targetType="content"
          targetId={editPage.id}
          targetLang={editPage.language?.code}
        />
      )}
    </ContentContainer>
  );
});

export default ContentDetailsForm;
