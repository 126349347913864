import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  padding: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0.8;

  h2 {
    color: ${p => p.theme.colors.white} !important;
  }

  &.unsaved-changes {
    background-color: #f48400;
  }

  &.changes-saved {
    background-color: ${p => p.theme.colors.toastGreenBorder};
  }
`;

interface Props {
  unsavedChanges?: boolean;
  showOnlyWhenUnsaved?: boolean;
}

const UnsavedChanges: React.FC<Props> = ({
  unsavedChanges,
  showOnlyWhenUnsaved,
}) => {
  const { t } = useTranslation();

  if (showOnlyWhenUnsaved && !unsavedChanges) return null;

  const className = unsavedChanges
    ? 'unsaved-changes'
    : unsavedChanges === false
    ? 'changes-saved'
    : '';

  const text = t(`common.unsavedChanges.${unsavedChanges ? 'true' : 'false'}`);

  return (
    <Container className={className}>
      {unsavedChanges !== undefined && (
        <Typography variant="h2">{text}</Typography>
      )}
    </Container>
  );
};

export default UnsavedChanges;
