import React, { FC } from 'react';
import { styled } from '../../styles';

interface ContentAreaProps {}

const ContentAreaBox = styled.div`
  background-color: ${props => props.theme.colors.white};
`;

export const ContentArea: FC<ContentAreaProps> = ({ children }) => {
  return <ContentAreaBox>{children}</ContentAreaBox>;
};
