import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';
import { LanguageModel } from './LanguageModel';

export const TranslationModel = types.model({
  id: types.maybeNull(types.identifierNumber),
  languageId: types.number,
  categoryId: types.number,
  name: types.string,
  language: LanguageModel,
});

export interface ITranslationModel extends Instance<typeof TranslationModel> {}
export interface Translation extends SnapshotOut<typeof TranslationModel> {}
export interface TranslationIn extends SnapshotIn<typeof TranslationModel> {}
