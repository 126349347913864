import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import PlusIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import Accordion from '../../components/Accordion';
import { useStores } from '../../stores';
import QuestionnaireEmailForm from './QuestionnaireEmailForm';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/FormControls';

const T_KEY = 'settings.questionnaireEmails';

const Container = styled.div`
  width: 100%;

  .MuiAccordion-root {
    background-color: ${p => p.theme.colors.greyLighter};
  }

  > * {
    margin 1rem 0;
  }
`;

const AccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  button {
    margin: 0 1rem;
  }
`;

const QuestionnaireEmails: React.FC = observer(() => {
  const { t } = useTranslation();

  const {
    questionnaireEmailStore: {
      state,
      getQuestionnaireEmails,
      questionnaireEmails,
      createQuestionnaireEmail,
      deleteQuestionnaireEmail,
    },
  } = useStores();

  useEffect(() => {
    if (state === 'NOT_FETCHED') getQuestionnaireEmails();
  }, [getQuestionnaireEmails, state]);

  const handleAddNew = () => {
    createQuestionnaireEmail({
      id: -1,
      subject: '',
      message: '',
      recipients: [],
    });
  };

  const handleDelete =
    (id: number, subject: string) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const confirmText = t(`${T_KEY}.deleteConfirm`, {
        subject,
      });
      if (window.confirm(confirmText)) {
        deleteQuestionnaireEmail({ id });
      }
    };

  const accordionSections = questionnaireEmails.map(email => {
    const subject = email.subject || t(`${T_KEY}.emptySubjectPlaceholder`);

    return {
      id: email.id,
      title: (
        <AccordionTitle>
          {subject}
          <IconButton size="small" onClick={handleDelete(email.id, subject)}>
            <DeleteIcon color="error" />
          </IconButton>
        </AccordionTitle>
      ),
      content: <QuestionnaireEmailForm email={email} />,
    };
  });

  return (
    <Container>
      <Accordion sections={accordionSections} />

      <Button
        label={t(`${T_KEY}.addNew`)}
        startIcon={<PlusIcon />}
        onClick={handleAddNew}
        variant="text"
        size="small"
      />
    </Container>
  );
});

export default QuestionnaireEmails;
