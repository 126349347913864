import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockEditorProps, BlockType } from '..';
import { Button } from '../../FormControls';
import TextEditor from '../../TextEditor';
import { BlockForm, handleSubmitBlockForm } from './BlockForm';

const TextBlock: React.FC<BlockEditorProps> = ({ onBlockSave, editBlock }) => {
  const { t } = useTranslation();

  const type: BlockType = 'TextBlock';

  // @ts-ignore
  const [body, setBody] = useState(editBlock?.body ?? '');

  const disabled = editBlock && !editBlock.id;

  const handleSaveBlock = () => {
    const block = {
      type,
      body,
    };

    if (onBlockSave) onBlockSave(block, editBlock);
  };

  return (
    <BlockForm onSubmit={handleSubmitBlockForm(handleSaveBlock)}>
      <TextEditor text={body} setText={setBody} />

      <Button
        label={t(`block-editor.${type}.save`)}
        type="submit"
        disabled={disabled}
        fullWidth
      />
    </BlockForm>
  );
};

export default TextBlock;
