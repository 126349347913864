import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import { Link } from '../Link';

export const CellRenderers = {
  link:
    (linkFn: (row: any) => string) =>
    ({ cell, row: { original } }: any) =>
      (
        <Typography variant="body1" style={cell.column?.customStyle}>
          <Link to={linkFn(original)}>{cell.value}</Link>
        </Typography>
      ),

  linkOnClick:
    (onClick: (row: any) => void) =>
    ({ cell, row: { original } }: any) =>
      (
        <Typography variant="body1" style={cell.column?.customStyle}>
          <Link onClick={() => onClick(original)}>{cell.value}</Link>
        </Typography>
      ),

  linkWrapper:
    (
      linkFn: (row: any) => string,
      cellRenderer: (data: any) => JSX.Element | string,
    ) =>
    (data: any) =>
      <Link to={linkFn(data.row.original)}>{cellRenderer(data)}</Link>,

  text: ({ cell }: any) => (
    <Typography variant="body1" style={cell.column?.customStyle}>
      {cell.value}
    </Typography>
  ),

  textFn:
    (fn: (cell: any, row: any) => string) =>
    ({ cell, row: { original } }: any) =>
      (
        <Typography variant="body1" style={cell.column?.customStyle}>
          {fn(cell, original)}
        </Typography>
      ),

  editIcon:
    (linkFn: (row: any) => string) =>
    ({ row: { original } }: any) =>
      (
        <Link to={linkFn(original)}>
          <IconButton aria-label="edit" size="small">
            <Edit fontSize="small" />
          </IconButton>
        </Link>
      ),

  deleteIcon:
    (deleteFn: (row: any) => any) =>
    ({ row: { original } }: any) =>
      (
        <IconButton
          aria-label="delete"
          onClick={() => deleteFn(original)}
          size="small"
        >
          <Delete fontSize="small" color="error" />
        </IconButton>
      ),
};
