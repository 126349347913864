import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';

export const UserModel = types.model({
  id: types.maybe(types.number),
  username: types.string,
  name: types.string,
  role: types.enumeration(['user', 'admin']),
});

export interface IUserModel extends Instance<typeof UserModel> {}
export interface User extends SnapshotOut<typeof UserModel> {}
export interface UserIn extends SnapshotIn<typeof UserModel> {}
