export const sortByName = (a: { name: string }, b: { name: string }) => {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();
  return aName < bName ? -1 : aName > bName ? 1 : 0;
};

export const sortByDate = (a: { date: string }, b: { date: string }) =>
  a.date < b.date ? -1 : a.date > b.date ? 1 : 0;

export const sortByIndex = (a: { index: number }, b: { index: number }) =>
  a.index - b.index;

export const sortByNumber = (key: string) => (a: any, b: any) =>
  a[key] - b[key];

export const sortByOrder = (
  a: { order?: number | null },
  b: { order?: number | null },
) => {
  return Number(a.order) - Number(b.order);
};
