import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import Papa from 'papaparse';
import { BlockEditorProps, BlockType } from '..';
import Checkbox from '../../Checkbox';
import FileUpload from '../../FileUpload';
import { Button } from '../../FormControls';
import Input, { handleInputChange } from '../../Input';
import { BlockForm, handleSubmitBlockForm } from './BlockForm';
import { useStores } from '../../../stores';
import { transformCsvData } from '../utils/chartCsvTransforms';
import RadioButton, { RadioButtonGroup } from '../../RadioButton';

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    :last-child {
      width: 200px;
      margin-left: 1.5rem;
    }
  }
`;

interface ChartCsvData {
  data: any[];
  groups: any[];
}

const Chart: React.FC<BlockEditorProps> = observer(
  ({ onBlockSave, editBlock }) => {
    const { t } = useTranslation();

    const { notificationStore } = useStores();

    const type: BlockType = 'Chart';

    const [title, setTitle] = useState(editBlock?.title ?? '');

    const [chartType, setChartType] = useState(
      editBlock?.chartType ?? 'linechart',
    );

    const [yUnit, setYUnit] = useState(editBlock?.yUnit ?? '');

    const [filename, setFilename] = useState(editBlock?.filename ?? '');

    const [useWebScraper, setUseWebScraper] = useState(
      editBlock?.useWebScraper ?? false,
    );

    const [webScraperUrl, setWebScraperUrl] = useState(
      editBlock?.webScraperUrl ?? '',
    );

    const [scrapedDataSetLabel, setScrapedDataSetLabel] = useState(
      editBlock?.scrapedDataSetLabel ?? '',
    );

    const [csvData, setCsvData] = useState<ChartCsvData | undefined>(
      editBlock?.csvData ? (editBlock.csvData as ChartCsvData) : undefined,
    );

    const [askFirstForecastRow, setAskFirstForecastRow] = useState(false);

    const disabled = editBlock && !editBlock.id;

    const handleSaveBlock = () => {
      const block = {
        type,
        title,
        chartType,
        yUnit,
        filename,
        csvData,
        useWebScraper,
        webScraperUrl,
        scrapedDataSetLabel,
      };

      if (onBlockSave) onBlockSave(block, editBlock);
    };

    const firstForecastRowPrompt = () => {
      const promptText = t(`block-editor.${type}.firstForecastRow`);
      const defaultText = DateTime.now().toFormat('yyyy/MM/dd');
      return window.prompt(promptText, defaultText);
    };

    const handleCsvDataDrop = (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        Papa.parse(acceptedFiles[0], {
          complete: ({ data: csvData }: { data: any }) => {
            try {
              const nameOfFirstForecastRow = askFirstForecastRow
                ? firstForecastRowPrompt()
                : undefined;
              setCsvData(transformCsvData(csvData, nameOfFirstForecastRow));
              setFilename(acceptedFiles[0].name);
            } catch (err) {
              notificationStore.setError(t('block-editor.invalid-csv-content'));
            }
          },
          error: (error, file) => {
            notificationStore.setError(t('block-editor.error-parsing-csv'));
            console.log(t('block-editor.error-parsing-csv'), error);
          },
        });
      } else {
        notificationStore.setError(t('block-editor.error-file-not-accepted'));
      }
    };

    return (
      <BlockForm onSubmit={handleSubmitBlockForm(handleSaveBlock)}>
        <InputRow>
          <Input
            label={t(`block-editor.${type}.title`)}
            value={title}
            onChange={handleInputChange(setTitle)}
            disabled={disabled}
            autoFocus
          />
          <Input
            label={t(`block-editor.${type}.yUnit`)}
            value={yUnit}
            onChange={handleInputChange(setYUnit)}
            disabled={disabled}
          />
        </InputRow>

        <RadioButtonGroup
          groupName="chart-type"
          value={chartType}
          onChange={e => setChartType(e.currentTarget.value)}
          disabled={disabled}
        >
          <RadioButton value="linechart" label={t('block-editor.linechart')} />
          <RadioButton value="barchart" label={t('block-editor.barchart')} />
        </RadioButtonGroup>

        <Checkbox
          checked={useWebScraper}
          onChange={e => setUseWebScraper(e.target.checked)}
          label={t(`block-editor.${type}.useWebScraper`)}
          disabled={disabled}
        />

        {useWebScraper && (
          <Input
            label={t(`block-editor.${type}.webScraperUrl`)}
            value={webScraperUrl}
            onChange={handleInputChange(setWebScraperUrl)}
            disabled={!useWebScraper || disabled}
          />
        )}

        {useWebScraper && (
          <Input
            label={t(`block-editor.${type}.scrapedDataSetLabel`)}
            value={scrapedDataSetLabel}
            onChange={handleInputChange(setScrapedDataSetLabel)}
            disabled={!useWebScraper || disabled}
          />
        )}

        <Checkbox
          checked={askFirstForecastRow}
          onChange={e => setAskFirstForecastRow(e.target.checked)}
          label={t(`block-editor.${type}.askFirstForecastRow`)}
          disabled={disabled}
        />

        <FileUpload
          label={t(`block-editor.file-input-label`)}
          // acceptedFiles="text/csv"
          customOnDrop={handleCsvDataDrop}
          filename={filename}
          disabled={disabled}
        />

        <Button
          label={t(`block-editor.${type}.save`)}
          type="submit"
          disabled={disabled}
          fullWidth
        />
      </BlockForm>
    );
  },
);

export default Chart;
