import { SelectOption } from '../components/Select';
import { QUESTIONNAIRE_QUESTION_TYPES } from '../constants/constants';
import i18n from '../i18n';

export const getQuestionTypeSelectOptions = (): SelectOption[] => {
  const getLabel = (questionType: string) =>
    i18n.t(`block-editor.Question.questionType.${questionType}`);

  return QUESTIONNAIRE_QUESTION_TYPES.map((questionType: string) => ({
    value: questionType,
    label: getLabel(questionType),
  }));
};
