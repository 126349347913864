import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BlockEditor, { BlockEditorProps, BlockType } from '..';
import { BlockIn, PersonFormPositions } from '../../../stores/models';
import { Button } from '../../FormControls';
import Input, { handleInputChange } from '../../Input';
import Select from '../../Select';
import { BlockForm, handleSubmitBlockForm } from './BlockForm';
import { InputRow } from './styled';
import { BlockCategorySelect } from '../BlockCategorySelect';

const QuestionnaireBlock: React.FC<BlockEditorProps> = ({
  onBlockSave,
  editBlock,
}) => {
  const { t } = useTranslation();

  const type: BlockType = 'QuestionnaireBlock';

  const [blockCategory, setBlockCategory] = useState(
    editBlock?.blockCategory ?? 'main',
  );

  const [title, setTitle] = useState(editBlock?.title ?? '');

  const [personFormPosition, setPersonFormPosition] = useState(
    editBlock?.personFormPosition ?? null,
  );

  const [items, setItems] = useState<BlockIn[]>(editBlock?.items ?? []);

  const disabled = editBlock && !editBlock.id;

  const handleSaveBlock = () => {
    const block = {
      type,
      blockCategory,
      title,
      personFormPosition,
      items,
    };

    if (onBlockSave) onBlockSave(block, editBlock);
  };

  const personFormPositionOptions = useMemo(
    () =>
      PersonFormPositions.map(option => ({
        value: option,
        label: t(`block-editor.${type}.personFormPosition.${option}`),
      })),
    [t],
  );

  return (
    <BlockForm onSubmit={handleSubmitBlockForm(handleSaveBlock)}>
      <InputRow>
        <Input
          label={t(`block-editor.${type}.title`)}
          value={title}
          onChange={handleInputChange(setTitle)}
          disabled={disabled}
          autoFocus
        />

        <Select
          id="person-form-position-select"
          label={t(`block-editor.${type}.personFormPositionLabel`)}
          value={personFormPosition ?? 'hidden'}
          options={personFormPositionOptions}
          onChange={(event: any) => {
            setPersonFormPosition(event?.target?.value ?? null);
          }}
          fullWidth
        />

        <BlockCategorySelect
          category={blockCategory}
          setCategory={setBlockCategory}
        />
      </InputRow>

      <BlockEditor
        blockData={items}
        setBlockData={setItems}
        onBlockSave={onBlockSave}
        availableBlocks={['Question']}
        vertical
      />

      <Button
        label={t(`block-editor.${type}.save`)}
        type="submit"
        fullWidth
        disabled={disabled}
      />
    </BlockForm>
  );
};

export default QuestionnaireBlock;
