import React, { useEffect, useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tabs from '../../components/Tabs';
import { useStores } from '../../stores';
import { observer } from 'mobx-react-lite';
import { Product } from '../../stores/models';
import AreaFilter from '../../components/AreaFilter';
import { SelectOptionValue } from '../../components/Select';
import STORAGE from '../../utils/storage';

const Container = styled.div`
  margin: 2em 0 1em 0;

  .company-products-toolbar {
    margin-top: 2rem;
  }
`;

const TabContent = styled.div`
  display: flex;
  border: 1px solid ${p => p.theme.colors.greyDarker};

  > div {
    flex: 1;

    :first-child {
      background-color: ${p => p.theme.colors.greyLighter};
      border-right: 1px solid ${p => p.theme.colors.greyDarker};
    }
  }
`;

const ProductList = styled.ul`
  list-style-type: none;
  padding: 1em;
  margin: 0;

  > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .products-list__no-items {
    font-style: italic;
    color: ${p => p.theme.colors.greyDarkest};
  }

  .products-list__bulk-action {
    padding-bottom: 0.3em;
    margin-bottom: 0.5em;
    border-bottom: 1px solid ${p => p.theme.colors.greyDarkest};
    color: #666;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 80%;
    &.visible {
      visibility: visible;
    }
    &.hidden {
      visibility: hidden;
    }
  }
`;

interface Props {
  selectedIds: number[];
  setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const CompanyProducts: React.FC<Props> = observer(
  ({ selectedIds, setSelectedIds }) => {
    const { t } = useTranslation();

    const {
      productStore: { state, getProducts, getCategoryProducts, allCategories },
    } = useStores();

    const [tab, setTab] = useState(0);
    const [areaFilter, setAreaFilter] = useState<SelectOptionValue>(
      STORAGE.read({ key: 'AREA' }) ?? 'all',
    );

    useEffect(() => {
      if (state === 'NOT_FETCHED') getProducts();
    }, [getProducts, state]);

    const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
      setTab(newValue);
    };

    const addProducts = (ids: number[]) => {
      const _selectedIds = [...selectedIds, ...ids];
      const uniqueProductIds = Array.from(new Set(_selectedIds));
      setSelectedIds(uniqueProductIds);
    };

    const removeProducts = (ids: number[]) => {
      setSelectedIds(selectedIds.filter(productId => !ids.includes(productId)));
    };

    const getActionIcon = (type: 'all' | 'selected') => {
      switch (type) {
        case 'all':
          return <ArrowRightIcon fontSize="small" />;
        case 'selected':
          return <CloseIcon fontSize="small" />;
        default:
          return null;
      }
    };

    const handleItemClick = (type: 'all' | 'selected', ids: number[]) => () => {
      switch (type) {
        case 'all':
          addProducts(ids);
          break;
        case 'selected':
          removeProducts(ids);
          break;
        default:
        // Do nothing
      }
    };

    const composeProductTitle = ({ name, language, area }: Product) => {
      const langText = language ? ` (${language.code.toUpperCase()})` : '';
      const areaText =
        area && areaFilter === 'all' ? ` [${t(`areas.${area.code}`)}]` : '';
      return `${name}${areaText}${langText}`;
    };

    const renderProductList = (
      products: Product[],
      type: 'all' | 'selected',
    ) => {
      const filteredProducts = products.filter(
        product => areaFilter === 'all' || product.area?.code === areaFilter,
      );

      const ids = filteredProducts.map(({ id }) => id);
      const bulkActionVisibility =
        filteredProducts.length > 1 ? 'visible' : 'hidden';

      return (
        <ProductList>
          {/* Add all / Remove all product items: */}
          <li className={`products-list__bulk-action ${bulkActionVisibility}`}>
            {type === 'all' && t('companies.addAllProducts')}
            {type === 'selected' && t('companies.removeAllProducts')}
            <IconButton onClick={handleItemClick(type, ids)} size="small">
              {getActionIcon(type)}
            </IconButton>
          </li>

          {/* "No items" item: */}
          {!filteredProducts.length && type === 'all' && (
            <li className="products-list__no-items">
              {t('companies.noProducts')}
            </li>
          )}

          {/* List product items: */}
          {filteredProducts.map(({ id, ...product }) => (
            <li key={id}>
              {composeProductTitle({ id, ...product })}
              <IconButton onClick={handleItemClick(type, [id])} size="small">
                {getActionIcon(type)}
              </IconButton>
            </li>
          ))}
        </ProductList>
      );
    };

    const renderTabContent = (categoryId: number) => {
      const products = getCategoryProducts(categoryId);
      const selectedProducts = products.filter(({ id }) =>
        selectedIds.includes(id),
      );

      return (
        <TabContent>
          <div>{renderProductList(products, 'all')}</div>
          <div>{renderProductList(selectedProducts, 'selected')}</div>
        </TabContent>
      );
    };

    const tabs = (allCategories ?? []).map(({ id, name }) => ({
      id,
      label: name,
      content: renderTabContent(id),
    }));

    return (
      <Container>
        <Typography variant="h3">{t('companies.companyProducts')}</Typography>

        <div className="company-products-toolbar">
          <AreaFilter value={areaFilter} setValue={setAreaFilter} />
        </div>

        <Tabs value={tab} onChange={handleTabChange} tabs={tabs} />
      </Container>
    );
  },
);

export default CompanyProducts;
