import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { User } from '../stores/models';
import { Button } from './FormControls';

const UserContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleContainer = styled(Box)`
  padding: 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  user-select: none;
`;

const InfoContainer = styled(Box)`
  border: 1px solid ${props => props.theme.colors.greyDarkest};
  padding: 16px;
  flex: 1;
  word-wrap: break-word;
`;

const ButtonContainer = styled(Box)`
  margin-top: 16px;
  display: flex;
  gap: 0.5em;
`;

interface UserItemProps {
  user: User;
  onDelete?: (userId: number) => void;
}

export const UserItem: FC<UserItemProps> = ({ user, onDelete }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const toggleInfo = () => {
    setIsOpen(!isOpen);
  };

  const handleDelete = (user: User) => {
    if (onDelete) {
      if (window.confirm(t('user.deleteUserConfirmation'))) {
        const id = Number(user.id);
        onDelete(id);
      }
    }
  };

  return (
    <UserContainer>
      <TitleContainer onClick={toggleInfo}>
        <Typography>{user.name}</Typography>
        {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </TitleContainer>

      {isOpen && (
        <InfoContainer>
          <Typography variant="body2">{user.username}</Typography>

          <ButtonContainer>
            {onDelete && (
              <Button
                label={t('user.deleteUser')}
                onClick={() => handleDelete(user)}
                style={{ backgroundColor: '#B80F0A', color: '#ffffff' }}
                size="small"
              />
            )}
          </ButtonContainer>
        </InfoContainer>
      )}
    </UserContainer>
  );
};

export default UserItem;
