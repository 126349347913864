export const UNALLOWED_DATA_CELL_CHARS_RE = /[^-0-9,.]/g;

export const convertCellToNumeric = cell => {
  if (cell === undefined || cell === null || cell === '') {
    return undefined;
  }
  const cellStr = String(cell).trim();
  const cellWithValidChars = cellStr.replace(UNALLOWED_DATA_CELL_CHARS_RE, '');
  const cellWithDotDecimal = cellWithValidChars.replace(/,/g, '.');
  const numericCell = Number(cellWithDotDecimal);
  return Number.isFinite(numericCell) ? numericCell : undefined;
};
export const convertRowToNumeric = (dataRow = []) => {
  const [headerCell, ...bodyCells] = dataRow;
  const convertedBodyCells = bodyCells.map(convertCellToNumeric);
  return [headerCell, ...convertedBodyCells];
};
export const convertBodyCellsToNumeric = (csvData = []) => {
  const [headerRow, ...dataRows] = csvData;
  const convertedRows = dataRows.map(convertRowToNumeric);
  return [headerRow, ...convertedRows];
};

export const validateCell = (cell = '') => {
  const cellStr = String(cell).trim();
  const validatedCellStr = cellStr.replace(UNALLOWED_DATA_CELL_CHARS_RE, '');
  return validatedCellStr === cellStr;
};
export const validateRow = (dataRow = []) => {
  // eslint-disable-next-line no-unused-vars
  const [headerCell, ...bodyCells] = dataRow;
  const isValidRow = bodyCells.every(validateCell);
  return isValidRow;
};
export const validateBodyCells = (csvData = []) => {
  // eslint-disable-next-line no-unused-vars
  const [headerRow, ...dataRows] = csvData;
  const isValidTable = dataRows.every(validateRow);
  return isValidTable;
};
