import {
  Instance,
  types,
  flow,
  cast,
  applySnapshot,
  getSnapshot,
  getParent,
} from 'mobx-state-tree';
import { ERROR } from '../constants/constants';
import i18n from '../i18n';
import { api } from '../services/api/ApiClient';
import { AreaModel } from './models';

const States = [
  'NOT_FETCHED' as const,
  'FETCHING' as const,
  'FETCHED' as const,
  'ERROR' as const,
];

export const AreaStore = types
  .model({
    data: types.array(AreaModel),
    state: types.enumeration('State', States),
  })

  .views(self => ({
    get areas() {
      return getSnapshot(self.data);
    },
  }))
  .actions(self => {
    let initialState = {};

    const getAreas = flow(function* (params: Api.Req.GetAreas = {}) {
      const { notificationStore } = getParent(self);
      self.state = 'FETCHING';

      const response: Api.Response<Api.Res.GetAreas> = yield api.getAreas(
        params,
      );

      if (response.kind === 'ok') {
        self.data = cast(response.data);
        self.state = 'FETCHED';
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.state = 'ERROR';
      }
    });

    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      reset: () => {
        applySnapshot(self, initialState);
      },
      getAreas,
    };
  });

export interface IAreaStore extends Instance<typeof AreaStore> {}

export default AreaStore;
