import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Company } from '../../stores/models';
import CompanyUsersForm from './CompanyUsersForm';
import styled from 'styled-components';
import CompanyProducts from './CompanyProducts';
import { Button } from '../../components/FormControls';
import { useStores } from '../../stores';
import Input, { handleInputChange } from '../../components/Input';

const CompanyContainer = styled(Box)`
  display: flex;
  padding: 1em 0;
`;

const MainColumn = styled.div`
  flex: 1;
  border-right: 1px solid ${p => p.theme.colors.greyDarkest};
  padding-right: 1em;
`;

const FormItem = styled(Box)`
  margin: 2em 0;
`;

const SideColumn = styled.div`
  width: 280px;
  padding-left: 1em;
`;

interface CompanyDetailsFormProps {
  editCompany?: Company;
  onSubmit: (formValues: any) => void;
}

export const CompanyDetailsForm: FC<CompanyDetailsFormProps> = observer(
  ({ editCompany, onSubmit }) => {
    const { t } = useTranslation();

    const {
      companyStore: { state },
    } = useStores();

    const isSaving = state === 'SAVING';

    const [name, setName] = useState('');
    const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);

    useEffect(() => {
      if (editCompany) {
        setName(editCompany.name ?? '');
        setSelectedProductIds(editCompany.productIds ?? []);
      }
    }, [editCompany]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit({
        id: editCompany?.id,
        name,
        productIds: selectedProductIds,
      });
    };

    return (
      <CompanyContainer>
        <MainColumn>
          <form onSubmit={handleSubmit}>
            <Typography variant="h3">
              {editCompany
                ? t('companies.editCompany')
                : t('companies.newCompany')}
            </Typography>

            <FormItem>
              <Input
                label={t('companies.name')}
                value={name}
                onChange={handleInputChange(setName)}
                required
                autoFocus
              />
            </FormItem>

            <CompanyProducts
              selectedIds={selectedProductIds}
              setSelectedIds={setSelectedProductIds}
            />

            <FormItem>
              <Button
                label={t('common.save')}
                type="submit"
                loading={isSaving}
              />
            </FormItem>
          </form>
        </MainColumn>

        <SideColumn>
          {!!editCompany && <CompanyUsersForm editCompany={editCompany} />}
        </SideColumn>
      </CompanyContainer>
    );
  },
);

export default CompanyDetailsForm;
