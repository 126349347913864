import {
  Instance,
  types,
  flow,
  cast,
  applySnapshot,
  getSnapshot,
  getParent,
} from 'mobx-state-tree';
import { ERROR, SUCCESS } from '../constants/constants';
import i18n from '../i18n';
import { api } from '../services/api/ApiClient';
import { QuestionnaireEmail, QuestionnaireEmailModel } from './models';

const States = [
  'NOT_FETCHED' as const,
  'FETCHING' as const,
  'FETCHED' as const,
  'ERROR' as const,
];

const ProcessState = ['IDLE' as const, 'PROCESSING' as const, 'ERROR' as const];

const sortBySubject = (a: QuestionnaireEmail, b: QuestionnaireEmail) => {
  if (a.subject.trim() === '') return 1;
  if (b.subject.trim() === '') return -1;
  return a.subject.localeCompare(b.subject);
};

export const QuestionnaireEmailStore = types
  .model({
    state: types.enumeration('State', States),
    processState: types.enumeration('State', ProcessState),
    data: types.array(QuestionnaireEmailModel),
  })

  .views(self => ({
    get questionnaireEmails() {
      return [...getSnapshot(self.data)].sort(sortBySubject);
    },
  }))
  .actions(self => {
    let initialState = {};

    const getQuestionnaireEmails = flow(function* (
      params: Api.Req.GetQuestionnaireEmails = {},
    ) {
      const { notificationStore } = getParent(self);
      self.state = 'FETCHING';

      const response: Api.Response<Api.Res.GetQuestionnaireEmails> =
        yield api.getQuestionnaireEmails(params);

      if (response.kind === 'ok') {
        self.data = cast(response.data);
        self.state = 'FETCHED';
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.state = 'ERROR';
      }
    });

    const createQuestionnaireEmail = flow(function* (
      params: Api.Req.CreateQuestionnaireEmail,
    ) {
      const { notificationStore } = getParent(self);
      self.processState = 'PROCESSING';

      const response: Api.Response<Api.Res.CreateQuestionnaireEmail> =
        yield api.createQuestionnaireEmail(params);

      if (response.kind === 'ok') {
        self.data = cast([...self.data, response.data]);
        self.processState = 'IDLE';
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.processState = 'ERROR';
      }
    });

    const editQuestionnaireEmail = flow(function* (
      params: Api.Req.EditQuestionnaireEmail,
    ) {
      const { notificationStore } = getParent(self);
      self.processState = 'PROCESSING';

      const response: Api.Response<Api.Res.EditQuestionnaireEmail> =
        yield api.editQuestionnaireEmail(params);

      if (response.kind === 'ok') {
        updateDataItem(response.data);
        notificationStore.setSuccess(i18n.t(SUCCESS.EDIT_SUCCESS));
        self.processState = 'IDLE';
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.processState = 'ERROR';
      }
    });

    const deleteQuestionnaireEmail = flow(function* (
      params: Api.Req.DeleteQuestionnaireEmail,
    ) {
      const { notificationStore } = getParent(self);
      self.processState = 'PROCESSING';

      const response: Api.Response<Api.Res.DeleteQuestionnaireEmail> =
        yield api.deleteQuestionnaireEmail(params);

      if (response.kind === 'ok' && response.data >= 1) {
        self.data = cast(self.data.filter(({ id }) => id !== params.id));
        notificationStore.setSuccess(i18n.t(SUCCESS.DELETE_SUCCESS));
        self.processState = 'IDLE';
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.processState = 'ERROR';
      }
    });

    const sendQuestionnaireEmail = flow(function* (
      params: Api.Req.SendQuestionnaireEmail,
    ) {
      const { notificationStore } = getParent(self);
      self.processState = 'PROCESSING';

      const response: Api.Response<Api.Res.SendQuestionnaireEmail> =
        yield api.sendQuestionnaireEmail(params);

      if (response.kind === 'ok') {
        const { questionnaireEmail, accepted, rejected } = response.data;
        updateDataItem(questionnaireEmail);
        notificationStore.setSuccess(i18n.t(SUCCESS.SEND_SUCCESS));
        self.processState = 'IDLE';
        return { accepted, rejected };
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.processState = 'ERROR';
        return { accepted: [], rejected: [i18n.t(ERROR.GENERAL_ERROR)] };
      }
    });

    /**
     *
     *
     * Private helpers etc.
     *
     *
     */
    const updateDataItem = (updatedItem: QuestionnaireEmail) => {
      self.data = cast(
        self.data.map(item => {
          if (item.id === updatedItem.id) return updatedItem;
          return item;
        }),
      );
    };

    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      reset: () => {
        applySnapshot(self, initialState);
      },
      getQuestionnaireEmails,
      createQuestionnaireEmail,
      editQuestionnaireEmail,
      deleteQuestionnaireEmail,
      sendQuestionnaireEmail,
    };
  });

export interface IQuestionnaireEmailStore
  extends Instance<typeof QuestionnaireEmailStore> {}

export default QuestionnaireEmailStore;
