import { FormControlLabel, Switch as MuiSwitch } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  label {
    .MuiFormControlLabel-label {
      font-size: 0.8rem;
    }
  }
`;

interface Props {
  label: string;
  checked: boolean;
  setter: React.Dispatch<React.SetStateAction<boolean>>;
}

const Switch: React.FC<Props> = ({ label, checked, setter }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setter(event.target.checked);
  };

  return (
    <Container>
      <FormControlLabel
        control={<MuiSwitch checked={checked} onChange={handleChange} />}
        label={label}
      />
    </Container>
  );
};

export default Switch;
