import React from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';

const Container = styled.div`
  .MuiAccordionSummary-root {
    padding: 0 16px;
  }
`;

interface AccordionSection {
  id: string | number;
  title: string | JSX.Element;
  content: string | JSX.Element | null;
  defaultExpanded?: boolean;
}

interface Props {
  sections: AccordionSection[];
}

export const Accordion: React.FC<Props> = ({ sections }) => (
  <Container>
    {sections.map(
      ({ id, title, content, defaultExpanded }) =>
        content !== null && (
          <MuiAccordion key={id} defaultExpanded={defaultExpanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${id}-content`}
              id={`panel-${id}-header`}
            >
              {title}
            </AccordionSummary>
            <AccordionDetails>{content}</AccordionDetails>
          </MuiAccordion>
        ),
    )}
  </Container>
);

export default Accordion;
