import { Box, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '../../components/FormControls';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../stores/RootStoreContext';
import { styled } from '../../styles';
import {
  composeValidators,
  isLogin,
  minLength,
  required,
} from '../../utils/validators';

interface AddAdminFormProps {}

const FormContainer = styled(Box)`
  width: 500px;
`;

export const AddAdminForm: FC<AddAdminFormProps> = observer(() => {
  const { t } = useTranslation();
  const { userStore } = useStores() as RootStore;

  const onAddAdmin = async (
    values: { username: string; name: string; password: string },
    form: any,
    callback: any,
  ) => {
    await userStore.addUser({
      ...values,
      role: 'admin',
    });
    form.restart();
  };

  const FIELDS = [
    {
      id: 'username',
      label: 'login.username',
      validate: composeValidators(required, isLogin),
    },
    {
      id: 'name',
      label: 'user.fullName',
      validate: composeValidators(required, minLength(1)),
    },
    {
      id: 'password',
      label: 'user.password',
      validate: composeValidators(required, minLength(1)),
    },
  ];

  return (
    <Form
      onSubmit={onAddAdmin}
      subscription={{
        submitting: true,
        pristine: true,
        hasValidationErrors: true,
      }}
      render={({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Typography variant="h3">
              {t('settings.admins.addAdmin')}
            </Typography>

            <FormContainer>
              {FIELDS.map(({ id, label, validate }) => (
                <Grid container spacing={8} justifyContent="center" key={id}>
                  <Grid item xs={12}>
                    <Typography>{t(label)}</Typography>
                    <Input
                      validate={validate}
                      type={id === 'password' ? id : undefined}
                      id={id}
                      name={id}
                    />
                  </Grid>
                </Grid>
              ))}
              <Grid container spacing={8} justifyContent="center">
                <Grid item xs={12}>
                  <Button label={t('user.addUser')} type="submit" />
                </Grid>
              </Grid>
              {/* <Grid container spacing={8} justifyContent="center">
                <Grid item>
                  <Typography>
                    <Link to="/password-reset-request">{t('login.forgotPasswordLink')}</Link>
                  </Typography>
                </Grid>
              </Grid> */}
            </FormContainer>
          </form>
        );
      }}
    />
  );
});

export default AddAdminForm;
