import React, { FC } from 'react';
import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
} from '@material-ui/core';
import { styled } from '../../styles/styledTheme';

const StyledButton = styled(MuiButton)`
  border-radius: 50px;
  padding: 4px 20px;
  min-width: 160px;
`;

const LoadingLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: ${p => p.theme.colors.white};
  font-style: italic;
`;

interface SubmitButtonProps extends ButtonProps {
  label: string;
  loading?: boolean;
}

export const Button: FC<SubmitButtonProps> = ({
  label,
  loading,
  size = 'large',
  type = 'button',
  variant = 'contained',
  color = 'primary',
  disabled,
  ...props
}) => {
  const LoadingEl = (
    <LoadingLabel>
      <CircularProgress color="inherit" size={20} />
      {/* Empty placeholder (thin space) to "keep" the original line height */}
      {'\u2009'}
    </LoadingLabel>
  );

  return (
    <StyledButton
      size={size}
      type={type}
      variant={variant}
      color={color}
      disableElevation
      disabled={disabled || loading}
      {...props}
    >
      {loading ? LoadingEl : label}
    </StyledButton>
  );
};
