import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Add from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { QuestionnaireEmail } from '../../stores/models/QuestionnaireEmailModel';
import { Button } from '../../components/FormControls';
import { useStores } from '../../stores';
import { formatDateTime } from '../../utils/date';
import { PersonDto } from '../../stores/models';
import Modal from '../../components/Modal';
import ModifyPersonForm from '../../components/ModifyPersonForm';

const SAVE_DELAY = 2; // seconds
const T_KEY = 'settings.questionnaireEmails';

const getPersonDetails = ({ title, name, company, email }: PersonDto) => {
  const titleText = title ? `${title} ` : '';
  const nameText = name ? name : '';
  const companyText = company ? `, ${company}` : '';
  const text = `${titleText}${nameText}${companyText}`;
  return text.length ? text : email;
};

const StyledForm = styled.form`
  width: 100%;

  > * {
    margin-bottom: 1rem;
  }

  .MuiTextField-root > .MuiInputBase-root {
    background-color: ${p => p.theme.colors.white};
  }

  label {
    font-weight: bold;
  }
`;

const HelpText = styled.span`
  margin-left: 1rem;
  font-style: italic;
  font-size: 0.8rem;
  color: ${p => p.theme.colors.secondary};
`;

type InputEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;
type SetStateType = React.Dispatch<React.SetStateAction<string>>;

interface Props {
  email: QuestionnaireEmail;
}

const QuestionnaireEmailForm: React.FC<Props> = observer(({ email }) => {
  const { t } = useTranslation();

  const {
    questionnaireEmailStore: {
      editQuestionnaireEmail,
      processState,
      sendQuestionnaireEmail,
    },
  } = useStores();

  const isBusy = processState === 'PROCESSING';

  // Form data
  const [subject, setSubject] = useState(email.subject ?? '');
  const [message, setMessage] = useState(email.message ?? '');
  const [link, setLink] = useState(email.link ?? '');
  const [recipients, setRecipients] = useState<PersonDto[]>(
    email.recipients ?? [],
  );

  // Tells if some changes were made to form
  const [changesMade, setChangesMade] = useState(false);

  // How many emails were accepted/rejected
  const [accepted, setAccepted] = useState<string[]>([]);
  const [rejected, setRejected] = useState<string[]>([]);

  const notNullableValue = (text: string) => text.trim();
  const nullableValue = (text: string) => {
    const trimmedText = text.trim();
    return !trimmedText.length ? null : trimmedText;
  };
  const getRecipient = (recipientId?: string | null) =>
    recipients.find(({ id }) => id === recipientId);

  /**
   * Form data collected in one object (questionnaire email)
   */
  const formData = useMemo(
    () => ({
      ...email,
      subject: notNullableValue(subject),
      message: notNullableValue(message),
      link: nullableValue(link),
      recipients,
    }),
    [email, link, message, recipients, subject],
  );

  useEffect(() => {
    setRecipients(email.recipients);
    setAccepted([]);
    setRejected([]);
  }, [email]);

  /**
   * Save changes after user stopped typing
   */
  useEffect(() => {
    const delay = setTimeout(async () => {
      if (changesMade) {
        await editQuestionnaireEmail(formData);
        setChangesMade(false);
      }
    }, SAVE_DELAY * 1000);

    return () => clearTimeout(delay);
  }, [changesMade, editQuestionnaireEmail, formData]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const confirmText = t(`${T_KEY}.sendEmailConfirm`, {
      subject: email.subject,
      recipients: email.recipients.length,
    });

    if (window.confirm(confirmText)) {
      const { accepted, rejected } = await sendQuestionnaireEmail({
        id: email.id,
      });
      setAccepted(accepted);
      setRejected(rejected);
    }
  };

  const handleInputChange = (setValue: SetStateType) => (event: InputEvent) => {
    setValue(event.currentTarget.value);
    setChangesMade(true);
  };

  const handleAddRecipient = async (newRecipient: PersonDto) => {
    await editQuestionnaireEmail({
      ...formData,
      recipients: [...recipients, newRecipient],
    });
  };

  const handleModifyRecipient = async (updatedRecipient: PersonDto) => {
    await editQuestionnaireEmail({
      ...formData,
      recipients: recipients.map(recipient => {
        if (recipient.id === updatedRecipient.id) return updatedRecipient;
        return recipient;
      }),
    });
  };

  const handleRemoveRecipient = async (recipientId?: string | null) => {
    const recipient = getRecipient(recipientId);
    if (recipient) {
      const confirmText = t(`${T_KEY}.removeRecipientConfirm`, {
        name: getPersonDetails(recipient),
      });
      if (window.confirm(confirmText)) {
        await editQuestionnaireEmail({
          ...formData,
          recipients: recipients.filter(({ id }) => id !== recipientId),
        });
      }
    }
  };

  const renderRecipientLabel = ({ title, name, email, company }: PersonDto) => (
    <>
      {!!title?.length && <div>{title}</div>}
      <div>{`${name || email}${company?.length ? `, ${company}` : ''}`}</div>
    </>
  );

  const renderSentResults = (
    severity: 'error' | 'success',
    titleKey: string,
    list: string[],
  ) => (
    <Alert severity={severity}>
      <AlertTitle>
        {t(`${T_KEY}.${titleKey}`, { count: list.length })}
      </AlertTitle>
      {list.map((email, i) => (
        <div key={`${email}-${i}`}>{email}</div>
      ))}
    </Alert>
  );

  return (
    <StyledForm onSubmit={handleSubmit}>
      <div>
        <FormControl fullWidth>
          <label htmlFor="questionnaire-email__subject-input">
            {t(`${T_KEY}.subject`)}
          </label>
          <TextField
            id="questionnaire-email__subject-input"
            value={subject}
            placeholder={t(`${T_KEY}.subjectPlaceholder`)}
            onChange={handleInputChange(setSubject)}
            variant="outlined"
            size="small"
          />
        </FormControl>
      </div>

      <div>
        <FormControl fullWidth>
          <label htmlFor="questionnaire-email__message-input">
            {t(`${T_KEY}.message`)}
          </label>
          <TextField
            id="questionnaire-email__message-input"
            value={message}
            placeholder={t(`${T_KEY}.messagePlaceholder`)}
            onChange={handleInputChange(setMessage)}
            multiline
            rows={5}
            variant="outlined"
            size="small"
          />
          <HelpText>{t(`${T_KEY}.messageInfo`)}</HelpText>
        </FormControl>
      </div>

      <div>
        <FormControl fullWidth>
          <label htmlFor="questionnaire-email__link-input">
            {t(`${T_KEY}.link`)}
          </label>
          <TextField
            id="questionnaire-email__link-input"
            value={link}
            placeholder={t(`${T_KEY}.linkPlaceholder`)}
            onChange={handleInputChange(setLink)}
            variant="outlined"
            size="small"
          />
        </FormControl>
      </div>

      <div>
        <div>
          <label>{t(`${T_KEY}.recipients.title`)}</label>
        </div>
        {recipients.map((recipient, i) => (
          <Modal
            key={`${recipient.id}-${i}`}
            title={`${t('common.edit')}: ${recipient.name ?? recipient.email}`}
            maxWidth="sm"
            trigger={
              <Chip
                clickable
                label={renderRecipientLabel(recipient)}
                onDelete={
                  recipient.id
                    ? () => handleRemoveRecipient(recipient.id)
                    : undefined
                }
              />
            }
            content={toggleModal => (
              <ModifyPersonForm
                person={recipient}
                onSubmit={handleModifyRecipient}
                closeForm={toggleModal}
              />
            )}
          />
        ))}

        <Modal
          title={t('settings.persons.addNew')}
          maxWidth="sm"
          trigger={
            <Button
              label={t('settings.persons.addNew')}
              size="small"
              variant="outlined"
              startIcon={<Add />}
              disabled={changesMade || isBusy}
            />
          }
          content={toggleModal => (
            <ModifyPersonForm
              person={undefined}
              onSubmit={handleAddRecipient}
              closeForm={toggleModal}
            />
          )}
        />
      </div>

      {!!accepted.length &&
        renderSentResults('success', 'sendEmailAccepted', accepted)}

      {!!rejected.length &&
        renderSentResults('error', 'sendEmailRejected', rejected)}

      <div>
        <Button
          label={t('common.send')}
          type="submit"
          loading={isBusy}
          disabled={changesMade || isBusy}
        />
        {email.lastSent && (
          <HelpText>
            {t(`${T_KEY}.lastSent`, { date: formatDateTime(email.lastSent) })}
          </HelpText>
        )}
      </div>
    </StyledForm>
  );
});

export default QuestionnaireEmailForm;
