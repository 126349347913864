import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';

const TriggerContainer = styled.div<{ fullWidth?: boolean }>`
  display: ${p => (p.fullWidth ? 'block' : 'inline-block')};
  width: ${p => (p.fullWidth ? '100%' : undefined)};
  margin: 0.5em 0.3em;
`;

const ModalContainer = styled.div`
  padding: 0.5em 0.5em 2em 0.5em;
`;

export type ModalContent =
  | string
  | JSX.Element
  | ((toggleModal: () => void) => string | JSX.Element);

interface Props {
  title?: string;
  trigger?: JSX.Element | string;
  triggerFullWidth?: boolean;
  content: ModalContent;
  maxWidth?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl';
}

const Modal: React.FC<Props> = ({
  title,
  trigger,
  triggerFullWidth,
  content,
  maxWidth = 'md',
}) => {
  const [open, setOpen] = useState(false);

  const toggleModal = () => {
    setOpen(!open);
  };

  return (
    <>
      <TriggerContainer onClick={toggleModal} fullWidth={triggerFullWidth}>
        {trigger ?? title}
      </TriggerContainer>

      <Dialog fullWidth maxWidth={maxWidth} open={open} onClose={toggleModal}>
        <ModalContainer>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            {typeof content === 'function' ? content(toggleModal) : content}
          </DialogContent>
        </ModalContainer>
      </Dialog>
    </>
  );
};

export default Modal;
