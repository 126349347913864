import { SelectOption } from '../components/Select';
import i18n from '../i18n';
import { Area } from '../stores/models';

const getLabel = (areaCode: string) => i18n.t(`areas.${areaCode}`);

export const getAreaSelectOptions = (areas: Area[]): SelectOption[] => {
  return areas.map(({ id, name, code }: Area) => ({
    value: id,
    label: getLabel(code) ?? name,
  }));
};

const DEFAULT_OPTON: Area = { id: 0, code: 'all', name: 'All' };

export const getAreaFilterOptions = (areas: Area[]): SelectOption[] => {
  return [DEFAULT_OPTON, ...areas].map(({ id, name, code }: Area) => ({
    value: code,
    label: getLabel(code) ?? name,
  }));
};
