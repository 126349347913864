import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockEditorProps, BlockType } from '..';
import { Button } from '../../FormControls';
import Input, { handleInputChange } from '../../Input';
import { BlockForm, handleSubmitBlockForm } from './BlockForm';
import { InputRow } from './styled';
import { BlockCategorySelect } from '../BlockCategorySelect';

const VideoBlock: React.FC<BlockEditorProps> = ({ onBlockSave, editBlock }) => {
  const { t } = useTranslation();

  const type: BlockType = 'VideoBlock';

  const [blockCategory, setBlockCategory] = useState(
    editBlock?.blockCategory ?? 'main',
  );

  const [videoSrc, setVideoSrc] = useState(editBlock?.videoSrc ?? '');

  const disabled = editBlock && !editBlock.id;

  const handleSaveBlock = () => {
    const block = {
      type,
      blockCategory,
      videoSrc,
    };

    if (onBlockSave) onBlockSave(block, editBlock);
  };

  return (
    <BlockForm onSubmit={handleSubmitBlockForm(handleSaveBlock)}>
      <InputRow>
        <Input
          label={t(`block-editor.${type}.videoSrc`)}
          value={videoSrc}
          onChange={handleInputChange(setVideoSrc)}
          disabled={disabled}
        />
        <BlockCategorySelect
          category={blockCategory}
          setCategory={setBlockCategory}
        />
      </InputRow>

      <Button
        label={t(`block-editor.${type}.save`)}
        type="submit"
        disabled={disabled}
        fullWidth
      />
    </BlockForm>
  );
};

export default VideoBlock;
