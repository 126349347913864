import React, { useEffect, useState } from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../stores';
import styled from 'styled-components';
import { Button } from '../../components/FormControls';

const Form = styled.form`
  > div {
    margin: 2em 0 0 0;
  }
`;

type SelectEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

type InputEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

type SetStateType = React.Dispatch<React.SetStateAction<string>>;

interface Props {}

const SendEmail: React.FC<Props> = () => {
  const { t } = useTranslation();

  const [companyId, setCompanyId] = useState<number>(-1);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const {
    companyStore: { state, sortedCompanies, getCompanies, sendCompanyEmail },
  } = useStores();

  useEffect(() => {
    if (state === 'NOT_FETCHED') {
      getCompanies();
    }
  }, [getCompanies, state]);

  const setInitialState = () => {
    setCompanyId(-1);
    setSubject('');
    setMessage('');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (companyId > 0 && subject.length && message.length) {
      const email = {
        companyId,
        subject,
        text: message,
      };

      sendCompanyEmail(email);
      setInitialState();
    }
  };

  const handleSelectChange = (event: SelectEvent) => {
    setCompanyId(Number(event.target.value));
  };

  const handleInputChange = (setValue: SetStateType) => (event: InputEvent) => {
    setValue(event.currentTarget.value);
  };

  return (
    <div>
      <Typography variant="h3">
        {t('settings.communications.sendEmail')}
      </Typography>

      <Form onSubmit={handleSubmit}>
        <FormControl fullWidth>
          <Typography>{t('settings.communications.company')}</Typography>
          <Select
            id="send-email__company-select"
            value={companyId}
            onChange={handleSelectChange}
            variant="outlined"
          >
            <MenuItem value={-1}>
              {t('settings.communications.defaultSelectOption')}
            </MenuItem>
            {sortedCompanies?.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <Typography>{t('settings.communications.subject')}</Typography>
          <TextField
            value={subject}
            onChange={handleInputChange(setSubject)}
            variant="outlined"
            size="small"
          />
        </FormControl>

        <FormControl fullWidth>
          <Typography>{t('settings.communications.message')}</Typography>
          <TextField
            value={message}
            onChange={handleInputChange(setMessage)}
            rows={5}
            variant="outlined"
            multiline
          />
        </FormControl>

        <div>
          <Button label={t('common.send')} type="submit" />
        </div>
      </Form>
    </div>
  );
};

export default SendEmail;
