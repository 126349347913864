import React from 'react';
import styled from 'styled-components';

const Container = styled.span`
  display: block;
  margin: 0 1em;

  > span {
    display: inline-block;
    margin: 0 0.2em;
    padding: 0 0.5em;
    border: 1px solid ${p => p.theme.colors.white};
    background-color: ${p => p.theme.colors.grey};
    color: ${p => p.theme.colors.primary};
    font-size: 75%;
    text-transform: uppercase;
    border-radius: 10px;
  }
`;

interface Props {
  badges: string[];
}

const Badges: React.FC<Props> = ({ badges }) => {
  if (!badges.length) return null;

  return (
    <Container>
      {badges.map((badge, i) => (
        <span key={`${badge}-${i}`}>{badge}</span>
      ))}
    </Container>
  );
};

export default Badges;
