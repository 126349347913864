import React, { FC, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useStores, RootStore } from '../../stores';
import Layout from '../../components/Layout/Layout';
import ContentDetailsForm from './ContentDetailsForm';
import { InnerContentContainer } from '../../components/Layout';

export const ContentScreen: FC = observer(() => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string | undefined }>();
  const pageId = Number(id);
  const pageIdRef = useRef<number>();

  const {
    contentStore: { contentPageState, page, getContentPage },
  } = useStores() as RootStore;

  const isBusy = contentPageState === 'FETCHING';
  const isEditing = !!page && page.id === pageId;

  useEffect(() => {
    if (!isBusy && pageId && pageId !== pageIdRef.current) {
      pageIdRef.current = pageId;
      getContentPage({ id: pageId });
    }
  }, [getContentPage, isBusy, pageId]);

  const getPageTitle = () => {
    const title = t(`screens.content`);
    const details = isEditing ? t(`common.edit`) : t(`contents.addNew`);
    return !isBusy ? `${title} - ${details}` : title;
  };

  return (
    <Layout pageTitle={getPageTitle()} loading={isBusy}>
      <InnerContentContainer>
        {!isBusy && (
          <ContentDetailsForm editPage={isEditing ? page : undefined} />
        )}
      </InnerContentContainer>
    </Layout>
  );
});

export default ContentScreen;
