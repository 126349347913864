import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useStores } from '../../stores';
import { IUserModel } from '../../stores/models';
import UserItem from '../../components/UserItem';

const Container = styled(Box)`
  width: 100%;
  margin-left: 2em;
`;

interface AdminListProps {}

export const AdminList: FC<AdminListProps> = observer(() => {
  const { t } = useTranslation();

  const { userStore } = useStores();

  const handleDelete = (userId: number) => {
    userStore.deleteUser({ id: userId });
  };

  return (
    <Container>
      <Typography variant="h3">{t('user.admins')}</Typography>

      {userStore.admins?.map((user: IUserModel) => (
        <UserItem key={user.id} user={user} onDelete={handleDelete} />
      ))}
    </Container>
  );
});

export default AdminList;
