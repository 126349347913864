import React from 'react';
import { IconButton } from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BlockForm, BlockType } from '.';
import { BlockCategory, BlockIn } from '../../stores/models';
import Modal from '../Modal';
import { copyBlock, getBlockPreviewText } from './utils';

const Container = styled.div`
  width: 100%;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.1em 0.5em;

      :not(:last-child) {
        border-bottom: 1px solid ${p => p.theme.colors.grey};
      }

      .block__main-column {
        flex: 1;
        padding-left: 0.5em;
      }

      .block-title {
        font-weight: bold;
        &__details {
          font-weight: normal;
          margin-left: 0.5rem;
          font-size: 0.7rem;
        }
      }
      .block-preview {
        > span {
          font-size: 90%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
`;

interface Props {
  blockData: BlockIn[];
  onBlockDataUpdate: (
    blockCategory?: BlockCategory | null,
  ) => (newBlockData: BlockIn[]) => void;
  getBlockForm: (blockType?: BlockType | null, block?: BlockIn) => BlockForm;
}

const BlockList: React.FC<Props> = ({
  blockData,
  onBlockDataUpdate,
  getBlockForm,
}) => {
  const { t } = useTranslation();

  const handleMoveBlock = (blockPosition: number, direction: 1 | -1) => () => {
    const newPosition = blockPosition + direction;

    if (newPosition >= 0 || newPosition < blockData.length) {
      const _blockData = [...blockData];
      const block = _blockData.splice(blockPosition, 1)[0];
      _blockData.splice(newPosition, 0, block);
      onBlockDataUpdate(block.blockCategory)(_blockData);
    }
  };

  const handleDeleteBlock = (blockPosition: number) => () => {
    if (window.confirm(t('block-editor.confirm.delete'))) {
      const _blockData = [...blockData];
      const block = _blockData.splice(blockPosition, 1)[0];
      onBlockDataUpdate(block.blockCategory)(_blockData);
    }
  };

  const handleCopyBlock = (block: BlockIn) => () => {
    onBlockDataUpdate(block.blockCategory)([...blockData, copyBlock(block)]);
  };

  const getBlockDetails = (block: BlockIn) => {
    switch (block.type) {
      case 'Question':
        return t(`block-editor.Question.questionType.${block.questionType}`);
      default:
        return null;
    }
  };

  return (
    <Container>
      <ul>
        {blockData.map((block, i) => (
          <li key={`${block.type}-${block.id}-${i}`}>
            <div>
              <IconButton size="small" onClick={handleMoveBlock(i, -1)}>
                <ArrowUpward />
              </IconButton>

              <IconButton size="small" onClick={handleMoveBlock(i, 1)}>
                <ArrowDownward />
              </IconButton>
            </div>

            <div className="block__main-column">
              <div className="block-title">
                {t(`block-editor.${block.type}.type`)}
                <span className="block-title__details">
                  {getBlockDetails(block)}
                </span>
              </div>
              <div className="block-preview">
                <span>{getBlockPreviewText(block)}</span>
              </div>
            </div>

            <div>
              <Modal
                title={t(`block-editor.${block.type}.edit`)}
                trigger={
                  <IconButton aria-label="delete" size="small">
                    <EditIcon />
                  </IconButton>
                }
                content={getBlockForm(block.type, block).content}
              />

              <IconButton size="small" onClick={handleCopyBlock(block)}>
                <CopyIcon />
              </IconButton>

              <IconButton size="small" onClick={handleDeleteBlock(i)}>
                <DeleteIcon color="error" />
              </IconButton>
            </div>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default BlockList;
