import React from 'react';
import { Box, Tab, Tabs as MuiTabs } from '@material-ui/core';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface ITab {
  id: number | string;
  label: string;
  content: JSX.Element;
}

interface Props {
  value: any;
  onChange: (event: React.ChangeEvent<{}>, value: any) => void;
  tabs: ITab[];
}

const Tabs: React.FC<Props> = ({ value, onChange, tabs }) => {
  return (
    <Box mt={5} mb={5}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MuiTabs
          value={value}
          onChange={onChange}
          aria-label="basic MuiTabs example"
        >
          {tabs.map(({ id, label }, index) => (
            <Tab key={id} label={label} {...a11yProps(index)} />
          ))}
        </MuiTabs>
      </Box>
      {tabs.map(({ id, content }, index) => (
        <TabPanel key={id} value={value} index={index}>
          {content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default Tabs;
