import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockEditorProps, BlockType } from '..';
import { Button } from '../../FormControls';
import Input, { handleInputChange } from '../../Input';
import { BlockForm, handleSubmitBlockForm } from './BlockForm';

const BulletedTextBlock: React.FC<BlockEditorProps> = ({
  onBlockSave,
  editBlock,
}) => {
  const { t } = useTranslation();

  const type: BlockType = 'BulletedTextBlock';

  // @ts-ignore
  const [body, setBody] = useState(editBlock?.body ?? '');

  const disabled = editBlock && !editBlock.id;

  const handleSaveBlock = () => {
    const block = {
      type,
      body,
    };

    if (onBlockSave) onBlockSave(block, editBlock);
  };

  return (
    <BlockForm onSubmit={handleSubmitBlockForm(handleSaveBlock)}>
      <Input
        label={t(`block-editor.${type}.body`)}
        value={body}
        onChange={handleInputChange(setBody)}
        disabled={disabled}
        multiline
        minRows={5}
        autoFocus
      />

      <Button
        label={t(`block-editor.${type}.save`)}
        type="submit"
        disabled={disabled}
        fullWidth
      />
    </BlockForm>
  );
};

export default BulletedTextBlock;
