import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Box, Modal, Typography, Input } from '@material-ui/core';
import styled from 'styled-components';
import { RootStore, useStores } from '../../stores';
import { Company, User } from '../../stores/models';
import PlusIcon from '@material-ui/icons/Add';
import UserItem from '../../components/UserItem';
import { Button } from '../../components/FormControls';
import { isEmail } from '../../utils/validators';
import { ERROR } from '../../constants/constants';

const Container = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`;

const UserItems = styled.div`
  width: 100%;
  margin: 1em 0;
`;

const ModalContainer = styled(Box)`
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 5em auto;
  width: 500px;
  padding: 1em;
`;

const StyledInput = styled(Input)`
  margin: 1em 0;
`;

interface CompanyUsersFormProps {
  editCompany?: Company;
}

export const CompanyUsersForm: FC<CompanyUsersFormProps> = observer(
  ({ editCompany }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [fullName, setFullName] = useState('');
    const [password, setPassword] = useState('');

    const { userStore, companyStore, notificationStore } =
      useStores() as RootStore;

    const toggleInfo = () => {
      if (isOpen) setIsOpen(false);
      else setIsOpen(true);
    };

    const handleAddUser = async () => {
      if (isEmail(username)) {
        notificationStore.setError(t(ERROR.INVALID_USERNAME));
        return;
      } else {
        await userStore.addUser({
          name: fullName,
          username,
          password,
          companyId: editCompany?.id,
        });
        setModalOpen(false);
      }
    };

    const handleDeleteUser = (userId: number) => {
      userStore.deleteUser({ id: userId });
    };

    return (
      <Container onClick={toggleInfo}>
        <Typography variant="h3">{t('user.users')}</Typography>

        <UserItems>
          {companyStore
            .getSortedUsersByCompany(editCompany?.id)
            .map((user: User) => (
              <UserItem key={user.id} user={user} onDelete={handleDeleteUser} />
            ))}
        </UserItems>

        <Button
          label={t(`user.addUser`)}
          startIcon={<PlusIcon />}
          onClick={() => setModalOpen(true)}
          variant="text"
        />

        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalContainer>
            <StyledInput
              id="username"
              name="username"
              value={username}
              onChange={e => setUsername(e.currentTarget.value)}
              placeholder={t('user.email')}
            />
            <StyledInput
              id="name"
              name="name"
              value={fullName}
              onChange={e => setFullName(e.currentTarget.value)}
              placeholder={t('user.fullName')}
            />
            <StyledInput
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={e => setPassword(e.currentTarget.value)}
              placeholder={t('user.password')}
            />
            <Button
              label={t('user.addUser')}
              type="submit"
              onClick={() => handleAddUser()}
            />
          </ModalContainer>
        </Modal>
      </Container>
    );
  },
);

export default CompanyUsersForm;
