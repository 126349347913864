import CompanyIcon from '@material-ui/icons/Group';
import ProductIcon from '@material-ui/icons/Star';
import SettingsIcon from '@material-ui/icons/Settings';
import ContentIcon from '@material-ui/icons/Description';
import { CompaniesScreen } from '../screens/Companies/CompaniesScreen';
import { CompanyScreen } from '../screens/Companies/CompanyScreen';
import { LoginScreen } from '../screens/Login/LoginScreen';
import { PasswordResetRequestScreen } from '../screens/Login/PasswordResetRequestScreen';
import { PasswordResetScreen } from '../screens/Login/PasswordResetScreen';
import ProductScreen from '../screens/Products/ProductScreen';
import ProductsScreen from '../screens/Products/ProductsScreen';
import SettingsScreen from '../screens/Settings/SettingsScreen';
import AllContentScreen from '../screens/Content/AllContentScreen';
import ContentScreen from '../screens/Content/ContentScreen';
import ContentCategoryScreen from '../screens/Content/ContentCategoryScreen';
import { IRoute } from '../types';
import ProductCategoryScreen from '../screens/Products/ProductCategoryScreen';

const login: IRoute[] = [
  {
    name: 'login',
    href: '/login',
    component: LoginScreen,
    nonAuth: true,
  },
  {
    name: 'passwordResetRequest',
    href: '/resetpassword',
    component: PasswordResetRequestScreen,
    nonAuth: true,
  },
  {
    name: 'passwordReset',
    href: '/resetpassword/:id/:token',
    component: PasswordResetScreen,
    nonAuth: true,
  },
];

const settings: IRoute[] = [
  {
    name: 'settings',
    href: '/settings',
    component: SettingsScreen,
    iconComponent: SettingsIcon,
    menuItem: true,
  },
];

const companies: IRoute[] = [
  {
    name: 'companies',
    href: '/companies',
    component: CompaniesScreen,
    iconComponent: CompanyIcon,
    menuItem: true,
  },
  {
    name: 'company',
    href: '/companies/:id',
    component: CompanyScreen,
  },
  {
    name: 'addOffice',
    href: '/companies/add',
    component: CompanyScreen,
  },
];

const products: IRoute[] = [
  {
    name: 'products',
    href: '/products',
    component: ProductsScreen,
    iconComponent: ProductIcon,
    menuItem: true,
  },
  {
    name: 'product',
    href: '/products/:id',
    component: ProductScreen,
  },
  {
    name: 'addProduct',
    href: '/products/add/:categoryId',
    component: ProductScreen,
  },
];

const content: IRoute[] = [
  {
    name: 'content',
    href: '/content',
    component: AllContentScreen,
    iconComponent: ContentIcon,
    menuItem: true,
  },
  {
    name: 'content',
    href: '/content/:id',
    component: ContentScreen,
  },
  {
    name: 'addContent',
    href: '/content/add/:categoryId',
    component: ContentScreen,
  },
  {
    name: 'contentCategory',
    href: '/contentCategory/:categoryId',
    component: ContentCategoryScreen,
  },
  {
    name: 'addContentCategory',
    href: '/contentCategory/add',
    component: ContentCategoryScreen,
  },
  {
    name: 'productCategory',
    href: '/productCategory/:categoryId',
    component: ProductCategoryScreen,
  },
];

export const ROUTES: IRoute[] = [
  ...login,
  ...companies,
  ...products,
  ...content,
  ...settings,
];
