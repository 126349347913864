import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';

interface RadioButtonGroupProps {
  label?: string;
  groupName: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  disabled?: boolean;
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  label,
  groupName,
  value,
  onChange,
  disabled,
  children,
}) => {
  return (
    <FormControl component="fieldset" disabled={disabled}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup name={groupName} value={value} onChange={onChange} row>
        {children}
      </RadioGroup>
    </FormControl>
  );
};

interface Props {
  value: string;
  label: string;
}

export const RadioButton: React.FC<Props> = ({ value, label }) => {
  return <FormControlLabel value={value} control={<Radio />} label={label} />;
};

export default RadioButton;
