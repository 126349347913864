import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Header } from '.';
import { Button } from '../FormControls';
import SideMenu from './SideMenu';

const LayoutContainer = styled(Box)`
  display: flex;
  align-items: stretch;
`;

const ScreenContainer = styled(Box)`
  flex: 1;
`;

const PageTitleContainer = styled.div`
  padding: 20px 40px;
  min-height: 60px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${p => p.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.greyDarkest};

  > div {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
`;

const ContentContainer = styled(Box)`
  padding: 20px 40px;
`;

interface Props {
  pageTitle?: string;
  loading?: boolean;
  actions?: {
    label: string;
    icon: React.ReactNode;
    onClick: () => void;
  }[];
}

const Layout: FC<Props> = ({ pageTitle, loading, actions, children }) => {
  return (
    <LayoutContainer>
      <SideMenu />
      <ScreenContainer>
        <Header />

        {pageTitle && (
          <PageTitleContainer>
            <div>
              <Typography variant="h1">{pageTitle}</Typography>
              {loading && <CircularProgress />}
            </div>
            <div>
              {actions?.map(({ label, icon, onClick }) => (
                <Button
                  key={label}
                  label={label}
                  onClick={onClick}
                  startIcon={icon}
                  size="small"
                />
              ))}
            </div>
          </PageTitleContainer>
        )}

        <ContentContainer>{children}</ContentContainer>
      </ScreenContainer>
    </LayoutContainer>
  );
};

export default Layout;
