import { convertBodyCellsToNumeric, validateBodyCells } from './csvUtils';

const not = predicate => value => !predicate(value);

const isEmpty = cell =>
  cell === '' || cell === '#PUUTTUU!' || cell === null || cell === undefined;

export const headerRowRE = /^((19)|(20))\d{2}$/;
export const isHeaderRow = (row = []) => {
  return headerRowRE.test(row[1]);
};

export const pruneRows = (csvData = []) => {
  const nonEmptyRows = csvData.filter(row => row.some(not(isEmpty)));
  const headerRowIndex = nonEmptyRows.findIndex(row => isHeaderRow(row));
  return headerRowIndex >= 0
    ? nonEmptyRows.slice(headerRowIndex)
    : nonEmptyRows;
};

export const pruneColumns = (csvData = []) => {
  const headerColumn = csvData[0] || [];
  const skipColumnIndices = headerColumn.reduce(
    (acc, cell, index) => (isEmpty(cell) ? [...acc, index] : acc),
    [],
  );
  // Keep the first column in all cases
  const filteredSkipColumnIndices = skipColumnIndices.slice(1);
  const prunedRows = csvData.map(row => {
    return row.reduce((acc, cell, index) => {
      return filteredSkipColumnIndices.includes(index) ? acc : [...acc, cell];
    }, []);
  });
  return prunedRows;
};

export const pruneData = (csvData = []) => pruneColumns(pruneRows(csvData));

export const getRightmostValueFromBottomRow = (csvData = []) => {
  const lastRow = csvData[csvData.length - 1];
  const numericCells = lastRow.filter(cell => typeof cell === 'number');
  const lastNumericCell = numericCells[numericCells.length - 1];
  return lastNumericCell;
};

export const transformCsvData = (csvData = []) => {
  const prunedData = pruneData(csvData);
  const isValidCsv = validateBodyCells(prunedData);
  if (!isValidCsv) {
    throw new Error('Invalid CSV');
  }
  const convertedData = convertBodyCellsToNumeric(prunedData);
  const gaugeValue = getRightmostValueFromBottomRow(convertedData);
  return { data: convertedData, gaugeValue };
};
