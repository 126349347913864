import React, { useEffect, useState } from 'react';
import CopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { api } from '../services/api';
import { Button } from './FormControls';
import { IconButton } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStores } from '../stores';

type TargetType = 'product' | 'content';

const composeSharingLink = (
  targetType: TargetType,
  targetId: number,
  token: string,
  lang = 'fi',
) => {
  const frontEndDomain =
    process.env.REACT_APP_FRONTEND_URL ?? 'https://prix.forecon.fi';

  return `${frontEndDomain}/${lang}/sharing/${targetType}/${targetId}?token=${token}`;
};

const Container = styled.div`
  ul {
    list-style-type: none;
    padding: 0;
  }
`;

const LinkItem = styled.li`
  border: 1px solid ${p => p.theme.colors.grey};
  margin: 1rem 0;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.7rem;

  .expiry-date-picker-container {
    margin-top: 0.5rem;
    label {
      margin-right: 0.5rem;
    }
  }
`;

type FetchingState = 'NOT_FETCHED' | 'FETCHING' | 'FETCHED' | 'ERROR';

interface Props {
  targetType: TargetType;
  targetId: number;
  targetLang?: string;
}

const SharingLink: React.FC<Props> = observer(
  ({ targetType, targetId, targetLang }) => {
    const { t } = useTranslation();

    const { notificationStore } = useStores();

    const [links, setLinks] = useState<Api.Dto.SharingLink[]>([]);
    const [fetchingState, setFetchingState] =
      useState<FetchingState>('NOT_FETCHED');

    useEffect(() => {
      const fetchLinks = async () => {
        const response = await api.getSharingLink({
          targetType,
          targetId,
        });
        if (response.kind === 'ok') {
          setLinks(response.data);
          setFetchingState('FETCHED');
        } else {
          setFetchingState('ERROR');
        }
      };

      if (fetchingState === 'NOT_FETCHED') {
        fetchLinks();
      }
    }, [fetchingState, targetId, targetType]);

    const handleCreateLink = async () => {
      const response = await api.createSharingLink({
        targetType,
        targetId,
      });
      if (response.kind === 'ok') {
        setLinks([...links, response.data]);
        notificationStore.setSuccess(t('sharing_link.link_created'));
      }
    };

    const handleExpiryDateChange =
      (linkId: number) =>
      async (event: React.ChangeEvent<HTMLInputElement>) => {
        const response = await api.setSharingLinkExpiryDate({
          id: linkId,
          expiryDate: event.currentTarget.value,
        });
        if (response.kind === 'ok') {
          const updatedLink = response.data;
          setLinks(
            links.map(link => {
              if (link.id === updatedLink.id) return updatedLink;
              return link;
            }),
          );
          notificationStore.setSuccess(t('sharing_link.expiry_date_changed'));
        }
      };

    const handleCopyLink = (link: string) => {
      navigator.clipboard.writeText(link);
      notificationStore.setSuccess(t('sharing_link.link_copied'));
    };

    const handleDeleteLink = async (linkId: number) => {
      if (window.confirm(t('sharing_link.delete_confirm'))) {
        const response = await api.deleteSharingLink({ id: linkId });
        if (response.kind === 'ok') {
          setLinks(links.filter(({ id }) => id !== linkId));
          notificationStore.setSuccess(t('sharing_link.link_deleted'));
        }
      }
    };

    return (
      <Container>
        <h3>{t('sharing_link.title')}</h3>
        <ul>
          {links.map(({ id, token, expiryDate }) => {
            if (!id || !token?.length) return null;
            const link = composeSharingLink(
              targetType,
              targetId,
              token,
              targetLang,
            );
            return (
              <LinkItem key={token}>
                <div>
                  <a href={link} target="_blank" rel="noreferrer">
                    {link}
                  </a>
                  <div className="expiry-date-picker-container">
                    <label htmlFor="sharing-link-date-picker">
                      {t('sharing_link.expires')}
                    </label>
                    <input
                      type="datetime-local"
                      id={`sharing-link-${token}-date-picker`}
                      name="sharing-link-date-picker"
                      value={expiryDate?.slice(0, 16) ?? ''}
                      onChange={handleExpiryDateChange(id)}
                    />
                  </div>
                </div>
                <div>
                  <IconButton size="small" onClick={() => handleCopyLink(link)}>
                    <CopyIcon />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleDeleteLink(id)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </div>
              </LinkItem>
            );
          })}
        </ul>

        <Button
          label={t('sharing_link.create_link')}
          onClick={handleCreateLink}
          size="small"
          color="inherit"
        />
      </Container>
    );
  },
);

export default SharingLink;
