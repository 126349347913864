import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const Container = styled.div`
  margin: 10px 0;
  .MuiOutlinedInput-input {
    padding: 8px 40px 10px 14px;
  }
`;

export type SelectOptionValue = number | string;
type SelectOptionLabel = number | string;

export interface SelectOption {
  value: SelectOptionValue;
  label: SelectOptionLabel;
}

export const handleSelectChange =
  (setValue: React.Dispatch<React.SetStateAction<SelectOptionValue>>) =>
  (event: any, child: React.ReactNode) => {
    if (event?.target?.value) setValue(event.target.value);
  };

interface Props {
  id: string;
  label?: string;
  value: SelectOptionValue;
  options: SelectOption[];
  onChange: (event: any, child: React.ReactNode) => void;
  width?: number;
  fullWidth?: boolean;
}

const Select: React.FC<Props> = ({
  id,
  label,
  value,
  options,
  onChange,
  width,
  fullWidth,
}) => {
  return (
    <Container>
      <Typography variant="body2">{label}</Typography>
      <MuiSelect
        id={id}
        value={value}
        onChange={onChange}
        variant="outlined"
        fullWidth={fullWidth}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={`select-option-${value}`} value={value}>
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
    </Container>
  );
};

export default Select;
