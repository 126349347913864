import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';
import { MediaModel } from './MediaModel';
import { BlockModel } from './BlockModel';
import { TranslationModel } from './TranslationModel';
import { LanguageModel } from './LanguageModel';
import { AreaModel } from './AreaModel';
import { CategoryModel } from './ProductModel';

export const ContentModel = types.model({
  id: types.identifierNumber,
  languageId: types.maybeNull(types.number),
  language: types.maybeNull(LanguageModel),
  areaId: types.maybeNull(types.number),
  area: types.maybeNull(AreaModel),
  isDraft: types.maybeNull(types.boolean),
  isPublic: types.maybeNull(types.boolean),
  enableAnalytics: types.maybeNull(types.boolean),
  title: types.string,
  slug: types.maybeNull(types.maybe(types.string)),
  order: types.maybeNull(types.maybe(types.number)),
  category: types.maybeNull(CategoryModel),
  categoryId: types.maybe(types.number),
  backgroundImageId: types.maybeNull(types.number),
  backgroundImage: types.maybeNull(MediaModel),
  pdfViewId: types.maybeNull(types.number),
  pdfView: types.maybeNull(MediaModel),
  data: types.maybeNull(types.array(BlockModel)),
  data2: types.maybeNull(types.array(BlockModel)),
});

export interface IContentModel extends Instance<typeof ContentModel> {}
export interface Content extends SnapshotOut<typeof ContentModel> {}
export interface ContentIn extends SnapshotIn<typeof ContentModel> {}

export const ContentCategoryModel = types.model({
  id: types.identifierNumber,
  name: types.string,
  slug: types.maybeNull(types.maybe(types.string)),
  order: types.maybeNull(types.maybe(types.number)),
  allContent: types.maybeNull(types.array(ContentModel)),
  translations: types.maybeNull(types.array(TranslationModel)),
  isHidden: types.maybeNull(types.boolean),
});

export interface IContentCategoryModel
  extends Instance<typeof ContentCategoryModel> {}
export interface ContentCategory
  extends SnapshotOut<typeof ContentCategoryModel> {}
export interface ContentCategoryIn
  extends SnapshotIn<typeof ContentCategoryModel> {}
