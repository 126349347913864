import { ApiResponse } from 'apisauce';
import BaseApi from './BaseApi';
import { ApiConfig, DEFAULT_API_CONFIG } from './config';

export class ApiClient extends BaseApi {
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    super(config);
  }

  /**
   * LOGIN
   *
   * @param params
   */
  async login(params: Api.Req.Login): Promise<Api.Response<Api.Res.Login>> {
    const response: ApiResponse<any> = await this.api.post(
      '/auth/admin-login',
      params,
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    this.token = data.token;

    return { kind: 'ok', data };
  }

  /**
   * GET ME (Data for the user that's logged in)
   *
   * @param params
   */
  async getMe(params: Api.Req.GetMe): Promise<Api.Response<Api.Res.GetMe>> {
    const response: ApiResponse<any> = await this.api.get(
      '/users/me',
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * LOGOUT
   *
   * @param params
   */
  async logout(_: Api.Req.Logout): Promise<Api.Response<Api.Res.Logout>> {
    await this.api.post('/auth/logout', {}, this.auth());

    this.token = undefined;

    return { kind: 'ok', data: {} };
  }

  /**
   * GET LANGUAGES
   *
   * @param params
   */
  async getLanguages(): Promise<Api.Response<Api.Res.GetLanguages>> {
    const response: ApiResponse<any> = await this.api.get('/language', {});

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * GET USERS
   *
   * @param params
   */
  async getUsers(_: Api.Req.GetUsers): Promise<Api.Response<Api.Res.GetUsers>> {
    const response: ApiResponse<any> = await this.api.get(
      '/users',
      {},
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * ADD USER
   *
   * @param params
   */
  async addUser(user: Api.Req.AddUser): Promise<Api.Response<Api.Res.AddUser>> {
    const response: ApiResponse<any> = await this.api.post(
      '/users',
      user,
      this.auth(),
    );
    if (!response.ok) return this.handleError(response);

    return { kind: 'ok', data: response.data };
  }

  /**
   * DELETE USER
   *
   * @param params
   */
  async deleteUser({
    id,
  }: Api.Req.DeleteUser): Promise<Api.Response<Api.Res.DeleteUser>> {
    const response: ApiResponse<any> = await this.api.delete(
      `/users/${id}`,
      {},
      this.auth(),
    );
    if (!response.ok) return this.handleError(response);

    return { kind: 'ok', data: response.data.data };
  }

  /**
   * RESET PASSWORD REQUEST
   *
   * @param params
   */
  async passwordResetRequest(params: {
    username: string;
  }): Promise<Api.Response<any>> {
    const response: ApiResponse<any> = await this.api.post(
      `/auth/resetrequest`,
      params,
      this.auth(),
    );
    if (!response.ok) return this.handleError(response);

    return { kind: 'ok', data: response.data.data };
  }

  /**
   * RESET PASSWORD
   *
   * @param params
   */
  async passwordReset(
    params: Api.Req.PasswordReset,
  ): Promise<Api.Response<any>> {
    const response: ApiResponse<any> = await this.api.post(
      `/auth/resetpassword`,
      params,
      this.auth(),
    );
    if (!response.ok) return this.handleError(response);

    return { kind: 'ok', data: response.data.data };
  }

  /**
   * GET COMPANIES
   *
   * @param params
   */
  async getCompanies(
    _: Api.Req.GetCompanies,
  ): Promise<Api.Response<Api.Res.GetCompanies>> {
    const response: ApiResponse<any> = await this.api.get(
      '/companies',
      {},
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * ADD NEW COMPANY
   *
   * @param param
   */
  async addCompany({
    company,
  }: Api.Req.AddCompany): Promise<Api.Response<Api.Res.AddCompany>> {
    const response: ApiResponse<any> = await this.api.post(
      '/companies',
      company,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * EDIT EXISTING COMPANY
   *
   * @param param
   */
  async updateCompany({
    company,
  }: Api.Req.UpdateCompany): Promise<Api.Response<Api.Res.UpdateCompany>> {
    const response: ApiResponse<any> = await this.api.put(
      `/companies`,
      company,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * DELETE COMPANY
   *
   * @param param
   */
  async deleteCompany({
    id,
  }: Api.Req.DeleteCompany): Promise<Api.Response<Api.Res.DeleteCompany>> {
    const response: ApiResponse<any> = await this.api.delete(
      `/companies/${id}`,
      {},
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * Send email to all user's under given company
   *
   * @param param
   */
  async sendCompanyEmail(
    params: Api.Req.SendCompanyEmail,
  ): Promise<Api.Response<Api.Res.SendCompanyEmail>> {
    const response: ApiResponse<any> = await this.api.post(
      `/messaging/email/company`,
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * GET PRODUCTS
   *
   * @param params
   */
  async getProducts(
    _: Api.Req.GetProducts,
  ): Promise<Api.Response<Api.Res.GetProducts>> {
    const response: ApiResponse<any> = await this.api.get(
      '/products',
      {},
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * GET PRODUCT
   *
   * @param param
   */
  async getProduct({
    id,
  }: Api.Req.GetProduct): Promise<Api.Response<Api.Res.GetProduct>> {
    const response: ApiResponse<any> = await this.api.get(
      `/products/${id}`,
      {},
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * ADD NEW PRODUCT
   *
   * @param param
   */
  async addProduct({
    product,
  }: Api.Req.AddProduct): Promise<Api.Response<Api.Res.AddProduct>> {
    const response: ApiResponse<any> = await this.api.post(
      '/products',
      product,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * EDIT EXISTING PRODUCT
   *
   * @param param
   */
  async updateProduct({
    product,
  }: Api.Req.UpdateProduct): Promise<Api.Response<Api.Res.UpdateProduct>> {
    const response: ApiResponse<any> = await this.api.put(
      `/products`,
      product,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * DELETE PRODUCT
   *
   * @param param
   */
  async deleteProduct({
    id,
  }: Api.Req.DeleteProduct): Promise<Api.Response<Api.Res.DeleteProduct>> {
    const response: ApiResponse<any> = await this.api.delete(
      `/products/${id}`,
      {},
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * UPLOAD FILE
   *
   * @param param
   */
  async uploadFile({
    file,
  }: Api.Req.UploadFile): Promise<Api.Response<Api.Res.UploadFile>> {
    const formData = new FormData();
    formData.append('file', file);

    const defaultConfig = this.auth();

    const config = {
      ...defaultConfig,
      headers: {
        ...defaultConfig.headers,
        'content-type': 'multipart/form-data',
      },
    };

    const response: ApiResponse<any> = await this.api.post(
      `/media`,
      formData,
      config,
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /*
   * GET CONTENT
   *
   * @param params
   */
  async getContent(
    _: Api.Req.GetContent,
  ): Promise<Api.Response<Api.Res.GetContent>> {
    const response: ApiResponse<any> = await this.api.get(
      '/content',
      {},
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * GET ONE CONTENT PAGE
   *
   * @param param
   */
  async getContentPage({
    id,
  }: Api.Req.GetContentPage): Promise<Api.Response<Api.Res.GetContentPage>> {
    const response: ApiResponse<any> = await this.api.get(
      `/content/${id}`,
      {},
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * ADD NEW CONTENT
   *
   * @param param
   */
  async addContent({
    content,
  }: Api.Req.AddContent): Promise<Api.Response<Api.Res.AddContent>> {
    const response: ApiResponse<any> = await this.api.post(
      '/content',
      content,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * EDIT EXISTING CONTENT
   *
   * @param param
   */
  async updateContent({
    content,
  }: Api.Req.UpdateContent): Promise<Api.Response<Api.Res.UpdateContent>> {
    const response: ApiResponse<any> = await this.api.put(
      `/content`,
      content,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * DELETE CONTENT
   *
   * @param param
   */
  async deleteContent({
    id,
  }: Api.Req.DeleteContent): Promise<Api.Response<Api.Res.DeleteContent>> {
    const response: ApiResponse<any> = await this.api.delete(
      `/content/${id}`,
      {},
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * Change page order
   *
   * @param param
   */
  async changePageOrder({
    id,
    direction,
  }: Api.Req.ChangePageOrder): Promise<Api.Response<Api.Res.ChangePageOrder>> {
    const url =
      direction === 'up' ? `/content/${id}/moveUp` : `/content/${id}/moveDown`;
    const response: ApiResponse<any> = await this.api.post(
      url,
      {},
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * ADD NEW CONTENT CATEGORY
   *
   * @param param
   */
  async addContentCategory(
    content: Api.Req.AddContentCategory,
  ): Promise<Api.Response<Api.Res.AddContentCategory>> {
    const response: ApiResponse<any> = await this.api.post(
      '/contentCategory',
      content,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * EDIT EXISTING CONTENT CATEGORY
   *
   * @param param
   */
  async updateContentCategory(
    category: Api.Req.UpdateContentCategory,
  ): Promise<Api.Response<Api.Res.UpdateContentCategory>> {
    const response: ApiResponse<any> = await this.api.patch(
      `/contentCategory/${category.id}`,
      category,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * DELETE CONTENT
   *
   * @param param
   */
  async deleteContentCategory({
    id,
  }: Api.Req.DeleteContentCategory): Promise<
    Api.Response<Api.Res.DeleteContentCategory>
  > {
    const response: ApiResponse<any> = await this.api.delete(
      `/contentCategory/${id}`,
      {},
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * Change category order
   *
   * @param param
   */
  async changeContentCategoryOrder({
    id,
    direction,
  }: Api.Req.ChangeCategoryOrder): Promise<
    Api.Response<Api.Res.ChangeCategoryOrder>
  > {
    const url =
      direction === 'up'
        ? `/contentCategory/${id}/moveUp`
        : `/contentCategory/${id}/moveDown`;
    const response: ApiResponse<any> = await this.api.post(
      url,
      {},
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * EDIT EXISTING PRODUCT CATEGORY
   *
   * @param param
   */
  async updateProductCategory(
    category: Api.Req.UpdateProductCategory,
  ): Promise<Api.Response<Api.Res.UpdateProductCategory>> {
    const response: ApiResponse<any> = await this.api.patch(
      `/productCategory/${category.id}`,
      category,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * Get sharing link(s)
   *
   * @param param
   */
  async getSharingLink(
    params: Api.Req.GetSharingLink,
  ): Promise<Api.Response<Api.Res.GetSharingLink>> {
    const url = 'sharing/sharing-link';
    const response: ApiResponse<any> = await this.api.get(
      url,
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);
    const { data } = response;
    return { kind: 'ok', data };
  }

  /**
   * Create sharing link
   *
   * @param param
   */
  async createSharingLink(
    params: Api.Req.CreateSharingLink,
  ): Promise<Api.Response<Api.Res.CreateSharingLink>> {
    const url = 'sharing/sharing-link';
    const response: ApiResponse<any> = await this.api.post(
      url,
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);
    const { data } = response;
    return { kind: 'ok', data };
  }

  /**
   * Set sharing link's expiry date
   *
   * @param param
   */
  async setSharingLinkExpiryDate({
    id,
    ...params
  }: Api.Req.SetSharingLinkExpiryDate): Promise<
    Api.Response<Api.Res.SetSharingLinkExpiryDate>
  > {
    const url = `sharing/sharing-link/${id}/expiry-date`;
    const response: ApiResponse<any> = await this.api.post(
      url,
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);
    const { data } = response;
    return { kind: 'ok', data };
  }

  /**
   * Delete sharing link
   *
   * @param param
   */
  async deleteSharingLink({
    id,
    ...params
  }: Api.Req.DeleteSharingLink): Promise<
    Api.Response<Api.Res.DeleteSharingLink>
  > {
    const url = `sharing/sharing-link/${id}`;
    const response: ApiResponse<any> = await this.api.delete(
      url,
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);
    const { data } = response;
    return { kind: 'ok', data };
  }

  /**
   * Get all areas
   *
   * @param param
   */
  async getAreas(
    params: Api.Req.GetAreas,
  ): Promise<Api.Response<Api.Res.GetAreas>> {
    const url = 'area';
    const response: ApiResponse<any> = await this.api.get(
      url,
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);
    const { data } = response;
    return { kind: 'ok', data };
  }

  /**
   * Get questionnaire emails
   *
   */
  async getQuestionnaireEmails(params: Api.Req.GetQuestionnaireEmails) {
    const url = 'questionnaire-email';
    const response = await this.api.get(url, params, this.auth());
    return this.handleResponse(response);
  }

  /**
   * Create questionnaire email
   *
   */
  async createQuestionnaireEmail({
    id,
    ...params
  }: Api.Req.CreateQuestionnaireEmail) {
    const url = 'questionnaire-email';
    const response = await this.api.post(url, params, this.auth());
    return this.handleResponse(response);
  }

  /**
   * Edit questionnaire email
   *
   */
  async editQuestionnaireEmail(params: Api.Req.EditQuestionnaireEmail) {
    const url = 'questionnaire-email';
    const response = await this.api.put(url, params, this.auth());
    return this.handleResponse(response);
  }

  /**
   * Delete questionnaire email
   *
   */
  async deleteQuestionnaireEmail({ id }: Api.Req.DeleteQuestionnaireEmail) {
    const url = `questionnaire-email/${id}`;
    const response = await this.api.delete(url, {}, this.auth());
    return this.handleResponse(response);
  }

  /**
   * Send questionnaire email
   *
   */
  async sendQuestionnaireEmail({ id }: Api.Req.SendQuestionnaireEmail) {
    const url = `questionnaire-email/send/${id}`;
    const response = await this.api.post(url, {}, this.auth());
    return this.handleResponse(response);
  }
}

export const api = new ApiClient();

export default api;
