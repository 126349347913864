import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { styled } from '../../styles';
import { IRoute } from '../../types';

interface SideMenuItemProps {
  route: IRoute;
  onClick: (href: IRoute['href']) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    color: '#ffffff',
  },
  content: {
    padding: '12px',
  },
  group: {
    margin: 0,
  },
}));

const SideMenuContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: #22304e;
  width: 350px;
  min-height: 100vh;
`;

const LogoContainer = styled(Box)`
  margin: 2em 1em 1em 2em;
`;

const IconContainer = styled(Box)`
  justify-content: center;
  align-items: center;
  display: flex;
  paddingt: 1.5rem;
  color: ${props => props.theme.colors.white};
`;

const SideMenuList = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const StyledLi = styled.li`
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primary};
  padding: 1em 2em;

  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
`;

const ListItemContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SideMenuItem: FC<SideMenuItemProps> = ({ route, onClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onListItemClick = () => {
    onClick(route.href);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <StyledLi onClick={onListItemClick}>
      <ListItemContainer>
        <Typography variant="button" className={classes.root}>
          {t(`screens.${route.name}`)}
        </Typography>
        {route.iconComponent ? (
          <IconContainer>
            <route.iconComponent />
          </IconContainer>
        ) : null}
      </ListItemContainer>
    </StyledLi>
  );
};

export interface SideMenuItemOptions {
  enabled?: boolean | undefined;
}

export interface SideMenuProps {}

export const SideMenu: FC<SideMenuProps> = () => {
  let history = useHistory();
  const [subMenuExpanded, setSubMenuExpanded] = useState<string[]>([]);

  const onNavItemClick = (href: IRoute['href']) => {
    history.push(href, { subMenuExpanded: subMenuExpanded });
  };

  const pathname = history.location.pathname;
  useEffect(() => {
    const currentRoute = ROUTES.find(route => route.href === pathname);
    const currentSubMenu = currentRoute?.subMenu ? [currentRoute.subMenu] : [];
    setSubMenuExpanded(currentSubMenu);
  }, [pathname]);

  return (
    <SideMenuContainer>
      <LogoContainer>
        <img
          style={{ width: '150px' }}
          src="/Forecon-logo-txt-white.svg"
          alt="Forecon logo"
        />
      </LogoContainer>
      <SideMenuList>
        {ROUTES.filter(route => route.menuItem).map(
          route =>
            route && (
              <SideMenuItem
                key={route.href}
                route={route}
                onClick={onNavItemClick}
              />
            ),
        )}
      </SideMenuList>
    </SideMenuContainer>
  );
};

export default SideMenu;
