import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../stores';
import { getAreaFilterOptions } from '../utils/area';
import Select, { SelectOptionValue } from './Select';
import STORAGE from '../utils/storage';
import { observer } from 'mobx-react-lite';

interface Props {
  value: SelectOptionValue;
  setValue: React.Dispatch<React.SetStateAction<SelectOptionValue>>;
}

const AreaFilter: React.FC<Props> = observer(({ value, setValue }) => {
  const { t } = useTranslation();

  const {
    areaStore: { areas, state: areasState, getAreas },
  } = useStores();

  useEffect(() => {
    if (areasState === 'NOT_FETCHED') getAreas();
  }, [areasState, getAreas]);

  const handleChange = (event: any, child: React.ReactNode) => {
    const area = event?.target?.value;
    if (area) {
      setValue(area);
      STORAGE.write({ key: 'AREA', value: area });
    }
  };

  return (
    <div>
      {!!areas.length && (
        <Select
          id="area-filter-select"
          label={t('areas.title')}
          value={value ?? ''}
          options={getAreaFilterOptions(areas)}
          onChange={handleChange}
          width={150}
        />
      )}
    </div>
  );
});

export default AreaFilter;
