import { Button, CircularProgress } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '../../styles/styledTheme';

interface SubmitButtonProps {
  loading: boolean;
  label: string;
  paddingTop?: string;
  disabled?: boolean;
  style?: any;
}

const ButtonContainer = styled.div<{ paddingTop?: string }>`
  width: 100%;
  ${props => (props.paddingTop ? `padding-top: ${props.paddingTop};` : '')}
  text-align: right;
`;

export const SubmitButton: FC<SubmitButtonProps> = ({
  label,
  loading,
  disabled,
  paddingTop,
  style,
}) => {
  const { t } = useTranslation();

  const styleOverride = style ? style : { borderRadius: '50px' };

  return (
    <ButtonContainer paddingTop={paddingTop}>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <Button
          style={styleOverride}
          size="large"
          type="submit"
          variant="contained"
          color="primary"
          disabled={disabled}
          disableElevation
        >
          {t(label)}
        </Button>
      )}
    </ButtonContainer>
  );
};
