import _ from 'lodash';
import {
  Instance,
  types,
  flow,
  cast,
  getSnapshot,
  applySnapshot,
  getParent,
} from 'mobx-state-tree';
import { ERROR } from '../constants/constants';
import i18n from '../i18n';
import { api } from '../services/api/ApiClient';
import { getMaxOrder } from '../utils/order';
import { sortByOrder } from '../utils/sort';
import {
  Content,
  ContentCategory,
  ContentCategoryModel,
  ContentModel,
} from './models';

const States = [
  'NOT_FETCHED' as const,
  'FETCHING' as const,
  'FETCHED' as const,
  'ERROR' as const,
  'SAVING' as const,
  'SAVED' as const,
];

const ContentPageStates = [
  'IDLE' as const,
  'FETCHING' as const,
  'ERROR' as const,
];

export const ContentStore = types
  .model({
    state: types.enumeration('State', States),
    allPages: types.array(ContentModel),
    allCategories: types.array(ContentCategoryModel),

    contentPageState: types.enumeration('State', ContentPageStates),
    contentPage: types.maybe(ContentModel),
  })

  .views(self => ({
    get pages() {
      return [...getSnapshot(self.allPages)].sort(sortByOrder);
    },
    get categories() {
      return [...getSnapshot(self.allCategories)].sort(sortByOrder);
    },
    get page() {
      return self.contentPage ? getSnapshot(self.contentPage) : undefined;
    },
    getCategoryPages(categoryId: number) {
      return [...getSnapshot(self.allPages)]
        .filter(content => content.categoryId === categoryId)
        .sort(sortByOrder);
    },
    getPage(contentId: number | undefined) {
      if (contentId === undefined) return undefined;

      const content = self.allPages.find(({ id }) => id === contentId);
      return content ? getSnapshot(content) : undefined;
    },
  }))
  .actions(self => {
    let initialState = {};

    const getContent = flow(function* (_: Api.Req.GetContent) {
      const { notificationStore } = getParent(self);
      self.state = 'FETCHING';

      const response: Api.Response<Api.Res.GetContent> = yield api.getContent(
        {},
      );

      if (response.kind === 'ok') {
        self.allPages = cast(response.data.pages);
        self.allCategories = cast(response.data.categories);
        self.state = 'FETCHED';
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.state = 'ERROR';
      }
    });

    const getContentPage = flow(function* (params: Api.Req.GetContentPage) {
      const { notificationStore } = getParent(self);
      self.contentPageState = 'FETCHING';

      const response: Api.Response<Api.Res.GetContentPage> =
        yield api.getContentPage(params);

      if (response.kind === 'ok') {
        const contentPage = response.data;
        self.contentPage = cast(contentPage);
        self.contentPageState = 'IDLE';
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.contentPageState = 'ERROR';
      }
    });

    const addContent = flow(function* (params: any) {
      const { notificationStore } = getParent(self);

      self.state = 'SAVING';

      const response: Api.Response<Api.Res.AddContent> = yield api.addContent(
        params,
      );

      if (response.kind === 'ok') {
        const addedContent = response.data;
        const pages = [
          ...getSnapshot(self.allPages).filter(r => r.id !== addedContent.id),
          addedContent,
        ];
        self.allPages = cast(pages);
        self.contentPage = cast(addedContent);
        self.state = 'SAVED';
        notificationStore.setSuccess(i18n.t('successes.general.add'));
      } else {
        let errorMessage = ERROR.GENERAL_ERROR
        if (response.kind === 'conflict') {
          errorMessage = ERROR.SLUG_CONFLICT_ERROR
        }

        notificationStore.setError(i18n.t(errorMessage));
        self.state = 'ERROR';
      }
    });

    const updateContent = flow(function* (content: any) {
      const { notificationStore } = getParent(self);
      self.state = 'SAVING';
      const response: Api.Response<Api.Res.UpdateContent> =
        yield api.updateContent(content);

      if (response.kind === 'ok') {
        const updatedContent = response.data;
        const pages = [
          ...getSnapshot(self.allPages).map(page =>
            page.id === updatedContent.id ? updatedContent : page,
          ),
        ];
        self.allPages = cast(pages);
        self.contentPage = cast(updatedContent);
        self.state = 'SAVED';
        notificationStore.setSuccess(i18n.t('successes.general.edit'));
      } else {
        let errorMessage = ERROR.GENERAL_ERROR
        if (response.kind === 'conflict') {
          errorMessage = ERROR.SLUG_CONFLICT_ERROR
        }

        notificationStore.setError(i18n.t(errorMessage));
        self.state = 'ERROR';
      }
    });

    const deleteContent = flow(function* (params: Api.Req.DeleteContent) {
      const { notificationStore } = getParent(self);

      const response: Api.Response<Api.Res.DeleteContent> =
        yield api.deleteContent(params);

      if (response.kind === 'ok') {
        getContent({});
        notificationStore.setSuccess(i18n.t('successes.general.delete'));
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
      }
    });

    const addContentCategory = flow(function* (content: any) {
      const { notificationStore } = getParent(self);
      self.state = 'SAVING';

      const response: Api.Response<Api.Res.AddContentCategory> =
        yield api.addContentCategory(content);

      if (response.kind === 'ok') {
        const addedContentCategory = response.data;
        const categories = [
          ...getSnapshot(self.allCategories).filter(
            r => r.id !== addedContentCategory.id,
          ),
          addedContentCategory,
        ];
        self.allCategories = cast(categories);
        self.state = 'SAVED';
        notificationStore.setSuccess(i18n.t('successes.general.add'));
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.state = 'ERROR';
      }
    });

    const updateContentCategory = flow(function* (
      payload: Api.Req.UpdateContentCategory,
    ) {
      const { notificationStore } = getParent(self);
      self.state = 'SAVING';
      const response: Api.Response<Api.Res.UpdateContentCategory> =
        yield api.updateContentCategory(payload);

      if (response.kind === 'ok') {
        const updatedContentCategory = response.data;
        const categories = [
          ...getSnapshot(self.allCategories).map(category =>
            category.id === updatedContentCategory.id
              ? updatedContentCategory
              : category,
          ),
        ];
        self.allCategories = cast(categories);
        self.state = 'SAVED';
        notificationStore.setSuccess(i18n.t('successes.general.edit'));
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.state = 'ERROR';
      }
    });

    const deleteContentCategory = flow(function* (
      params: Api.Req.DeleteContentCategory,
    ) {
      const { notificationStore } = getParent(self);

      const response: Api.Response<Api.Res.DeleteContentCategory> =
        yield api.deleteContentCategory(params);

      if (response.kind === 'ok') {
        getContent({});
        notificationStore.setSuccess(i18n.t('successes.general.delete'));
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
      }
    });

    const updateItemsOrder = function <T>(
      itemId: number,
      direction: 'up' | 'down',
      items: ({ id: number; order?: number | null } & T)[],
    ) {
      const movedItem = items.find(({ id }) => id === itemId);

      if (movedItem && !_.isNil(movedItem.order)) {
        const step = direction === 'up' ? -1 : 1;
        const newOrder = movedItem.order + step ?? 0;

        const maxOrder = getMaxOrder(items);

        if (newOrder > maxOrder) return items;

        const updatedItems = items.map(item => {
          if (item.order === newOrder)
            return { ...item, order: movedItem.order };
          if (item.id === movedItem.id)
            return { ...movedItem, order: newOrder };
          return item;
        });

        return updatedItems;
      }
      return;
    };

    const changePageOrder = flow(function* (params: Api.Req.ChangePageOrder) {
      const { notificationStore } = getParent(self);

      const response: Api.Response<Api.Res.ChangePageOrder> =
        yield api.changePageOrder(params);

      if (response.kind === 'ok') {
        const page = getSnapshot(self.allPages).find(
          ({ id }) => id === params.id,
        );

        const updatedPages = updateItemsOrder<Content>(
          params.id,
          params.direction,
          self.getCategoryPages(page?.categoryId ?? -1),
        );

        if (updatedPages) {
          const updatedAllPages = getSnapshot(self.allPages).map(p => {
            const _page = updatedPages.find(({ id }) => id === p.id);
            if (_page) return _page;
            return p;
          });

          self.allPages = cast(updatedAllPages);
          notificationStore.setSuccess(i18n.t('successes.general.edit'));
        } else {
          return getContent({});
        }
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
      }
    });

    const changeCategoryOrder = flow(function* (
      params: Api.Req.ChangeCategoryOrder,
    ) {
      const { notificationStore } = getParent(self);

      const response: Api.Response<Api.Res.ChangeCategoryOrder> =
        yield api.changeContentCategoryOrder(params);

      if (response.kind === 'ok') {
        const updatedCategories = updateItemsOrder<ContentCategory>(
          params.id,
          params.direction,
          getSnapshot(self.allCategories),
        );

        if (updatedCategories) {
          self.allCategories = cast(updatedCategories);
          notificationStore.setSuccess(i18n.t('successes.general.edit'));
        } else {
          return getContent({});
        }
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
      }
    });

    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      reset: () => {
        applySnapshot(self, initialState);
      },
      getContent,
      getContentPage,
      addContent,
      updateContent,
      deleteContent,
      addContentCategory,
      updateContentCategory,
      deleteContentCategory,
      changePageOrder,
      changeCategoryOrder,
    };
  });

export interface IContentStore extends Instance<typeof ContentStore> {}

export default ContentStore;
