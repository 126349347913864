import { DateTime } from 'luxon';

type Quarter = 'I' | 'II' | 'III' | 'IV';

const getQuarterEndMonth = (quarter: Quarter) => {
  switch (quarter) {
    case 'I':
      return '03';
    case 'II':
      return '06';
    case 'III':
      return '09';
    case 'IV':
      return '12';
    default:
      return '12';
  }
};

/* Given date format should be either
 * 'YYYY', 'YYYY/MM', 'YYYY/MM/DD' (Date format)
 * or
 * 'YYYY/Qx' where x=I/II/III/IV (Quarter format)
 */
export const getDateOfChartDataRow = (rowName: string) => {
  const dateRegex = /^([\d]{4})(\/[\d]{2})?(\/[\d]{2})?$/g;
  const quarterRegex = /^[\d]{4}\/([I]{1,3}|IV)$/g;

  const trimmedRowName = rowName?.replaceAll(' ', '');

  if (trimmedRowName?.length) {
    const isDateFormat = dateRegex.test(trimmedRowName);
    const isQuarterFormat = quarterRegex.test(trimmedRowName);

    if (isDateFormat) {
      const dateString = trimmedRowName.replaceAll('/', '-');
      const dateParts = dateString.split('-');

      let endOf: 'day' | 'month' | 'year' = 'day';
      if (dateParts.length === 1) endOf = 'year';
      else if (dateParts.length === 2) endOf = 'month';

      return DateTime.fromISO(dateString).endOf(endOf);
    }

    if (isQuarterFormat) {
      const [year, quarter] = trimmedRowName.split('/');
      const month = getQuarterEndMonth(quarter as Quarter);
      return DateTime.fromISO(`${year}-${month}`).endOf('month');
    }
  }

  return undefined;
};
