import { Instance, SnapshotOut, onSnapshot, types } from 'mobx-state-tree';
import { UserStore } from './UserStore';
import { NotificationStoreModel } from './NotificationStore';
import STORAGE from '../utils/storage';
import CompanyStore from './CompanyStore';
import ProductStore from './ProductStore';
import ContentStore from './ContentStore';
import AreaStore from './AreaStore';
import QuestionnaireEmailStore from './QuestionnaireEmailStore';

// Set to true, if you want to save entire store's snapshot to the local/session storage
const SAVE_TO_STORAGE = false;

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model('RootStore').props({
  userStore: types.optional(UserStore, {
    state: 'LOGGED_OUT',
    usersState: 'NOT_FETCHED',
    languagesState: 'NOT_FETCHED',
  }),
  notificationStore: types.optional(NotificationStoreModel, {}),
  companyStore: types.optional(CompanyStore, {
    state: 'NOT_FETCHED',
    companies: [],
  }),
  productStore: types.optional(ProductStore, {
    state: 'NOT_FETCHED',
    productState: 'IDLE',
  }),
  contentStore: types.optional(ContentStore, {
    state: 'NOT_FETCHED',
    contentPageState: 'IDLE',
  }),
  areaStore: types.optional(AreaStore, {
    state: 'NOT_FETCHED',
  }),
  questionnaireEmailStore: types.optional(QuestionnaireEmailStore, {
    state: 'NOT_FETCHED',
    processState: 'IDLE',
  }),
});

export interface RootStore extends Instance<typeof RootStoreModel> {}

export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}

const storageState = SAVE_TO_STORAGE
  ? STORAGE.read({ key: 'ROOT_STATE' })
  : undefined;

// Get initial state from local storage (or initialize empty state)
export const rootStore = RootStoreModel.create(
  RootStoreModel.is(storageState) ? storageState : {},
);

// Update state in local storage on every snapshot
onSnapshot(rootStore, snapshot => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Snapshot:', snapshot);
  }
  if (SAVE_TO_STORAGE) {
    STORAGE.write({ key: 'ROOT_STATE', value: snapshot });
  }
});
