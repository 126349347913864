import { SelectOption } from '../components/Select';
import i18n from '../i18n';
import { Language } from '../stores/models/LanguageModel';
import { Translation } from '../stores/models/TranslationModel';

export const getLanguageSelectOptions = (
  languages: Language[],
): SelectOption[] => {
  const getLabel = (languageCode: string) =>
    i18n.t(`languages.${languageCode}`);

  return languages.map(({ id, name, code }: Language) => ({
    value: id,
    label: name ?? getLabel(code),
  }));
};

export const getLanguagesOfTranslations = (translations: Translation[]) => {
  return translations.map(({ language }) => language);
};

export const getLanguageCodes = (languages: Language[]) => {
  return languages.map(({ code }) => code);
};

export const getLanguageCodesFromTranslations = (
  translations?: Translation[] | null,
) => {
  if (!translations) return [];
  const languages = getLanguagesOfTranslations(translations);
  return getLanguageCodes(languages);
};
