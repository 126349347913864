import { Box, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Input, SubmitButton } from '../../components/FormControls';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../stores/RootStoreContext';
import { styled } from '../../styles';
import {
  composeValidators,
  isLogin,
  minLength,
  required,
} from '../../utils/validators';

interface LoginScreenProps {}

const FormContainer = styled(Box)`
  width: 500px;
  padding: 100px 150px;
  margin: auto;
`;

export const LoginScreen: FC<LoginScreenProps> = observer(() => {
  const history = useHistory();
  const { t } = useTranslation();
  const { userStore } = useStores() as RootStore;

  const onLoginSubmit = async (
    values: { username: string; password: string },
    b: any,
    callback: any,
  ) => {
    await userStore.login(values);
    if (userStore.state === 'LOGGED_IN') history.push('/companies');
  };

  const FIELDS = [
    {
      id: 'username',
      label: 'login.username',
      validate: composeValidators(required, isLogin),
    },
    {
      id: 'password',
      label: 'user.password',
      validate: composeValidators(required, minLength(1)),
    },
  ];

  return (
    <Form
      onSubmit={onLoginSubmit}
      subscription={{
        submitting: true,
        pristine: true,
        hasValidationErrors: true,
      }}
      render={({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormContainer>
              <Grid container spacing={8} justifyContent="center">
                <Grid item xs={12}>
                  <img
                    style={{ width: '100%' }}
                    src="/Forecon-logo-txt.svg"
                    alt="Forecon logo"
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h1">{t('login.admin')}</Typography>
                </Grid>
              </Grid>
              {FIELDS.map(({ id, label, validate }) => (
                <Grid container spacing={8} justifyContent="center" key={id}>
                  <Grid item xs={12}>
                    <Typography>{t(label)}</Typography>
                    <Input
                      validate={validate}
                      type={id === 'password' ? id : undefined}
                      id={id}
                      name={id}
                    />
                  </Grid>
                </Grid>
              ))}
              <Grid container spacing={8} justifyContent="center">
                <Grid item xs={12}>
                  <SubmitButton
                    label="login.login"
                    loading={submitting}
                    disabled={pristine || hasValidationErrors}
                    style={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8} justifyContent="center">
                <Grid item>
                  <Typography>
                    <Link to="/resetpassword">
                      {t('login.forgotPasswordLink')}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </FormContainer>
          </form>
        );
      }}
    />
  );
});
