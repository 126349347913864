import {
  Instance,
  types,
  flow,
  cast,
  getSnapshot,
  getParent,
  applySnapshot,
} from 'mobx-state-tree';
import { ERROR } from '../constants/constants';
import i18n from '../i18n';
import { api } from '../services/api/ApiClient';
import { sortByName } from '../utils/sort';
import { CompanyModel } from './models';

const States = [
  'NOT_FETCHED' as const,
  'FETCHING' as const,
  'FETCHED' as const,
  'ERROR' as const,
  'SAVING' as const,
  'SAVED' as const,
];

export const CompaniesStore = types
  .model({
    state: types.enumeration('State', States),
    companies: types.array(CompanyModel),
  })
  .views(self => ({
    get sortedCompanies() {
      return [...getSnapshot(self.companies)].sort(sortByName);
    },
    getSortedUsersByCompany(companyId?: number) {
      const company = self.companies.find(({ id }) => id === companyId);
      const companyUsers = company?.allUsers ?? [];
      return [...companyUsers].sort(sortByName);
    },
    getCompany(companyId: number | undefined) {
      if (companyId === undefined) return undefined;

      const company = self.companies.find(({ id }) => id === companyId);
      return company ? getSnapshot(company) : undefined;
    },
  }))
  .actions(self => {
    let initialState = {};

    const getCompanies = flow(function* (params: Api.Req.GetCompanies = {}) {
      const { notificationStore } = getParent(self);
      self.state = 'FETCHING';

      const response: Api.Response<Api.Res.GetCompanies> =
        yield api.getCompanies(params);

      if (response.kind === 'ok') {
        self.companies = cast(response.data);
        self.state = 'FETCHED';
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.state = 'ERROR';
      }
    });

    const addCompany = flow(function* (params: Api.Req.AddCompany) {
      const { notificationStore } = getParent(self);
      self.state = 'SAVING';

      const response: Api.Response<Api.Res.AddCompany> = yield api.addCompany(
        params,
      );

      if (response.kind === 'ok') {
        self.state = 'SAVED';
        self.companies = cast([response.data, ...self.companies]);
        notificationStore.setSuccess(i18n.t('successes.general.add'));
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.state = 'ERROR';
      }
    });

    const updateCompany = flow(function* (params: Api.Req.UpdateCompany) {
      const { notificationStore } = getParent(self);
      self.state = 'SAVING';
      const response: Api.Response<Api.Res.UpdateCompany> =
        yield api.updateCompany(params);

      if (response.kind === 'ok') {
        const updatedCompany = response.data;
        const companies = [
          updatedCompany,
          ...getSnapshot(self.companies).filter(
            r => r.id !== updatedCompany.id,
          ),
        ];
        self.companies = cast(companies);
        self.state = 'SAVED';
        notificationStore.setSuccess(i18n.t('successes.general.edit'));
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.state = 'ERROR';
      }
    });

    const deleteCompany = flow(function* (params: Api.Req.DeleteCompany) {
      const { notificationStore } = getParent(self);
      const response: Api.Response<Api.Res.DeleteCompany> =
        yield api.deleteCompany(params);

      if (response.kind === 'ok') {
        getCompanies({});
        notificationStore.setSuccess(i18n.t('successes.general.delete'));
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
      }
    });

    const sendCompanyEmail = flow(function* (params: Api.Req.SendCompanyEmail) {
      const { notificationStore } = getParent(self);
      const response: Api.Response<Api.Res.SendCompanyEmail> =
        yield api.sendCompanyEmail(params);

      if (response.kind === 'ok') {
        notificationStore.setSuccess(i18n.t('successes.general.messageSent'));
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
      }
    });

    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      reset: () => {
        applySnapshot(self, initialState);
      },
      getCompanies,
      addCompany,
      updateCompany,
      deleteCompany,
      sendCompanyEmail,
    };
  });

export interface ICompaniesStore extends Instance<typeof CompaniesStore> {}

export default CompaniesStore;
