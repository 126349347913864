import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockEditorProps, BlockType } from '..';
import { Button } from '../../FormControls';
import Input, { handleInputChange } from '../../Input';
import { BlockForm, handleSubmitBlockForm } from './BlockForm';
import { localizedDate } from '../../../utils/date';
import styled from 'styled-components';

const DatePickerContainer = styled.div`
  margin: 1rem 0;
  label,
  input {
    display: block;
  }
`;

const Link: React.FC<BlockEditorProps> = ({ onBlockSave, editBlock }) => {
  const { t } = useTranslation();

  const type: BlockType = 'Link';

  const [text, setText] = useState(editBlock?.text ?? '');
  const [href, setHref] = useState(editBlock?.href ?? '');
  const [publishedAt, setPublishedAt] = useState(
    editBlock?.publishedDateTime
      ? localizedDate(editBlock?.publishedDateTime).toISO()
      : '',
  );

  const disabled = editBlock && !editBlock.id;

  const handleSaveBlock = () => {
    const block = {
      type,
      text,
      href,
      publishedDateTime: publishedAt || undefined,
    };

    if (onBlockSave) onBlockSave(block, editBlock);
  };

  return (
    <BlockForm onSubmit={handleSubmitBlockForm(handleSaveBlock)}>
      <DatePickerContainer>
        <label htmlFor="link-block__date-picker">
          {t('block-editor.Link.publishedAt')}
        </label>
        <input
          type="date"
          id="link-block__date-picker"
          name="link-block__date-picker"
          value={publishedAt?.slice(0, 10) ?? ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPublishedAt(event.currentTarget.value);
          }}
        />
      </DatePickerContainer>

      <Input
        label={t(`block-editor.${type}.text`)}
        value={text}
        onChange={handleInputChange(setText)}
        disabled={disabled}
        autoFocus
      />

      <Input
        label={t(`block-editor.${type}.href`)}
        value={href}
        onChange={handleInputChange(setHref)}
        disabled={disabled}
      />

      <Button
        label={t(`block-editor.${type}.save`)}
        type="submit"
        disabled={disabled}
        fullWidth
      />
    </BlockForm>
  );
};

export default Link;
