import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import PlusIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { useStores, RootStore } from '../../stores';
import Layout from '../../components/Layout/Layout';
import TreeView, { Tree } from '../../components/TreeView';
import { getLanguageCodesFromTranslations } from '../../utils/language';
import AreaFilter from '../../components/AreaFilter';
import { SelectOptionValue } from '../../components/Select';
import { Category, Product } from '../../stores/models';
import TreeItemName from '../../components/TreeItemName';
import STORAGE from '../../utils/storage';

export const ProductsScreen: FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();

  const [areaFilter, setAreaFilter] = useState<SelectOptionValue>(
    STORAGE.read({ key: 'AREA' }) ?? 'all',
  );

  const {
    productStore: {
      state,
      allCategories,
      getProducts,
      getCategoryProducts,
      deleteProduct,
    },
  } = useStores() as RootStore;

  const isBusy = state === 'FETCHING';

  useEffect(() => {
    if (state === 'NOT_FETCHED') {
      getProducts({});
    }
  }, [allCategories, getProducts, state]);

  const editCategory = (id: number) => {
    history.push(`/productCategory/${id}`);
  };

  const addProduct = (categoryId: number) => {
    history.push(`/products/add/${categoryId}`);
  };

  const editProduct = (id: number) => {
    history.push(`/products/${id}`);
  };

  const handleDeleteProduct = (id: number) => {
    if (window.confirm(t('products.deleteProductConfirmation'))) {
      deleteProduct({ id });
    }
  };

  const filterProduct = (product: Product) => {
    return areaFilter === 'all' || product.area?.code === areaFilter;
  };

  const composeCategoryName = (category: Category) => {
    const productCount = getCategoryProducts(category.id).filter(
      filterProduct,
    ).length;
    return `${category.name} (${productCount})`;
  };

  const composeProductName = (product: Product) => {
    const areaText =
      areaFilter === 'all' ? ` [${t(`areas.${product.area?.code}`)}]` : '';
    return `${product.name}${areaText}`;
  };

  const composeTreeItems = (): Tree => {
    return (allCategories ?? []).map(category => ({
      id: category.id,
      name: (
        <TreeItemName
          label={composeCategoryName(category)}
          badges={getLanguageCodesFromTranslations(category.translations)}
        />
      ),
      onClick: () => null,
      actions: [
        {
          label: t('common.edit'),
          icon: <EditIcon />,
          onClick: (categoryId: number) => editCategory(categoryId),
        },
      ],
      actionButtons: [
        {
          label: t(`products.addNew`),
          icon: <PlusIcon />,
          onClick: (categoryId: number) => addProduct(categoryId),
        },
      ],
      subItems: (getCategoryProducts(category.id) ?? [])
        .filter(filterProduct)
        .map(product => ({
          id: product.id,
          name: (
            <TreeItemName
              label={composeProductName(product)}
              badges={product.language ? [product.language?.code] : []}
              isHidden={product.isDraft ?? false}
            />
          ),
          onClick: (productId: number) => editProduct(productId),
          actions: [
            {
              label: t('common.edit'),
              icon: <EditIcon />,
              onClick: (productId: number) => editProduct(productId),
            },
            {
              label: t('common.delete'),
              icon: <DeleteIcon color="error" />,
              onClick: (productId: number) => handleDeleteProduct(productId),
            },
          ],
        })),
    }));
  };

  return (
    <Layout pageTitle={t(`screens.products`)} loading={isBusy}>
      <AreaFilter value={areaFilter} setValue={setAreaFilter} />

      {!isBusy && <TreeView tree={composeTreeItems()} />}
    </Layout>
  );
});

export default ProductsScreen;
