import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Papa from 'papaparse';
import { BlockEditorProps, BlockType } from '..';
import Checkbox from '../../Checkbox';
import FileUpload from '../../FileUpload';
import { Button } from '../../FormControls';
import Input, { handleInputChange } from '../../Input';
import { BlockForm, handleSubmitBlockForm } from './BlockForm';
import { useStores } from '../../../stores';
import { transformCsvData } from '../utils/dataTableCsvTransforms';

const DataTable: React.FC<BlockEditorProps> = observer(
  ({ onBlockSave, editBlock }) => {
    const { t } = useTranslation();

    const { notificationStore } = useStores();

    const type: BlockType = 'DataTable';

    // @ts-ignore
    const [title, setTitle] = useState(editBlock?.title ?? '');
    // @ts-ignore
    const [showGauge, setShowGauge] = useState(editBlock?.showGauge ?? false);
    // @ts-ignore
    const [gaugeValue, setGaugeValue] = useState(editBlock?.gaugeValue);
    // @ts-ignore
    const [filename, setFilename] = useState(editBlock?.filename ?? '');

    const [csvDataType, setCsvDataType] = useState<string>(
      editBlock?.csvDataType ?? 'percentages',
    );

    const [csvData, setCsvData] = useState<(string | number | null)[]>(
      // @ts-ignore
      editBlock?.csvData,
    );

    const disabled = editBlock && !editBlock.id;

    const handleSaveBlock = () => {
      const block = {
        type,
        title,
        showGauge,
        gaugeValue,
        filename,
        csvData,
        csvDataType,
      };

      if (onBlockSave) onBlockSave(block, editBlock);
    };

    const handleCsvDataDrop = (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        Papa.parse(acceptedFiles[0], {
          complete: ({ data: csvData }: { data: any }) => {
            try {
              const { data: transformedData, gaugeValue } =
                transformCsvData(csvData);
              setGaugeValue(gaugeValue);
              setCsvData(transformedData);
              setFilename(acceptedFiles[0].name);
            } catch (err) {
              notificationStore.setError(t('block-editor.invalid-csv-content'));
            }
          },
          error: (error, file) => {
            notificationStore.setError(t('block-editor.error-parsing-csv'));
            console.log(t('block-editor.error-parsing-csv'), error);
          },
        });
      } else {
        notificationStore.setError(t('block-editor.error-file-not-accepted'));
      }
    };

    return (
      <BlockForm onSubmit={handleSubmitBlockForm(handleSaveBlock)}>
        <Input
          label={t(`block-editor.${type}.title`)}
          value={title}
          onChange={handleInputChange(setTitle)}
          disabled={disabled}
          autoFocus
        />
        <Checkbox
          checked={showGauge}
          onChange={e => setShowGauge(e.target.checked)}
          label={t(`block-editor.${type}.showGauge`)}
          disabled={disabled}
        />

        <Checkbox
          checked={csvDataType === 'percentages' ? true : false}
          onChange={e =>
            setCsvDataType(e.target.checked ? 'percentages' : 'numbers')
          }
          label={t(`block-editor.${type}.usePercentages`)}
          disabled={disabled}
        />

        <FileUpload
          label={t(`block-editor.file-input-label`)}
          // acceptedFiles="text/csv"
          customOnDrop={handleCsvDataDrop}
          filename={filename}
          disabled={disabled}
        />
        <Button
          label={t(`block-editor.${type}.save`)}
          type="submit"
          disabled={disabled}
          fullWidth
        />
      </BlockForm>
    );
  },
);

export default DataTable;
