import React, { FC, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import { RootStore, useStores } from '../../stores';
import { styled } from '../../styles';
import AddAdminForm from './AddAdminForm';
import AdminList from './AdminList';
import Accordion from '../../components/Accordion';
import SendEmailForm from './SendEmailForm';
import QuestionnaireEmails from './QuestionnaireEmails';

interface SettingsScreenProps {}

const SettingsContent = styled(Box)`
  display: flex;
  margin: 1em 0;
`;

export const SettingsScreen: FC<SettingsScreenProps> = observer(() => {
  const { t } = useTranslation();

  const {
    userStore: { usersState, getUsers },
  } = useStores() as RootStore;

  useEffect(() => {
    if (usersState === 'NOT_FETCHED') {
      getUsers({});
    }
  }, [getUsers, usersState]);

  const manageAdminUsersSection = {
    id: 'manage-admin-users',
    title: t('settings.admins.title'),
    content: (
      <>
        <SettingsContent>
          <AddAdminForm />
          <AdminList />
        </SettingsContent>
      </>
    ),
  };

  const sendEmailsSection = {
    id: 'send-emails',
    title: t('settings.communications.title'),
    content: <SendEmailForm />,
  };

  const questionnaireEmailsSection = {
    id: 'questionnaire-emails',
    title: t('settings.questionnaireEmails.title'),
    content: <QuestionnaireEmails />,
  };

  const accordionSections = [
    manageAdminUsersSection,
    sendEmailsSection,
    questionnaireEmailsSection,
  ];

  return (
    <Layout pageTitle={t('screens.settings')}>
      <Accordion sections={accordionSections} />
    </Layout>
  );
});

export default SettingsScreen;
