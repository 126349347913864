import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStores } from '../stores';
import { Language } from '../stores/models/LanguageModel';
import { TranslationIn } from '../stores/models/TranslationModel';
import Input from './Input';

const Container = styled.div`
  margin: 1em 0 1em 2em;
  padding: 1em 0 0.5em 1em;
  border-left: 1px solid ${p => p.theme.colors.grey};

  ul {
    list-style-type: none;
    padding: 0;
  }
`;

interface Props {
  categoryId: number;
  translations: TranslationIn[];
  setTranslations: React.Dispatch<React.SetStateAction<TranslationIn[]>>;
}

const TranslationEditor: React.FC<Props> = observer(
  ({ categoryId, translations, setTranslations }) => {
    const { t } = useTranslation();

    const {
      userStore: { getLanguages, languagesState, availableLanguages },
    } = useStores();

    useEffect(() => {
      if (languagesState === 'NOT_FETCHED') getLanguages();
    }, [getLanguages, languagesState]);

    const getTranslation = (langCode: string) => {
      return translations.find(({ language }) => language.code === langCode);
    };

    const existingTranslations = useMemo(
      () => translations.map(({ language }) => language.code),
      [translations],
    );

    const handleTranslationChange =
      (language: Language) =>
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (existingTranslations.includes(language.code)) {
          // Editing existing translation
          setTranslations(
            translations.map(translation => {
              if (translation.language.code !== language.code)
                return translation;
              return {
                ...translation,
                name: e.currentTarget.value,
              };
            }),
          );
        } else {
          // Translation didn't exist before
          const newTranslation: TranslationIn = {
            language,
            languageId: language.id,
            categoryId,
            name: e.currentTarget.value,
            id: null,
          };
          setTranslations([...translations, newTranslation]);
        }
      };

    return (
      <Container>
        <Typography variant="h3">{t('common.translations')}</Typography>

        <ul>
          {availableLanguages.map(language => {
            const translation = getTranslation(language.code);
            return (
              <li key={`translation-${language.code}`}>
                <Input
                  label={language.name}
                  value={translation?.name ?? ''}
                  onChange={handleTranslationChange(language)}
                />
              </li>
            );
          })}
        </ul>
      </Container>
    );
  },
);

export default TranslationEditor;
