import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';
import { PersonDtoModel } from './PersonModel';

export const QuestionnaireEmailModel = types.model({
  id: types.identifierNumber,
  subject: types.string,
  message: types.string,
  link: types.maybeNull(types.string),
  lastSent: types.maybeNull(types.string), // ISO date
  recipients: types.array(PersonDtoModel),
});

export interface IQuestionnaireEmailModel
  extends Instance<typeof QuestionnaireEmailModel> {}
export interface QuestionnaireEmail
  extends SnapshotOut<typeof QuestionnaireEmailModel> {}
export interface QuestionnaireEmailIn
  extends SnapshotIn<typeof QuestionnaireEmailModel> {}
