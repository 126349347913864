export enum ERROR {
  LOGIN_ERROR = 'errors.loginError',
  GENERAL_ERROR = 'errors.generalError',
  FETCH_USER_ERROR = 'errors.fetchUserError',
  CHANGE_PASSWORD_ERROR = 'errors.changePasswordError',
  STATISTICS_SEND_ERROR = 'errors.statisticsSentError',
  INVALID_USERNAME = 'errors.validation.email',
  SLUG_CONFLICT_ERROR = 'errors.slugConflictError',
}

export enum SUCCESS {
  EDIT_SUCCESS = 'successes.general.edit',
  DELETE_SUCCESS = 'successes.general.delete',
  SEND_SUCCESS = 'successes.general.send',
  CHANGE_PASSWORD_SUCCESS = 'successes.passwordChanged',
  RESET_PASSWORD_SUCCESS = 'successes.linkSent',
  STATISTICS_SEND_SUCCESS = 'successes.statisticsSent',
}

export const LOCALE = 'locale';

export const LANGUAGES = ['fi', 'en'];
export const DEFAULT_LANGUAGE = 'fi';

export const QUESTIONNAIRE_QUESTION_TYPES = [
  'slider.disagree-agree',
  'slider.no-yes',
  'slider.custom',
  'textarea',
];
