import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';
import { MediaModel } from './MediaModel';

export const ChartDataPointGroupModel = types.map(
  types.union(types.string, types.number, types.null),
);

export const ChartDataPointModel = types.map(
  types.union(
    types.string,
    types.number,
    types.boolean,
    types.null,
    ChartDataPointGroupModel,
  ),
);

export const ChartGroupModel = types.map(
  types.union(types.array(types.string), types.string),
);

export const ChartCSVDataModel = types.model({
  data: types.array(ChartDataPointModel),
  groups: types.array(ChartGroupModel),
});

export const DataTableCell = types.maybeNull(
  types.maybe(types.union(types.string, types.number)),
);

export const DataTableCSVDataModel = types.array(types.array(DataTableCell));

const BlockTypes = [
  'NestedTextBlock' as const,
  'ImageBlock' as const,
  'VideoBlock' as const,
  'QuestionnaireBlock' as const,
  'LinkListBlock' as const,
  'NestedChartBlock' as const,
  'NestedDataTableBlock' as const,
  'BulletedTextBlock' as const,
  'Chart' as const,
  'DataTable' as const,
  'Link' as const,
  'Question' as const,
  'SubHeader' as const,
  'TextBlock' as const,
];

export const BlockCategories = ['main' as const, 'side' as const];
export type BlockCategory = (typeof BlockCategories)[number];

export const PersonFormPositions = [
  'hidden' as const,
  'top' as const,
  'bottom' as const,
];

const BaseBlockModel = types.model('BaseBlockModel', {
  __index: types.maybeNull(types.number),
  id: types.maybeNull(types.string),
  type: types.maybeNull(types.enumeration('BlockType', BlockTypes)),
  blockCategory: types.maybeNull(
    types.enumeration('BlockCategory', BlockCategories),
  ),
  csvData: types.maybeNull(
    types.union(ChartCSVDataModel, DataTableCSVDataModel),
  ),
  csvDataType: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
  body: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  href: types.maybeNull(types.string),
  publishedDateTime: types.maybeNull(types.string),
  filename: types.maybeNull(types.string),
  chartType: types.maybeNull(types.string),
  useWebScraper: types.maybeNull(types.boolean),
  webScraperUrl: types.maybeNull(types.string),
  scrapedDataSetLabel: types.maybeNull(types.string),
  showGauge: types.maybeNull(types.boolean),
  gaugeValue: types.maybeNull(types.number),
  imageSrc: types.maybeNull(MediaModel),
  altText: types.maybeNull(types.string),
  videoSrc: types.maybeNull(types.string),
  yUnit: types.maybeNull(types.string),
  scaleMin: types.maybeNull(types.number),
  scaleMax: types.maybeNull(types.number),
  scaleMinLabel: types.maybeNull(types.string),
  scaleMaxLabel: types.maybeNull(types.string),
  questionType: types.maybeNull(types.string),
  personFormPosition: types.maybeNull(
    types.enumeration('PersonFormPosition', PersonFormPositions),
  ),
  isInputVisible: types.maybeNull(types.boolean),
  sliderStep: types.maybeNull(types.number),
  sliderInputDecoration: types.maybeNull(types.string),
});

export const MainBlockModel = types.model({
  items: types.maybe(types.array(BaseBlockModel)),
});

export const BlockModel = types
  .compose(BaseBlockModel, MainBlockModel)
  .named('BlockModel');

export interface IBlockModel extends Instance<typeof BlockModel> {}
export interface Block extends SnapshotOut<typeof BlockModel> {}
export interface BlockIn extends SnapshotIn<typeof BlockModel> {}
