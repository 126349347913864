import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStores, RootStore } from '../../stores';
import Layout from '../../components/Layout/Layout';
import { useHistory, useParams } from 'react-router-dom';
import CompanyDetailsForm from './CompanyDetailsForm';
import { Company, CompanyIn } from '../../stores/models';
import { InnerContentContainer } from '../../components/Layout';

export const CompanyScreen: FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string | undefined }>();
  const companyId = Number(id);

  const {
    companyStore: {
      getCompany,
      updateCompany,
      addCompany,
      state,
      getCompanies,
    },
  } = useStores() as RootStore;

  const company = getCompany(companyId);

  useEffect(() => {
    if (companyId && state === 'NOT_FETCHED') {
      getCompanies({});
    }
  }, [companyId, getCompanies, state]);

  const handleUpdateCompany = (company: Company) => {
    updateCompany({ company });
  };

  const handleAddCompany = async (company: CompanyIn) => {
    await addCompany({ company });
    history.push('/companies');
  };

  const getPageTitle = () => {
    const title = t(`screens.companies`);
    const details = company ? t(`common.edit`) : t(`companies.addNew`);
    return `${title} - ${details}`;
  };

  return (
    <Layout pageTitle={getPageTitle()}>
      <InnerContentContainer>
        <CompanyDetailsForm
          onSubmit={companyId ? handleUpdateCompany : handleAddCompany}
          editCompany={company}
        />
      </InnerContentContainer>
    </Layout>
  );
});

export default CompanyScreen;
