import React from 'react';
import { Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { observer } from 'mobx-react-lite';
import { useStores } from '../stores';
import Alert from '@material-ui/lab/Alert';
import styled from 'styled-components';

const MESSAGE_DURATION = 5000; // ms

const AlertContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;

  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
`;

const Notifications: React.FC = observer(() => {
  const {
    notificationStore: { type, message, clearNotification },
  } = useStores();

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    clearNotification();
  };

  const getSeverity = () => {
    switch (type) {
      case 'error':
        return 'error';
      case 'success':
        return 'success';
      default:
        return;
    }
  };

  return (
    <Snackbar
      open={!!message?.length}
      autoHideDuration={MESSAGE_DURATION}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity={getSeverity()}>
        <AlertContent>
          {message}
          <button onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </button>
        </AlertContent>
      </Alert>
    </Snackbar>
  );
});

export default Notifications;
