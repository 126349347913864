import { DateTime } from 'luxon';
import { convertBodyCellsToNumeric, validateBodyCells } from './csvUtils';
import { getDateOfChartDataRow } from './chartUtils';

const getGroupKey = groupIndex => `group${groupIndex}`;

const not = predicate => value => !predicate(value);

const isEmpty = cell =>
  cell === '' || cell === '#PUUTTUU!' || cell === null || cell === undefined;

export const pruneRows = (csvData = []) => {
  return csvData.filter(row => row.some(not(isEmpty)));
};

export const pruneColumns = (csvData = []) => {
  const headerColumn = csvData[0] || [];
  const skipColumnIndices = headerColumn.reduce(
    (acc, cell, index) => (isEmpty(cell) ? [...acc, index] : acc),
    [],
  );
  // Keep the first column in all cases
  const filteredSkipColumnIndices = skipColumnIndices.slice(1);
  const prunedRows = csvData.map(row => {
    return row.reduce((acc, cell, index) => {
      return filteredSkipColumnIndices.includes(index) ? acc : [...acc, cell];
    }, []);
  });
  return prunedRows;
};

export const pruneData = (csvData = []) => pruneColumns(pruneRows(csvData));

export const toDataPointObject =
  (fillAllSubGroups = false) =>
  (acc, dataPoint, index) => {
    return not(isEmpty)(dataPoint)
      ? {
          ...acc,
          [getGroupKey(index)]: {
            historical: fillAllSubGroups || !acc.isForecast ? dataPoint : null,
            forecast: fillAllSubGroups || acc.isForecast ? dataPoint : null,
          },
        }
      : acc;
  };

const checkIfIsForecastDate = nameOfFirstForecastRow => dataPointName => {
  if (dataPointName?.length) {
    const thresholdDate =
      getDateOfChartDataRow(nameOfFirstForecastRow) ?? DateTime.now();
    const dataPointDate = getDateOfChartDataRow(dataPointName);
    return dataPointDate?.isValid && thresholdDate < dataPointDate;
  }
};

export const transformCsvData = (csvData = [], nameOfFirstForecastRow) => {
  const prunedData = pruneData(csvData);
  const isValidCsv = validateBodyCells(prunedData);
  if (!isValidCsv) {
    throw new Error('Invalid CSV');
  }
  const convertedData = convertBodyCellsToNumeric(prunedData);
  const [groupRow, ...dataPointRows] = convertedData;
  const groupNames = groupRow.slice(1); // Remove data point header column
  const groupDescriptions = groupNames.map((name, index) => {
    const dataKey = getGroupKey(index);
    return { name, dataKeys: [`${dataKey}.historical`, `${dataKey}.forecast`] };
  });

  const checkIfIsForecast = checkIfIsForecastDate(nameOfFirstForecastRow);

  const dataPointsObjects = dataPointRows.map(
    ([dataPointName, ...dataPoints], index) => {
      const isForecast = checkIfIsForecast(dataPointName);

      // Look for the next item, and check if it is the FIRST forecast value
      // We need to know this to stop historical line there,
      // and start forecast line from the very same data point.
      const nextIsFirstForecastValue =
        dataPointRows.length > index + 1 && !isForecast
          ? checkIfIsForecast(dataPointRows[index + 1][0])
          : false;

      const isLastHistoricalValue = nextIsFirstForecastValue;

      return dataPoints.reduce(toDataPointObject(isLastHistoricalValue), {
        name: dataPointName,
        isForecast,
      });
    },
  );

  return {
    data: dataPointsObjects,
    groups: groupDescriptions,
  };
};
