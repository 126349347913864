import React, { Dispatch, SetStateAction, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Close';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Box, Button, CircularProgress, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { api } from '../services/api';

const Container = styled.div`
  margin: 1em 0;

  > div.upload-file__container {
    display: flex;
    align-items: center;
    gap: 20px;
    font-style: italic;
  }
`;

const Error = styled.div`
  margin-top: 5px;
  color: ${p => p.theme.colors.toastRed};
`;

type UploadState = 'IDLE' | 'UPLOADING' | 'ERROR';

interface Props {
  file?: UploadedFile;
  setFile?: Dispatch<SetStateAction<UploadedFile | undefined>>;
  label?: string;
  acceptedFiles?: string; // e.g. "image/jpeg, image/png" to accept both, jpeg and png.
  filename?: string;
  customOnDrop?: (acceptedFiles: File[]) => void;
  disabled?: boolean;
}

const FileUpload: React.FC<Props> = ({
  file,
  setFile,
  label,
  acceptedFiles,
  filename,
  customOnDrop,
  disabled,
}) => {
  const { t } = useTranslation();

  const [uploadState, setUploadState] = useState<UploadState>('IDLE');

  const onDrop = async (acceptedFiles: File[]) => {
    setUploadState('UPLOADING');
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const response = await api.uploadFile({ file });

      if (response.kind === 'ok') {
        const uploadedFile: UploadedFile = response.data;
        if (setFile) setFile(uploadedFile);
        setUploadState('IDLE');
      } else {
        setUploadState('ERROR');
      }
    } else {
      setUploadState('ERROR');
    }
  };

  return (
    <Container>
      <div className="upload-file__container">
        <FileInput
          onDrop={customOnDrop ?? onDrop}
          multiple={false}
          label={label ?? t('common.uploadFile')}
          accept={acceptedFiles}
          disabled={disabled}
        />

        {uploadState === 'UPLOADING' && <CircularProgress size={24} />}

        {uploadState !== 'UPLOADING' && (
          <>
            {file?.url ? (
              <a href={file.url} target="_blank" rel="noreferrer">
                {file.name ?? filename}
              </a>
            ) : (
              <span>{file?.name ?? filename}</span>
            )}
            {file && setFile && (
              <IconButton
                onClick={() => setFile(undefined)}
                size="small"
                disabled={disabled}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </>
        )}
      </div>

      {uploadState === 'ERROR' && <Error>{t('errors.fileUploadError')}</Error>}
    </Container>
  );
};

export default FileUpload;

export const FileInput: React.FC<
  DropzoneOptions & { label: string }
> = props => {
  const { getRootProps, getInputProps } = useDropzone(props);

  return (
    <Box {...getRootProps()}>
      <input {...getInputProps()} />
      {
        <Button size="small" variant="outlined" disabled={props.disabled}>
          {props.label}
        </Button>
      }
    </Box>
  );
};
