import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { BlockIn, Product } from '../../stores/models';
import styled from 'styled-components';
import { RootStore, useStores } from '../../stores';
import { useHistory, useParams } from 'react-router-dom';
import FileUpload from '../../components/FileUpload';
import BlockEditor from '../../components/BlockEditor';
import UnsavedChanges from '../../components/UnsavedChanges';
import Input, { handleInputChange } from '../../components/Input';
import Select, {
  handleSelectChange,
  SelectOptionValue,
} from '../../components/Select';
import { getLanguageSelectOptions } from '../../utils/language';
import { getAreaSelectOptions } from '../../utils/area';
import Switch from '../../components/Switch';

const ProductContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .flex-row {
    display: flex;
    align-items: center;

    > * {
      :not(:first-child) {
        margin-left: 1rem;
      }
    }
  }
`;

interface Props {
  editProduct?: Product;
}

export const ProductDetailsForm: FC<Props> = observer(({ editProduct }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { categoryId } = useParams<{ categoryId: string | undefined }>();

  const {
    areaStore: { areas, state: areasState, getAreas },
    productStore: { state, addProduct, updateProduct },
    userStore: { availableLanguages, languagesState, getLanguages },
  } = useStores() as RootStore;

  const isSaving = state === 'SAVING';

  const [productName, setProductName] = useState<string>('');
  const [languageId, setLanguageId] = useState<SelectOptionValue>('');
  const [areaId, setAreaId] = useState<SelectOptionValue>('');
  const [isDraft, setIsDraft] = useState(false);
  const [productBgImage, setProductBgImage] = useState<UploadedFile>();
  const [productPdfView, setProductPdfView] = useState<UploadedFile>();
  const [currentData, setCurrentData] = useState<BlockIn[]>([]);
  const [currentData2, setCurrentData2] = useState<BlockIn[]>([]);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>();

  useEffect(() => {
    if (languagesState === 'NOT_FETCHED') getLanguages();
  }, [getLanguages, languagesState]);

  useEffect(() => {
    if (areasState === 'NOT_FETCHED') getAreas();
  }, [areasState, getAreas]);

  /**
   * Track unsaved changes
   */
  useEffect(() => {
    // Set timeout to wait for possible product to load (if editing existing product)
    const timeout = setTimeout(() => {
      if (editProduct) {
        // Editing existing product
        const nameChanged = productName !== editProduct.name;

        const languageChanged = languageId !== editProduct.languageId;
        const areaChanged = areaId !== editProduct.areaId;
        const isDraftChanged = isDraft !== editProduct.isDraft;

        const imageChanged = editProduct.backgroundImage
          ? !_.isEqual(productBgImage, editProduct.backgroundImage)
          : productBgImage !== undefined;

        const pdfViewChanged = editProduct.pdfView
          ? !_.isEqual(productPdfView, editProduct.pdfView)
          : productPdfView !== undefined;

        const dataChanged = !_.isEqual(currentData, editProduct.data);
        const data2Changed = !_.isEqual(currentData2, editProduct.data2);

        const changesMade =
          languageChanged ||
          areaChanged ||
          isDraftChanged ||
          nameChanged ||
          imageChanged ||
          pdfViewChanged ||
          dataChanged ||
          data2Changed;

        if (changesMade) {
          setUnsavedChanges(true);
        }
      } else {
        // Creating new product
        if (
          productName.length > 0 ||
          productBgImage !== undefined ||
          currentData.length > 0 ||
          currentData2.length > 0
        ) {
          setUnsavedChanges(true);
        }
      }
    }, 250);

    return () => clearTimeout(timeout);
  }, [
    areaId,
    currentData,
    currentData2,
    editProduct,
    isDraft,
    languageId,
    productBgImage,
    productPdfView,
    productName,
  ]);

  useEffect(() => {
    if (editProduct) {
      setProductName(editProduct.name ?? '');
      setLanguageId(editProduct.languageId ?? '');
      setAreaId(editProduct.areaId ?? '');
      setIsDraft(editProduct.isDraft ?? false);
      setProductBgImage(editProduct.backgroundImage ?? undefined);
      setProductPdfView(editProduct.pdfView ?? undefined);
      setCurrentData(editProduct.data ?? []);
      setCurrentData2(editProduct.data2 ?? []);
    }
  }, [editProduct]);

  const storeData = async (newData: BlockIn[], newData2?: BlockIn[]) => {
    const product = {
      ...editProduct,
      languageId: Number(languageId) || undefined,
      areaId: Number(areaId) || undefined,
      isDraft,
      name: productName,
      backgroundImageId: productBgImage?.id ?? null,
      pdfViewId: productPdfView?.id ?? null,
      data: newData,
      data2: newData2,
    };

    if (product?.id !== undefined) {
      await updateProduct({ product: { ...product, id: product.id } });
    } else {
      await addProduct({
        product: { ...product, categoryId: Number(categoryId) },
      });
      history.push(`/products`);
    }
    setUnsavedChanges(false);
  };

  return (
    <ProductContainer>
      <UnsavedChanges unsavedChanges={unsavedChanges} showOnlyWhenUnsaved />

      <BlockEditor
        blockData={currentData}
        blockData2={currentData2}
        setBlockData={setCurrentData}
        setBlockData2={setCurrentData2}
        availableBlocks={[
          'NestedTextBlock',
          'ImageBlock',
          'VideoBlock',
          'QuestionnaireBlock',
          'LinkListBlock',
          'NestedDataTableBlock',
          'NestedChartBlock',
        ]}
        onSave={storeData}
        isSaving={isSaving}
      >
        <div className="flex-row">
          {!!availableLanguages.length && (
            <Select
              id="product-language-select"
              label={t('languages.title')}
              value={languageId ?? ''}
              options={getLanguageSelectOptions(availableLanguages)}
              onChange={handleSelectChange(setLanguageId)}
              width={150}
            />
          )}

          {!!areas.length && (
            <Select
              id="product-area-select"
              label={t('areas.title')}
              value={areaId ?? ''}
              options={getAreaSelectOptions(areas)}
              onChange={handleSelectChange(setAreaId)}
              width={150}
            />
          )}

          <Switch
            label={t(`common.isDraft.${isDraft ? 'yes' : 'no'}`)}
            checked={isDraft}
            setter={setIsDraft}
          />
        </div>

        <Input
          label={t('products.name')}
          value={productName}
          onChange={handleInputChange(setProductName)}
          autoFocus
        />

        <FileUpload
          file={productBgImage}
          setFile={setProductBgImage}
          label={t('common.uploadBackgroundImage')}
          acceptedFiles="image/jpeg, image/png"
        />

        <FileUpload
          file={productPdfView}
          setFile={setProductPdfView}
          label={t('common.uploadPdfView')}
          acceptedFiles="application/pdf"
        />
      </BlockEditor>
    </ProductContainer>
  );
});

export default ProductDetailsForm;
