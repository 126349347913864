import React from 'react';
import Select from '../Select';
import { useTranslation } from 'react-i18next';
import { BlockCategories, BlockCategory } from '../../stores/models';

interface Props {
  category: BlockCategory;
  setCategory: (blockCategory: BlockCategory) => void;
}

export function BlockCategorySelect({ category, setCategory }: Props) {
  const { t } = useTranslation();

  const blockCategoryOptions = BlockCategories.map(option => ({
    value: option,
    label: t(`block-editor.blockCategory.${option}`),
  }));

  return (
    <Select
      id="block-category-select"
      label={t(`block-editor.blockCategoryLabel`)}
      value={category ?? 'main'}
      options={blockCategoryOptions}
      onChange={(event: any) => {
        setCategory(event?.target?.value ?? null);
      }}
      fullWidth
    />
  );
}
