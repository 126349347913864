import React from 'react';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Badges from './Badges';
import styled from 'styled-components';

const Container = styled.span`
  display: flex;
  alignitems: center;

  svg {
    margin-right: 0.75rem;
  }
`;

interface Props {
  label: string;
  badges: string[];
  isHidden?: boolean | null;
}

const TreeItemName: React.FC<Props> = ({ label, badges, isHidden }) => (
  <Container>
    {isHidden && <VisibilityOffIcon />}
    {label}
    {!!badges.length && <Badges badges={badges} />}
  </Container>
);

export default TreeItemName;
