import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BlockCategory, BlockIn } from '../../stores/models';
import { Button } from '../FormControls/Button';
import Modal, { ModalContent } from '../Modal';
import BlockList from './BlockList';
import { makeBlockId, replaceBlock, updateBlockDataIndexes } from './utils';

import BulletedTextBlock from './blocks/BulletedTextBlock';
import Chart from './blocks/Chart';
import DataTable from './blocks/DataTable';
import ImageBlock from './blocks/ImageBlock';
import VideoBlock from './blocks/VideoBlock';
import Link from './blocks/Link';
import LinkListBlock from './blocks/LinkListBlock';
import NestedChartBlock from './blocks/NestedChartBlock';
import NestedDataTableBlock from './blocks/NestedDataTableBlock';
import NestedTextBlock from './blocks/NestedTextBlock';
import Question from './blocks/Question';
import QuestionnaireBlock from './blocks/QuestionnaireBlock';
import SubHeader from './blocks/SubHeader';
import TextBlock from './blocks/TextBlock';
import Accordion from '../Accordion';

const Container = styled.div`
  margin-bottom: 2em;
`;

const Columns = styled.div<{ vertical?: boolean }>`
  margin-bottom: 1em;
  display: flex;
  justify-content: space-evenly;
  flex-direction: ${p => (p.vertical ? 'column' : 'row')};
`;

const Column = styled.div`
  flex: 1;
  padding: 1em;

  :not(:first-child) {
    border-left: 1px solid ${p => p.theme.colors.grey};
  }
`;

export type BlockType =
  | 'NestedTextBlock'
  | 'ImageBlock'
  | 'VideoBlock'
  | 'QuestionnaireBlock'
  | 'LinkListBlock'
  | 'NestedChartBlock'
  | 'NestedDataTableBlock'
  | 'BulletedTextBlock'
  | 'Chart'
  | 'DataTable'
  | 'Link'
  | 'Question'
  | 'SubHeader'
  | 'TextBlock';

export interface BlockForm {
  id: string;
  title: string;
  content: ModalContent;
}

export interface BlockEditorProps {
  onBlockSave?: (newBlockData: BlockIn, oldBlock?: BlockIn) => void;
  editBlock?: BlockIn;
}

interface Props extends BlockEditorProps {
  blockData: BlockIn[];
  blockData2?: BlockIn[];
  setBlockData: React.Dispatch<React.SetStateAction<BlockIn[]>>;
  setBlockData2?: React.Dispatch<React.SetStateAction<BlockIn[]>>;
  onSave?: (blockData: BlockIn[], sideBlockData?: BlockIn[]) => void;
  isSaving?: boolean;
  availableBlocks: BlockType[];
  vertical?: boolean;
}

const BlockEditor: React.FC<Props> = ({
  blockData,
  blockData2,
  setBlockData,
  setBlockData2,
  onSave,
  isSaving,
  availableBlocks,
  vertical = false,
  children,
}) => {
  const { t } = useTranslation();

  const handleBlockDataUpdate =
    (blockCategory?: BlockCategory | null) => (newBlockData: BlockIn[]) => {
      const transformedData = updateBlockDataIndexes(newBlockData);
      switch (blockCategory) {
        case 'side':
          if (setBlockData2) setBlockData2(transformedData);
          break;
        default:
          setBlockData(transformedData);
          break;
      }
    };

  const handleSaveBlock =
    (closeDialog?: () => void) =>
    (newBlockData: BlockIn, oldBlock?: BlockIn) => {
      const block = {
        ...newBlockData,
        id: oldBlock?.id ?? makeBlockId(),
      };

      const updateBlockData = handleBlockDataUpdate(block.blockCategory);
      const categoryBlockData =
        block.blockCategory === 'side' ? blockData2 ?? [] : blockData;

      if (oldBlock?.id) {
        // Editing existing block

        const oldBlockHasCategory = !!oldBlock.blockCategory;
        const categoryChanged = oldBlock.blockCategory !== block.blockCategory;
        // If block category has changed (and old category existed), remove block from old category and add to new category

        if (oldBlockHasCategory && categoryChanged) {
          updateBlockData([...categoryBlockData, block]);
          const oldCategoryBlockData =
            oldBlock.blockCategory === 'side' ? blockData2 ?? [] : blockData;

          const updatedOldBlockData = oldCategoryBlockData.filter(
            b => b.id !== oldBlock.id,
          );

          handleBlockDataUpdate(oldBlock.blockCategory)(updatedOldBlockData);
        } else {
          updateBlockData(replaceBlock(block, categoryBlockData));
        }
      } else {
        // Adding new block
        switch (block.type) {
          case 'Link':
            // Links should appear on top of the list
            updateBlockData([block, ...categoryBlockData]);
            break;
          default:
            updateBlockData([...categoryBlockData, block]);
            break;
        }
      }
      if (closeDialog) closeDialog();
    };

  const getBlockForm = (
    type?: BlockType | null,
    editBlock?: BlockIn,
  ): BlockForm => {
    let ContentElement: React.FunctionComponent<BlockEditorProps>;

    let elementNotAvailable = false;

    switch (type) {
      case 'NestedTextBlock':
        ContentElement = NestedTextBlock;
        break;
      case 'SubHeader':
        ContentElement = SubHeader;
        break;
      case 'TextBlock':
        ContentElement = TextBlock;
        break;
      case 'ImageBlock':
        ContentElement = ImageBlock;
        break;
      case 'VideoBlock':
        ContentElement = VideoBlock;
        break;
      case 'QuestionnaireBlock':
        ContentElement = QuestionnaireBlock;
        break;
      case 'Question':
        ContentElement = Question;
        break;
      case 'LinkListBlock':
        ContentElement = LinkListBlock;
        break;
      case 'Link':
        ContentElement = Link;
        break;
      case 'NestedDataTableBlock':
        ContentElement = NestedDataTableBlock;
        break;
      case 'DataTable':
        ContentElement = DataTable;
        break;
      case 'NestedChartBlock':
        ContentElement = NestedChartBlock;
        break;
      case 'Chart':
        ContentElement = Chart;
        break;
      case 'BulletedTextBlock':
        ContentElement = BulletedTextBlock;
        break;
      default:
        elementNotAvailable = true;
    }

    if (elementNotAvailable) return { id: '', title: '', content: '' };

    return {
      id: `${type}-block`,
      title: t(`block-editor.${type}.add`),
      content: (closeDialog: () => void) => (
        <ContentElement
          onBlockSave={handleSaveBlock(closeDialog)}
          editBlock={editBlock}
        />
      ),
    };
  };

  const blocks = availableBlocks
    .map(blockType => getBlockForm(blockType))
    .filter(block => !!block.id.length);

  return (
    <Container>
      <Columns vertical={vertical}>
        <Column>
          <div>{children}</div>

          <div>
            {blocks.map(block => (
              <Modal
                key={block.id}
                title={block.title}
                trigger={
                  <Button
                    label={block.title}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                }
                content={block.content}
              />
            ))}
          </div>
        </Column>

        <Column>
          <Accordion
            sections={[
              {
                id: 'main-content',
                title: t('block-editor.blockCategory.main'),
                defaultExpanded: true,
                content: (
                  <BlockList
                    blockData={blockData}
                    onBlockDataUpdate={handleBlockDataUpdate}
                    getBlockForm={getBlockForm}
                  />
                ),
              },
              {
                id: 'side-content',
                title: t('block-editor.blockCategory.side'),
                defaultExpanded: true,
                content: !blockData2?.length ? null : (
                  <BlockList
                    blockData={blockData2}
                    onBlockDataUpdate={handleBlockDataUpdate}
                    getBlockForm={getBlockForm}
                  />
                ),
              },
            ]}
          />
        </Column>
      </Columns>

      {onSave && (
        <div>
          <Button
            label={t('block-editor.save')}
            onClick={() => onSave(blockData, blockData2)}
            loading={isSaving}
            fullWidth
          />
        </div>
      )}
    </Container>
  );
};

export default BlockEditor;
