import { TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';

export const handleInputChange =
  (setValue: React.Dispatch<React.SetStateAction<string>>) =>
  (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.currentTarget.value);
  };

const Input: React.FC<TextFieldProps> = ({ ...props }) => {
  return (
    <TextField
      size="small"
      variant="outlined"
      fullWidth
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  );
};

export default Input;
