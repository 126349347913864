import { Box, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Input, SubmitButton } from '../../components/FormControls';
import { Link } from '../../components/Link';
import { useStores } from '../../stores';
import { styled } from '../../styles';
import { required } from '../../utils/validators';

interface PasswordResetRequestScreenProps {}

const FormContainer = styled(Box)`
  width: 500px;
  padding: 100px 150px;
  margin: auto;
`;

export const PasswordResetRequestScreen: FC<PasswordResetRequestScreenProps> =
  observer(() => {
    const { t } = useTranslation();
    const { userStore, notificationStore } = useStores();
    const [error, setError] = useState<string>();

    const onSubmit = async (values: { username: string }) => {
      await userStore.passwordResetRequest(values);
      if (notificationStore.error) {
        setError('Sähköpostin lähetys epäonnistui');
      } else {
        setError('');
      }
    };

    const FIELDS = [
      {
        id: 'username',
        label: 'users.username',
        validate: required,
      },
    ];

    return (
      <Form
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          submitting,
          pristine,
          hasValidationErrors,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormContainer>
                <Grid container spacing={8} justifyContent="center">
                  <Grid item xs={12}>
                    <img
                      style={{ width: '100%' }}
                      src="/Forecon-logo-txt.svg"
                      alt="Forecon logo"
                    />
                  </Grid>
                  <Grid item>
                    <Typography color="primary" variant="h2">
                      {t('login.resetPasswordRequestHeader')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={8} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography color="primary">
                      {t('login.resetPasswordRequestInfo')}
                    </Typography>
                  </Grid>
                </Grid>
                {FIELDS.map(({ id, label, validate }) => (
                  <Grid container spacing={8} justifyContent="center" key={id}>
                    <Grid item xs={12}>
                      <Input
                        validate={validate}
                        label={label}
                        id={id}
                        name={id}
                      />
                    </Grid>
                  </Grid>
                ))}
                <Grid container spacing={8} justifyContent="center">
                  <Grid item>
                    <SubmitButton
                      label="login.sendPasswordResetLink"
                      loading={submitting}
                      disabled={pristine || hasValidationErrors}
                    />
                  </Grid>
                </Grid>
                {error && (
                  <Grid container spacing={8} justifyContent="center">
                    <Grid item>
                      <Typography color="primary" variant="h2">
                        {error}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={8} justifyContent="center">
                  <Grid item>
                    <Typography>
                      <Link to="/login">{t('login.loginLink')}</Link>
                    </Typography>
                  </Grid>
                </Grid>
              </FormContainer>
            </form>
          );
        }}
      />
    );
  });
