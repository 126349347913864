import {
  Instance,
  types,
  flow,
  cast,
  getParent,
  getSnapshot,
} from 'mobx-state-tree';
import i18n from '../i18n';
import { api } from '../services/api';
import STORAGE from '../utils/storage';
import { ERROR, SUCCESS } from '../constants/constants';
import { sortByNumber } from '../utils/sort';
import { UserModel } from './models/UserModel';
import { LanguageModel } from './models/LanguageModel';

const LoginStates = [
  'LOGGED_IN' as const,
  'LOGGED_OUT' as const,
  'IN_PROGRESS' as const,
];
const FetchingStates = [
  'NOT_FETCHED' as const,
  'FETCHING' as const,
  'FETCHED' as const,
  'ERROR' as const,
];

export const UserStore = types
  .model({
    state: types.enumeration('State', LoginStates),
    token: types.optional(
      types.string,
      STORAGE.read({ key: 'AUTH_TOKEN' }) ?? '',
    ),
    user: types.maybe(UserModel),

    usersState: types.enumeration('State', FetchingStates),
    users: types.array(UserModel),
    admins: types.array(UserModel),

    languages: types.array(LanguageModel),
    languagesState: types.enumeration('State', FetchingStates),
  })
  .views(self => ({
    get usersSnapshot() {
      return [...getSnapshot(self.users)].sort(sortByNumber('id'));
    },
    get adminsSnapshot() {
      return [...getSnapshot(self.admins)].sort(sortByNumber('id'));
    },
    get isLoggedIn() {
      return !!self.token?.length;
    },
    get availableLanguages() {
      return [...getSnapshot(self.languages)].sort(
        // Sort by language code
        (a, b) => a.code.localeCompare(b.code),
      );
    },
  }))
  .actions(self => {
    const login = flow(function* (params: Api.Req.Login) {
      const { notificationStore } = getParent(self);
      self.state = 'IN_PROGRESS';

      const response: Api.Response<Api.Res.Login> = yield api.login({
        ...params,
        username: params.username.toLowerCase().trim(),
      });

      if (response.kind === 'ok') {
        self.token = response.data.token;
        self.user = cast({
          id: response.data.id,
          username: response.data.username,
          name: response.data.name,
          role: response.data.role,
        });
        STORAGE.write({ key: 'AUTH_TOKEN', value: response.data.token });
        self.state = 'LOGGED_IN';
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.state = 'LOGGED_OUT';
      }
    });

    const logout = flow(function* () {
      const { companyStore, contentStore, productStore } = getParent(self);

      yield api.logout({});

      STORAGE.write({ key: 'AUTH_TOKEN', value: null });

      self.token = '';
      self.user = undefined;
      self.users = cast([]);

      companyStore.reset();
      contentStore.reset();
      productStore.reset();

      self.state = 'LOGGED_OUT';
      self.languagesState = 'NOT_FETCHED';
      self.usersState = 'NOT_FETCHED';
    });

    const getMe = flow(function* (params: Api.Req.GetMe = {}) {
      const response: Api.Response<Api.Res.GetMe> = yield api.getMe(params);

      if (response.kind === 'ok') {
        const user = response.data;
        self.user = cast({
          id: user.id,
          username: user.username,
          name: user.name,
          role: user.role,
        });
      }
    });

    const getUsers = flow(function* (params: Api.Req.GetUsers) {
      const { notificationStore } = getParent(self);
      self.usersState = 'FETCHING';

      const response: Api.Response<Api.Res.GetUsers> = yield api.getUsers(
        params,
      );

      if (response.kind === 'ok') {
        const users = response.data?.filter(user => user.role === 'user');
        self.users = cast(users);

        const admins = response.data?.filter(user => user.role === 'admin');
        self.admins = cast(admins);
        self.usersState = 'FETCHED';
      } else {
        self.usersState = 'ERROR';
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
      }
    });

    const addUser = flow(function* (user: Api.Req.AddUser) {
      const { notificationStore, companyStore } = getParent(self);

      const response: Api.Response<Api.Res.AddUser> = yield api.addUser({
        ...user,
        username: user.username.toLowerCase().trim(),
      });

      if (response.kind === 'ok') {
        companyStore.getCompanies();
        getUsers({});
        notificationStore.setSuccess(i18n.t('successes.general.add'));
      } else {
        if (response.data) notificationStore.setError(response.data.message);
        else notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
      }
    });

    const deleteUser = flow(function* (user: Api.Req.DeleteUser) {
      const { notificationStore, companyStore } = getParent(self);
      const response: Api.Response<Api.Res.DeleteUser> = yield api.deleteUser(
        user,
      );

      if (response.kind === 'ok') {
        companyStore.getCompanies();
        getUsers({});
        notificationStore.setSuccess(i18n.t('successes.general.delete'));
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
      }
    });

    const passwordResetRequest = flow(function* (params: { username: string }) {
      const { notificationStore } = getParent(self);

      const response = yield api.passwordResetRequest(params);

      if (response.kind !== 'ok') {
        notificationStore.setError(i18n.t(ERROR.FETCH_USER_ERROR));
      } else {
        notificationStore.clearNotification();
      }
    });

    const passwordReset = flow(function* (params: Api.Req.PasswordReset) {
      const { notificationStore } = getParent(self);

      const response = yield api.passwordReset(params);

      if (response.kind !== 'ok') {
        notificationStore.setError(i18n.t(ERROR.FETCH_USER_ERROR));
      } else {
        notificationStore.setSuccess(i18n.t(SUCCESS.CHANGE_PASSWORD_SUCCESS));
      }
    });

    const getLanguages = flow(function* () {
      const response: Api.Response<Api.Res.GetLanguages> =
        yield api.getLanguages();
      self.languagesState = 'FETCHING';

      if (response.kind === 'ok') {
        self.languages = cast(response.data);
        self.languagesState = 'FETCHED';
      } else {
        self.languagesState = 'ERROR';
      }
    });

    return {
      afterCreate: () => {
        self.state = self.token?.length ? 'LOGGED_IN' : 'LOGGED_OUT';
        if (!self.user && self.isLoggedIn) {
          getMe();
        }
      },
      login,
      logout,
      getUsers,
      addUser,
      deleteUser,
      passwordResetRequest,
      passwordReset,
      getLanguages,
    };
  });

export interface IUserStore extends Instance<typeof UserStore> {}

export default UserStore;
