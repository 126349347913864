import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BlockEditor, { BlockEditorProps, BlockType } from '..';
import { BlockIn } from '../../../stores/models';
import { Button } from '../../FormControls';
import Input, { handleInputChange } from '../../Input';
import { BlockForm, handleSubmitBlockForm } from './BlockForm';
import { InputRow } from './styled';
import { BlockCategorySelect } from '../BlockCategorySelect';

const NestedChartBlock: React.FC<BlockEditorProps> = ({
  onBlockSave,
  editBlock,
}) => {
  const { t } = useTranslation();

  const type: BlockType = 'NestedChartBlock';

  const [blockCategory, setBlockCategory] = useState(
    editBlock?.blockCategory ?? 'main',
  );

  // @ts-ignore
  const [title, setTitle] = useState(editBlock?.title ?? '');
  // @ts-ignore
  const [items, setItems] = useState<BlockIn[]>(editBlock?.items ?? []);

  const disabled = editBlock && !editBlock.id;

  const handleSaveBlock = () => {
    const block = {
      type,
      blockCategory,
      title,
      items,
    };

    if (onBlockSave) onBlockSave(block, editBlock);
  };

  return (
    <BlockForm onSubmit={handleSubmitBlockForm(handleSaveBlock)}>
      <InputRow>
        <Input
          label={t(`block-editor.${type}.title`)}
          value={title}
          onChange={handleInputChange(setTitle)}
          disabled={disabled}
          autoFocus
        />
        <BlockCategorySelect
          category={blockCategory}
          setCategory={setBlockCategory}
        />
      </InputRow>

      <BlockEditor
        blockData={items}
        setBlockData={setItems}
        onBlockSave={onBlockSave}
        availableBlocks={['Chart', 'BulletedTextBlock']}
        vertical
      />

      <Button
        label={t(`block-editor.${type}.save`)}
        type="submit"
        fullWidth
        disabled={disabled}
      />
    </BlockForm>
  );
};

export default NestedChartBlock;
