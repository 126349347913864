import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';

export const PersonDtoModel = types.model({
  id: types.maybe(types.maybeNull(types.string)), // uuid
  email: types.maybe(types.maybeNull(types.string)),
  name: types.maybe(types.maybeNull(types.string)),
  company: types.maybe(types.maybeNull(types.string)),
  title: types.maybe(types.maybeNull(types.string)),
  info: types.maybe(types.maybeNull(types.string)),
});
export interface PersonDto extends SnapshotOut<typeof PersonDtoModel> {}

export const PersonModel = types.model({
  id: types.string, // uuid
  email: types.string,
  name: types.maybeNull(types.string),
  company: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  info: types.maybeNull(types.string),
});

export interface IPersonModel extends Instance<typeof PersonModel> {}
export interface Person extends SnapshotOut<typeof PersonModel> {}
export interface PersonIn extends SnapshotIn<typeof PersonModel> {}
