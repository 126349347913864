import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import styled from 'styled-components';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { TreeItem, TreeView as MuiTreeView } from '@material-ui/lab';
import { Button } from './FormControls';
import { Tooltip } from '@material-ui/core';

const StyledTreeItem = styled(TreeItem)`
  :first-child {
    margin-top: 1rem;
  }
  :not(:first-child) {
    border-top: 1px solid #b7b7b7;
  }

  user-select: none;

  .MuiTreeItem-content .MuiTreeItem-label,
  .MuiTreeItem-content .MuiTreeItem-label:hover,
  &.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: transparent !important;
  }

  .MuiTreeItem-content .MuiTreeItem-label:hover {
    text-decoration: underline;
  }

  .MuiTreeItem-group {
    margin: 0;
  }
`;

const TreeItemContent = styled.div`
  border-top: 1px solid #b7b7b7;
  background-color: ${p => p.theme.colors.white};
  padding: 1em 2em;

  button.tree-view__content-action-button {
    margin: 1em 0;
  }
`;

const StyledSubTreeItem = styled(TreeItem)`
  padding: 0;
  margin: 0 !important;
`;

const TreeItemLabel = styled.div<{ size?: 'small' }>`
  padding: ${p => (p.size === 'small' ? 0.5 : 1)}em;
  display: flex;
  justify-content: space-between;
`;

interface TreeItemAction {
  label: string;
  icon: React.ReactNode;
  onClick: (id: number) => void;
  disabled?: boolean;
}

interface BaseTreeItem {
  id: number;
  name: string | JSX.Element;
  actions?: TreeItemAction[];
  onClick: (id: number) => void;
}

export type Tree = (BaseTreeItem & {
  subItems: BaseTreeItem[];
  actionButtons?: TreeItemAction[];
})[];

interface Props {
  tree: Tree;
}

const TreeView: React.FC<Props> = ({ tree }) => {
  const renderTreeItemLabel = (
    { id, name, actions }: BaseTreeItem,
    size?: 'small',
  ) => (
    <TreeItemLabel size={size}>
      <Typography>{name}</Typography>
      <div>
        {actions?.map(({ label, icon, disabled, onClick }) => (
          <Tooltip key={label} title={label} placement="bottom" arrow>
            <span>
              <IconButton
                size="small"
                disabled={disabled}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                  onClick(id);
                }}
              >
                {icon}
              </IconButton>
            </span>
          </Tooltip>
        ))}
      </div>
    </TreeItemLabel>
  );

  return (
    <MuiTreeView
      aria-label="Tree view"
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
    >
      {tree.map(treeItem => (
        <StyledTreeItem
          key={treeItem.id}
          nodeId={`${treeItem.id}`}
          label={renderTreeItemLabel(treeItem)}
        >
          <TreeItemContent>
            {treeItem.subItems.map(subItem => (
              <StyledSubTreeItem
                key={subItem.id}
                nodeId={`${treeItem.id}`}
                label={renderTreeItemLabel(subItem, 'small')}
                onClick={() => subItem.onClick(subItem.id)}
              />
            ))}

            {treeItem.actionButtons?.map(
              ({ label, icon, disabled, onClick }) => (
                <Button
                  key={label}
                  label={label}
                  onClick={() => onClick(treeItem.id)}
                  startIcon={icon}
                  size="small"
                  disabled={disabled}
                  className="tree-view__content-action-button"
                />
              ),
            )}
          </TreeItemContent>
        </StyledTreeItem>
      ))}
    </MuiTreeView>
  );
};

export default TreeView;
