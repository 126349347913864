import React, { FC, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStores, RootStore } from '../../stores';
import Layout from '../../components/Layout/Layout';
import { useParams } from 'react-router-dom';
import ProductDetailsForm from './ProductDetailsForm';
import { InnerContentContainer } from '../../components/Layout';

export const ProductScreen: FC = observer(() => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string | undefined }>();
  const productId = Number(id);
  const productIdRef = useRef<number>();

  const {
    productStore: { productState, product, getProduct },
  } = useStores() as RootStore;

  const isBusy = productState === 'FETCHING';
  const isEditing = !!product && product.id === productId;

  useEffect(() => {
    if (!isBusy && productId && productId !== productIdRef.current) {
      productIdRef.current = productId;
      getProduct({ id: productId });
    }
  }, [getProduct, isBusy, productId]);

  const getPageTitle = () => {
    const title = t(`screens.products`);
    const details = isEditing ? t(`common.edit`) : t(`products.addNew`);
    return !isBusy ? `${title} - ${details}` : title;
  };

  return (
    <Layout pageTitle={getPageTitle()} loading={isBusy}>
      <InnerContentContainer>
        {!isBusy && (
          <ProductDetailsForm editProduct={isEditing ? product : undefined} />
        )}
      </InnerContentContainer>
    </Layout>
  );
});

export default ProductScreen;
