import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { styled } from '../../styles/styledTheme';
import { FileWithPreview } from '../../types';

interface FileUploaderProps {
  name: string;
  value: File | string | null;
  onChange: (event: File) => void;
}

const DropzoneContainer = styled.div`
  border: 2px dashed ${props => props.theme.colors.secondaryDarkest};
  padding: 0 2rem;
  border-radius: 0.5rem;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const FileUploader: FC<FileUploaderProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      onChange(file);
    },
    multiple: false,
  });

  return (
    <Container>
      <DropzoneContainer {...getRootProps()}>
        <input {...getInputProps()} />
        {value === null || value === '' ? (
          <Typography>{t('common.uploadFile')}</Typography>
        ) : typeof value === 'string' ? (
          <Typography>{value}</Typography>
        ) : (
          <Typography>{value.name}</Typography>
        )}
      </DropzoneContainer>
    </Container>
  );
};

interface FileUploaderFieldProps {
  name: string;
  required?: boolean;
  validate?: (value: FileWithPreview | null) => string | undefined;
}

export const FileUploaderField: FC<FileUploaderFieldProps> = ({
  name,
  required = true,
  validate,
}) => {
  const render = (props: any) => {
    return (
      <FileUploader
        name={props.input.name}
        value={props.input.value}
        onChange={props.input.onChange}
      />
    );
  };

  return (
    <Field
      name={name}
      required={required}
      render={render}
      validate={validate}
    />
  );
};
