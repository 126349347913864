import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';
import { UserModel } from './UserModel';

export const CompanyModel = types.model({
  id: types.maybe(types.identifierNumber),
  name: types.string,
  allUsers: types.maybeNull(types.array(UserModel)),
  productIds: types.array(types.number),
});

export interface ICompanyModel extends Instance<typeof CompanyModel> {}
export interface Company extends SnapshotOut<typeof CompanyModel> {}
export interface CompanyIn extends SnapshotIn<typeof CompanyModel> {}
