import {
  Instance,
  types,
  flow,
  cast,
  applySnapshot,
  getSnapshot,
  getParent,
} from 'mobx-state-tree';
import { ERROR } from '../constants/constants';
import i18n from '../i18n';
import { api } from '../services/api/ApiClient';
import { CategoryModel, Product, ProductModel } from './models';

const States = [
  'NOT_FETCHED' as const,
  'FETCHING' as const,
  'FETCHED' as const,
  'ERROR' as const,
  'SAVING' as const,
  'SAVED' as const,
];

const ProductStates = ['IDLE' as const, 'FETCHING' as const, 'ERROR' as const];

const sortProducts = (a: Product, b: Product) => {
  const byLanguage =
    a.language?.code && b.language?.code
      ? a.language?.code?.localeCompare(b.language?.code)
      : 0;

  const byName = a.name.localeCompare(b.name);

  return byLanguage || byName;
};

export const ProductStore = types
  .model({
    state: types.enumeration('State', States),
    products: types.array(ProductModel),
    categories: types.array(CategoryModel),

    productState: types.enumeration('State', ProductStates),
    productData: types.maybe(ProductModel),
  })

  .views(self => ({
    get allProducts() {
      return [...getSnapshot(self.products)].sort(sortProducts);
    },
    get allCategories() {
      return [...getSnapshot(self.categories)];
    },
    get product() {
      return self.productData ? getSnapshot(self.productData) : undefined;
    },
    getCategoryProducts(categoryId: number) {
      return [...getSnapshot(self.products)]
        .filter(product => product.categoryId === categoryId)
        .sort(sortProducts);
    },
  }))
  .actions(self => {
    let initialState = {};

    const getProducts = flow(function* (_: Api.Req.GetProducts = {}) {
      const { notificationStore } = getParent(self);
      self.state = 'FETCHING';

      const response: Api.Response<Api.Res.GetProducts> = yield api.getProducts(
        {},
      );

      if (response.kind === 'ok') {
        self.products = cast(response.data.products);
        self.categories = cast(response.data.categories);
        self.state = 'FETCHED';
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.state = 'ERROR';
      }
    });

    const getProduct = flow(function* (params: Api.Req.GetProduct) {
      const { notificationStore } = getParent(self);
      self.productState = 'FETCHING';

      const response: Api.Response<Api.Res.GetProduct> = yield api.getProduct(
        params,
      );

      if (response.kind === 'ok') {
        const product = response.data;
        self.productData = cast(product);
        self.productState = 'IDLE';
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.productState = 'ERROR';
      }
    });

    const addProduct = flow(function* (params: any) {
      // TODO: Fix type
      const { notificationStore } = getParent(self);
      self.state = 'SAVING';
      const response: Api.Response<Api.Res.AddProduct> = yield api.addProduct(
        params,
      );

      if (response.kind === 'ok') {
        const addedProduct = response.data;
        const products = [
          ...getSnapshot(self.products).filter(r => r.id !== addedProduct.id),
          addedProduct,
        ];
        self.products = cast(products);
        self.productData = cast(addedProduct);
        self.state = 'SAVED';
        notificationStore.setSuccess(i18n.t('successes.general.add'));
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.state = 'ERROR';
      }
    });

    const updateProduct = flow(function* (params: Api.Req.UpdateProduct) {
      const { notificationStore } = getParent(self);
      self.state = 'SAVING';
      const response: Api.Response<Api.Res.UpdateProduct> =
        yield api.updateProduct(params);

      if (response.kind === 'ok') {
        const updatedProduct = response.data;
        const products = getSnapshot(self.products).map(product =>
          product.id === updatedProduct.id ? updatedProduct : product,
        );
        self.products = cast(products);
        self.productData = cast(updatedProduct);
        self.state = 'SAVED';
        notificationStore.setSuccess(i18n.t('successes.general.edit'));
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.state = 'ERROR';
      }
    });

    const deleteProduct = flow(function* (params: Api.Req.DeleteProduct) {
      const { notificationStore } = getParent(self);

      const response: Api.Response<Api.Res.DeleteProduct> =
        yield api.deleteProduct(params);

      if (response.kind === 'ok') {
        getProducts({});
        notificationStore.setSuccess(i18n.t('successes.general.delete'));
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
      }
    });

    const updateProductCategory = flow(function* (
      payload: Api.Req.UpdateProductCategory,
    ) {
      const { notificationStore } = getParent(self);
      self.state = 'SAVING';
      const response: Api.Response<Api.Res.UpdateProductCategory> =
        yield api.updateProductCategory(payload);

      if (response.kind === 'ok') {
        const updatedProductCategory = response.data;
        const categories = [
          ...getSnapshot(self.categories).map(category =>
            category.id === updatedProductCategory.id
              ? updatedProductCategory
              : category,
          ),
        ];
        self.categories = cast(categories);
        self.state = 'SAVED';
        notificationStore.setSuccess(i18n.t('successes.general.edit'));
      } else {
        notificationStore.setError(i18n.t(ERROR.GENERAL_ERROR));
        self.state = 'ERROR';
      }
    });

    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      reset: () => {
        applySnapshot(self, initialState);
      },
      getProducts,
      getProduct,
      addProduct,
      updateProduct,
      deleteProduct,
      updateProductCategory,
    };
  });

export interface IProductStore extends Instance<typeof ProductStore> {}

export default ProductStore;
