import { v4 as uuidv4 } from 'uuid';
import { BlockIn } from '../../../stores/models';

export const makeBlockId = () => uuidv4();

export const stripHtmlTags = (text?: string) => {
  return text?.replace(/(<([^>]+)>)/gi, '');
};

export const getBlockPreviewText = (block: BlockIn) => {
  const getNotEmptyValue = (key: keyof BlockIn) =>
    block[key]?.length ? block[key] : undefined;

  return (
    getNotEmptyValue('title') ??
    getNotEmptyValue('value') ??
    stripHtmlTags(getNotEmptyValue('body')) ??
    getNotEmptyValue('text') ??
    getNotEmptyValue('filename') ??
    block.imageSrc?.name
  );
};

// Currently supporting nested blocks only on two levels
export const replaceBlock = (newBlock: BlockIn, blockData: BlockIn[]) => {
  if (!newBlock.id) return blockData;

  return blockData.map(block => {
    if (block.id === newBlock.id) return newBlock;
    return {
      ...block,
      items: block.items?.map((item: BlockIn) => {
        if (item.id === newBlock.id) return newBlock;
        return item;
      }),
    };
  });
};

// Currently supporting nested blocks only on two levels
export const copyBlock = (block: BlockIn): BlockIn => {
  const blockCopy = {
    ...block,
    id: makeBlockId(),
    items: block.items?.map((item: BlockIn) => ({
      ...item,
      id: makeBlockId(),
    })),
  };

  return blockCopy;
};

// Currently supporting nested blocks only on two levels
export const updateBlockDataIndexes = (blockData: BlockIn[]): BlockIn[] => {
  return blockData.map((block, blockIndex) => ({
    ...block,
    __index: blockIndex,
    items: block.items?.map((item: BlockIn, itemIndex: number) => ({
      ...item,
      __index: itemIndex,
    })),
  }));
};
