import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import React from 'react';

const Checkbox: React.FC<CheckboxProps & { label: string }> = ({
  checked,
  onChange,
  label,
  disabled,
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={<MuiCheckbox checked={checked} onChange={onChange} />}
        disabled={disabled}
        label={label}
      />
    </FormGroup>
  );
};

export default Checkbox;
